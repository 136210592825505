import { FilterFn, SortingFn, sortingFns } from '@tanstack/react-table';
import { compareItems, RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import Utils, { isDate } from '../../../helper/Utils';
import { CmsTableFilterType } from './CmsTableFilter';
import { distance as levenshteinDistance } from 'fastest-levenshtein';

//#region Calculators

// Module pour l'utilisation des filter et sort fuzzy
declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

//#region Filtering

/**
 * Normalise une chaîne de caractères en supprimant les accents et les caractères spéciaux (optionnel).
 * @param str Chaîne à normaliser
 * @param removeSpecialChars Si `true`, supprime aussi les caractères spéciaux (par défaut `false`).
 * @returns Chaîne normalisée (minuscules, sans accents, avec ou sans caractères spéciaux).
 */
const normalizeString = (str: string, removeSpecialChars: boolean = true): string => {
  let normalized = str
    .normalize('NFKD') // Décompose les caractères accentués
    .toLowerCase()
    .replace('œ', 'oe')
    .replace('ß', 'ss') // Ligatures non gérée par unicode
    .replace(/[\u0300-\u036f]/g, ''); // Supprime les accents

  return removeSpecialChars ? normalized.replace(/[^a-z0-9]/g, '') : normalized;
};

/**
 * Filtre fuzzy, ce filtre diffère du contient par le fait qu'il utilise le match-sorter.
 * Il est donc plus permissif et permet de trouver des résultats même si la recherche est incomplète.
 * Exemple: "Jean" trouvera "Jean", "Jean-Pierre", "Jeanne", "Jean-Paul" mais aussi "Je tentant" car il contient "Jean"
 * car les lettres sont présentes dans le bon ordre.
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 * @param addMeta fonction permettant d'ajouter des métadonnées à la ligne, permet de récupérer le rang de l'item
 * le rang d'un item en fuzzy détermine à quel point l'item correspond à la recherche
 */
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

/**
 * Filtre permettant de vérifier si la valeur contenue dans row[columnId] contient la texte recherché.
 * Cette fonction est notamment utilisée pour la recherche globale (globalFilter).
 * La comparaison ignore les accents, les majuscules, les espaces et les caractères spéciaux.
 *
 * @param row Ligne du tableau contenant les données
 * @param columnId Identifiant de la colonne à filtrer
 * @param value Valeur à rechercher
 * @returns `true` si `value` est inclus dans `row[columnId]`, sinon `false`
 */
const includesFilter: FilterFn<any> = (row, columnId, value) => {
  if (!value?.trim()) return false;
  const rowValue = row.getValue(columnId);
  // comme c'est utilisé uniquement pour la recherche globale, on ne prend en compte que les strings (donc pas dates et nombres)
  if (!rowValue || typeof rowValue !== 'string' || !rowValue.trim()) return false;
  return normalizeString(rowValue).includes(normalizeString(value.toString()));
};

/**
 * Filtre de type texte, permettant de filtrer les colonnes avec 5 types de filtres:
 *
 * - **StringEqual** : égalité stricte
 * - **StringBegin** : commence par
 * - **StringExclude** : ne contient pas
 * - **StringLike** : contient
 * - **StringFuzzy** : recherche approximative (tolère fautes et variations orthographiques)
 *
 * La recherche ignore **les accents et les majuscules**.
 *
 * @param row Ligne du tableau contenant les données
 * @param columnId Identifiant de la colonne à filtrer
 * @param rawValue Valeur et type du filtre
 * @param addMeta Fonction permettant d'ajouter des métadonnées
 * @returns `true` si la ligne correspond au filtre, sinon `false`
 */
const textFilter: FilterFn<any> = (row, columnId, rawValue, addMeta) => {
  if (!rawValue?.value) return true;
  const { value, type } = rawValue;
  const rowValue = row.getValue(columnId);
  if (rowValue === undefined || rowValue === null) return false;

  const normalize = (val: any) => (typeof val === 'string' ? normalizeString(val) : val.toString());

  const normalizedRowValue = normalize(rowValue);
  const normalizedSearch = normalize(value);

  switch (type) {
    case CmsTableFilterType.StringEqual:
      return normalizedRowValue === normalizedSearch;
    case CmsTableFilterType.StringBegin:
      return normalizedRowValue.startsWith(normalizedSearch);
    case CmsTableFilterType.StringExclude:
      return !normalizedRowValue.includes(normalizedSearch);
    case CmsTableFilterType.StringLike:
      return normalizedRowValue.includes(normalizedSearch);
    case CmsTableFilterType.StringFuzzy:
      return fuzzyTextMatch(normalizedRowValue, normalizedSearch);
    default:
      return true;
  }
};

/**
 * Vérifie si une chaîne correspond approximativement à une autre en utilisant la distance de Levenshtein.
 *
 * Utilise une **normalisation complète** (accents, majuscules et caractères spéciaux supprimés).
 *
 * @param normalizedRowValue Valeur de la ligne à comparer
 * @param normalizedSearch Valeur de recherche
 * @returns `true` si la distance de Levenshtein est inférieure au seuil pour une sous-chaîne, sinon `false`
 */
const fuzzyTextMatch = (normalizedRowValue: string, normalizedSearch: string): boolean => {
  const searchLength = normalizedSearch.length;
  const rowLength = normalizedRowValue.length;
  const maxDistance = Math.ceil(Math.log2(searchLength) - 2);

  if (searchLength === 0) return true;
  if (rowLength < searchLength) return levenshteinDistance(normalizedRowValue, normalizedSearch) <= maxDistance;

  if (maxDistance <= 0) return normalizedRowValue.includes(normalizedSearch);

  let bestDistance = Infinity;
  // Parcours de toutes les sous-chaînes de `normalizedRowValue` ayant la même longueur que `normalizedSearch`
  for (let i = 0; i <= rowLength - searchLength; i++) {
    const subStr = normalizedRowValue.substring(i, i + searchLength);
    const distance = levenshteinDistance(subStr, normalizedSearch);
    if (distance < bestDistance) {
      bestDistance = distance;
    }
    if (bestDistance <= maxDistance) {
      return true;
    }
  }
  return false;
};

/**
 * Recherche pour le filtre de type search, permet de filtrer les colonnes de type texte avec les 5 types de filtres suivants:
 * - SearchFuzzy: recherche fuzzy
 * - SearchEqual: égalité stricte
 * - SearchBegin: commence par
 * - SearchExclude: ne contient pas
 * - SearchLike: contient
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 * @param addMeta fonction permettant d'ajouter des métadonnées à la ligne, permet de récupérer le rang de l'item
 */
const searchFilter: FilterFn<any> = (row, columnId, rawValue, addMeta) => {
  if (!rawValue?.value) return true;
  const { value, type } = rawValue;
  const rowValue = row.getValue(columnId);
  if (!rowValue || typeof rowValue !== 'string') return false;
  const normalizedRowValue = normalizeString(rowValue);
  const normalizedSearch = normalizeString(value.toString());
  if (type === CmsTableFilterType.SearchFuzzy) return fuzzyFilter(row, columnId, value, addMeta);
  if (type === CmsTableFilterType.SearchEqual) return normalizedRowValue === normalizedSearch;
  if (type === CmsTableFilterType.SearchBegin) return normalizedRowValue.startsWith(normalizedSearch);
  if (type === CmsTableFilterType.SearchExclude) return !normalizedRowValue.includes(normalizedSearch);
  if (type === CmsTableFilterType.SearchLike) return normalizedRowValue.includes(normalizedSearch);
  else return true;
};

/**
 * Filtre de type nombre, permet de filtrer les colonnes de type nombre avec les 4 types de filtres suivants:
 * - NumberEqual: égalité stricte
 * - NumberGreater: supérieur
 * - NumberLess: inférieur
 * - NumberBetween: entre deux valeurs (bornes incluses)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const numberFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: number = row.getValue(columnId);
  if (rowValue === undefined || rowValue === null) return false;
  if (type === CmsTableFilterType.NumberEqual) return rowValue === value;
  if (type === CmsTableFilterType.NumberGreater) return rowValue > value;
  if (type === CmsTableFilterType.NumberLess) return rowValue < value;
  if (type === CmsTableFilterType.NumberBetween)
    return rowValue >= (value.min ?? -9999999999) && rowValue <= (value.max ?? 9999999999);
  return true;
};

/**
 * Normalise une date en supprimant l'heure.
 * @param date Date à normaliser
 * @returns Date sans heure, ou `null` si invalide
 */
const normalizeDate = (date: any): Date | null => {
  if (!(date instanceof Date) || isNaN(date.getTime())) return null;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

/**
 * Filtre de type date avec 4 types de filtres:
 * - **DateEqual** : égalité stricte (même jour)
 * - **DateGreater** : supérieur ou égal
 * - **DateLess** : inférieur ou égal
 * - **DateBetween** : entre deux dates (bornes incluses)
 *
 * ⚠️ La comparaison ignore l'heure.
 */
const dateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue?.value) return true;
  const { value, type } = rawValue;
  const rowDate = normalizeDate(row.getValue(columnId));
  if (!rowDate) return false;
  const filterDate = normalizeDate(value);
  if (!filterDate) return false;

  switch (type) {
    case CmsTableFilterType.DateEqual:
      return rowDate.getTime() === filterDate.getTime();
    case CmsTableFilterType.DateGreater:
      return rowDate >= filterDate;
    case CmsTableFilterType.DateLess:
      return rowDate <= filterDate;
    case CmsTableFilterType.DateBetween:
      return (
        rowDate >= (normalizeDate(value?.start) ?? new Date(-8640000000000000)) &&
        rowDate <= (normalizeDate(value?.end) ?? new Date(8640000000000000))
      );
    default:
      return true;
  }
};

/**
 * Filtre de type date UTC, utilisez le filtre dateFilter par défaut dans la plupart des cas.
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const utcDateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: Date = new Date(row.getValue(columnId));
  if (!Utils.isDate(rowValue)) return false;
  const rowUtcValue = new Date(rowValue.getTime() - rowValue.getTimezoneOffset() * 60000);
  rowUtcValue.setHours(0, 0, 0, 0);
  const filterUtcValue = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
  filterUtcValue.setHours(0, 0, 0, 0);
  if (type === CmsTableFilterType.DateEqual) return rowUtcValue.getTime() === filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateGreater) return rowUtcValue.getTime() >= filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateLess) return rowUtcValue.getTime() <= filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateBetween) {
    return (
      rowUtcValue.getTime() >= (value?.start?.getTime() ?? -9999999999) &&
      rowUtcValue.getTime() <= (value?.end?.getTime() ?? 9999999999)
    );
  } else return true;
};

/**
 * Filtre de type "date du lundi", retourne les dates du lundi de la semaine de la date sélectionnée
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const mondayDateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  value.setHours(0, 0, 0, 0);
  const day = value.getDay() - 1;
  value.setDate(value.getDate() - day);
  const rowValue: Date = new Date(row.getValue(columnId));
  if (!Utils.isDate(rowValue)) return false;
  if (type === CmsTableFilterType.DateEqual) return rowValue.getTime() === value.getTime();
  else if (type === CmsTableFilterType.DateGreater) return rowValue >= value;
  else if (type === CmsTableFilterType.DateLess) return rowValue <= value;
  else if (type === CmsTableFilterType.DateBetween) {
    const result = value.min ? rowValue >= value.min : true;
    return value.max ? rowValue <= value.max : result;
  } else return true;
};

/**
 * Filtre de type select (gère le multi-select)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre (string, nombre ou tableau de string/nombres)
 */
const selectFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const rowValue = row.getValue(columnId);
  if (rowValue === undefined || rowValue === null) return false;

  const normalize = (value: any) => (typeof value === 'string' ? normalizeString(value) : value);

  const normalizedRowValue = normalize(rowValue);

  if (Array.isArray(rawValue)) {
    return rawValue.some((value) => {
      if (value === undefined || value === null) return false;
      return normalize(value) === normalizedRowValue;
    });
  }

  return normalize(rawValue) === normalizedRowValue;
};

/**
 * Filtre de type contient
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsFilter: FilterFn<any> = (row, columnId, value) =>
  Array.isArray(value) ? value.includes(row.getValue(columnId)) : true;

/**
 * Filtre de type contient certains éléments, (si une ligne contient au moins un des éléments du tableau de recherche)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsSomeFilter: FilterFn<any> = (row, columnId, value) => {
  if (!Array.isArray(value)) return true;
  const rowValue = row.getValue(columnId);
  if (!Array.isArray(rowValue)) return false;
  return value.some((x) => rowValue?.includes(x) ?? false);
};

/**
 * Filtre de type contient certains éléments, (si une ligne contient au moins un des éléments du tableau de recherche)
 * La différence par rapport au filtre containsSomeFilter est que les éléments du tableau de recherche sont des objets
 * dans lequel nous allons rechercher l'attribut id
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsSomeIdFilter: FilterFn<any> = (row, columnId, value) => {
  if (!Array.isArray(value)) return true;
  const rowValue = row.getValue(columnId);
  if (!Array.isArray(rowValue)) return false;
  return value.some((x) => rowValue?.find((y) => y.id === x) ?? false);
};

/**
 * Filtre de type égalité stricte
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const exactFilter: FilterFn<any> = (row, columnId, value) => {
  if (value === undefined || value === null) return true;
  return row.getValue(columnId) === value;
};

/**
 * Filtre de type égalité stricte pour les dates
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const exactDateFilter: FilterFn<any> = (row, columnId, value) => {
  if (!isDate(value)) return true;
  const date: Date = row.getValue(columnId);
  if (!isDate(date)) return false;
  return Utils.Date.pureDate(date).getTime() === Utils.Date.pureDate(value).getTime();
};

/**
 * Filtre de type inférieur ou égal
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const lessOrEqualFilter: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId) as number | undefined;
  return !!rowValue && rowValue <= value;
};

//#endregion

//#region Sorting

/**
 * Fonction de tri fuzzy, ce tri diffère du tri alphanumérique par le fait qu'il utilise le match-sorter.
 * @param rowA Ligne A
 * @param rowB Ligne B
 * @param columnId id de la colonne du tableau
 */
const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId])
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// Comparaison "Naturelle" des strings (c'est-à-dire qu'il va tenter de sort par nombre si possible)
const compare = (a?: string, b?: string) =>
  (a?.toString() ?? '').localeCompare(b?.toString() ?? '', undefined, { numeric: true, sensitivity: 'base' });

// Fonction de tri pour les strings
const StringSortingFn = (a?: string, b?: string, desc = false) => (a === b ? 0 : desc ? compare(a, b) : compare(b, a));

// Fonction de tri pour les strings avec les undefined en dernier
const StringOnlySortingFn = (a?: string, b?: string, desc = false) =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? compare(a, b) : compare(b, a);

// Fonction de tri pour les booléens
const BooleanSortingFn = (a?: boolean, b?: boolean, desc = false) => (a === b ? 0 : desc ? (a ? -1 : 1) : a ? 1 : -1);

// Fonction de tri pour les booléens avec les undefined en dernier
const BooleanOnlySortingFn = (a?: boolean, b?: boolean, desc = false) =>
  a === b ? 0 : a === undefined ? 1 : b === undefined ? -1 : desc ? (a ? -1 : 1) : a ? 1 : -1;

// Fonction de tri pour les nombres
const NumberSortingFn = (a?: number, b?: number, desc = false) =>
  a === b ? 0 : desc ? ((a ?? 0) > (b ?? 0) ? -1 : 1) : (a ?? 0) > (b ?? 0) ? 1 : -1;

// Fonction de tri pour les nombres avec les undefined en dernier
const NumberOnlySortingFn = (a?: number, b?: number, desc = false) =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? (a > b ? -1 : 1) : a > b ? 1 : -1;

// Fonction de tri pour les dates
const SortingDateFn = (a?: Date, b?: Date, desc = false) =>
  a === b ? 0 : desc ? ((a ?? 0) > (b ?? 0) ? -1 : 1) : (a ?? 0) > (b ?? 0) ? 1 : -1;

// Fonction de tri pour les dates avec les undefined en dernier
const SortingDateOnlyFn = (a?: Date, b?: Date, desc = false): number =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? (a > b ? -1 : 1) : a > b ? 1 : -1;

//#endregion

const CmsFilterCalculator = {
  fuzzy: fuzzyFilter,
  text: textFilter,
  search: searchFilter,
  number: numberFilter,
  date: dateFilter,
  utcDate: utcDateFilter,
  mondayDate: mondayDateFilter,
  select: selectFilter,
  contains: containsFilter,
  containsSome: containsSomeFilter,
  containsSomeId: containsSomeIdFilter,
  includes: includesFilter,
  exact: exactFilter,
  exactDate: exactDateFilter,
  lessOrEqual: lessOrEqualFilter,
  sort: {
    fuzzy: fuzzySort,
    date: SortingDateFn,
    string: StringSortingFn,
    boolean: BooleanSortingFn,
    number: NumberSortingFn,
  },
  pureSort: {
    date: SortingDateOnlyFn,
    string: StringOnlySortingFn,
    boolean: BooleanOnlySortingFn,
    number: NumberOnlySortingFn,
  },
};

export default CmsFilterCalculator;

//#endregion
