import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkReportCategory } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkReportCategoryListConfig: CmsColumnDef<WkReportCategory>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Type', id: 'typeLabel', Filter: CmsTableFilter.Text },
  { header: 'Active', id: 'active', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des catégories de rapport d'intervention
 */
export const WkReportCategoryList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_REPORTCATEGORY_CREATE])
    ? [<AddNavigationButton title="Ajouter un type de rapport d'intervention" to="/castres/work/reporttype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des catégories de rapport d'intervention"
      route={APIRoute.WkReportCategory}
      columns={WkReportCategoryListConfig}
      navigateTo={(_, cell) => `/castres/work/reporttype/${cell.row.original.typeId}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};
