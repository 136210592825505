import React, { FC, Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { CmsDivider, CmsPaper, FormField, Label } from './Ui';
import { DeleteButton, ListNavigationButton, NavigationButton, ValidButton, ViewNavigationButton } from './Buttons';
import './shared.scss';
import Typography from '@mui/material/Typography';
import { DialogUI } from './DialogUI';
import { GlobalContext } from '../../context/Global.context';
import CmsIcon from './CmsIcon';

interface FormFieldProps {
  // Libellé du champ
  label?: string;
  // Nom du champ
  name?: string;
  // Style à appliquer sur le champ
  style?: React.CSSProperties;
  // Style à appliquer sur le champ
  inputStyle?: React.CSSProperties;
  // Défini si le champ est requis ou non
  required?: boolean;
}

interface InputProps extends FormFieldProps {
  // La valeur à afficher dans le champ si ce dernier est vide
  placeholder?: string;
  // Gestionnaire d'erreurs, affiche un message d'erreur si la valeur est incorrect (à vous de gérer les erreurs)
  errors?: any;
  // Taille du champ (horizontal) (par défaut 100%)
  width?: string;
  // Fonction à appeler lors du changement de valeur
  onChange?: any;
  // Défini si le champ est désactivé ou non (à ne pas confondre avec visible ou non)
  disabled?: boolean;
}

interface FormInputProps extends InputProps {
  // La référence du champ
  inputRef?: any;
}

interface FormButtonValidationProps {
  // Fonction à appeler lors de la validation du formulaire (après la méthode onSubmit)
  // Utiliser pour gérer les redirections principalement.
  handleSubmit: any;
  // Fonction à appeler lors de la validation du formulaire
  onSubmit: any;
  // Fonction à appeler lors de la validation du formulaire pour retourner à la liste
  onSubmitReturnToList: any;
  // Fonction à appeler lors de la suppression d'un élément (HttpDelete)
  onDelete?: any;
  // Défini si le formulaire est en train de soumettre la requête (utile pour empêcher les doublons)
  isSubmitting: boolean;
  // Défini si le formulaire est valide ou non
  // Utile pour bloquer les action submit si le formulaire n'est pas valide/complet
  isValid: boolean;
  // Défini si le formulaire est en mode création ou édition
  isFormUpdate: boolean;
  // Url de base du composant (pour les redirections)
  componentBaseUrl: string;
  // ne pas afficher le divider
  withoutDivider?: boolean;
}

/**
 * Ensemble des boutons de validation / Edition / Suppression formatter pour un formulaire utilisant les hooks
 * de formulaire react-hook-form
 * @param handleSubmit Fonction à appeler lors de la validation du formulaire (après la méthode onSubmit)
 * @param onSubmit Fonction à appeler lors de la validation du formulaire
 * @param isSubmitting Défini si le formulaire est en train de soumettre la requête (utile pour empêcher les doublons)
 * @param isValid Défini si le formulaire est valide ou non
 * @param isFormUpdate Défini si le formulaire est en mode création ou édition
 * @param onSubmitReturnToList Fonction à appeler lors de la validation du formulaire pour retourner à la liste
 * @param componentBaseUrl Url de base du composant (pour les redirections)
 * @param onDelete Fonction à appeler lors de la suppression d'un élément (HttpDelete)
 * @param withoutDivider ne pas affichier le divider
 */
export const FormButtonValidation: FC<FormButtonValidationProps> = ({
  handleSubmit,
  onSubmit,
  isSubmitting,
  isValid,
  isFormUpdate,
  onSubmitReturnToList,
  componentBaseUrl,
  onDelete,
  withoutDivider = false,
}) => {
  const { theming } = useContext(GlobalContext);
  return (
    <>
      {!withoutDivider && <CmsDivider />}
      <div css={{ '& button': { margin: '0 0.5em 0.5em 0' }, '& a': { margin: '0 0.5em 0.5em 0' } }}>
        <Fragment>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting || !isValid}
          >
            {isFormUpdate ? 'Mettre à jour' : 'Créer'}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit(onSubmitReturnToList)}
            disabled={isSubmitting || !isValid}
          >
            {isFormUpdate ? 'Mettre à jour' : 'Créer'} et retourner à la liste
          </Button>
          {onDelete && isFormUpdate && (
            <Button
              style={{
                borderColor: theming.get().cms.severity.error,
                color: theming.get().cms.severity.error,
              }}
              variant="outlined"
              onClick={onDelete}
              disabled={isSubmitting}
            >
              Supprimer
            </Button>
          )}
        </Fragment>
        <NavigationButton size="medium" title="Abandonner" to={`${componentBaseUrl}list`} />
      </div>
    </>
  );
};

export interface DebouncedInputProps extends FormInputProps {
  // Valeur du champ, ce dernier étant un input de type texte ou nombre, il doit pouvoir gérer les deux types de valeur
  value?: number | string;
  // Type de champ (outlined, filled, standard)
  variant?: 'outlined' | 'filled' | 'standard';
  // Fonction à appeler lors du changement de valeur du champ
  onChange: any;
  // Type du champ (string ou number)
  type?: 'string' | 'number' | 'time';
  // Défini si le champ va être "amorti", cela signifie que l'évènement de mise à jour du composant est amorti par
  // un combo useEffect / setTimeOut permettant de limiter rafraichissement du composant
  debounced?: boolean;
  // Temps d'attente avant de déclencher l'évènement de mise à jour du composant
  delayTime?: number;
  // Défini si le champ est multiligne ou non (Type textarea)
  multiline?: boolean;
  // Défini si le parent peut mettre à jour la valeur du champ après son initialisation
  valueCanUpdate?: boolean;
  className?: string;
  deleteButton?: boolean;
  inputProps?: any;
  multilineToggle?: boolean;
  controlledToggle?: { isToggled: boolean; setIsToggled: (toggled: boolean) => any };
  priceInput?: boolean;
  inputRef?: any; // Référence du champ
  percentInput?: boolean;
}

/**
 * Input texte avec effet "anti-rebond", l'effet "anti-rebond" signifie le l'évènement de mis à jour du
 * composant est amorti par un combo useEffect / setTimeOut permettant de limiter rafraichissement du composant
 * @param label Libellé du champ
 * @param variant Type de champ (outlined, filled, standard)
 * @param name Nom du champ
 * @param required Défini si le champ est requis ou non
 * @param inputRef Référence du champ
 * @param type Type du champ (string ou number)
 * @param width Largeur du champ (par défaut 100%)
 * @param inputStyle Style à appliquer sur le champ input
 * @param placeholder Texte à placer dans le champ s'il est vide
 * @param multiline Défini si le champ est multiligne ou non (Type textarea)
 * @param value Valeur du champ, ce dernier étant un input de type texte ou nombre, il doit pouvoir gérer les deux types de valeur
 * @param onChange Fonction à appeler lors du changement de valeur du champ
 * @param style Style à appliquer sur le champ
 * @param disabled Défini si le champ est désactivé ou non (grisé)
 * @param delayTime Temps d'attente avant de déclencher l'évènement de mise à jour du composant
 * @param valueCanUpdate Défini si le parent peut mettre à jour la valeur du champ après son initialisation
 * @param className Classe CSS à appliquer sur le champ
 * @param inputProps Propriétés supplémentaires à appliquer sur le champ
 * @param deleteButton Défini si le champ doit avoir un bouton de suppression
 * @param multilineToggle Défini si le champ doit avoir un bouton pour passer en mode multiligne
 * @param controlledToggle Défini si le toggle multiligne est en mode contrôlé (le parent gère l'état du champ)
 * @param priceInput Défini le style pour ajouter un symbole € à la fin du champ et aligné à droite
 * @param percentInput Défini le style pour ajouter un symbole % à la fin du champ et aligné à droite
 */
export const DebouncedInput: FC<DebouncedInputProps> = ({
  label,
  variant = 'outlined',
  name,
  required = false,
  inputRef,
  type,
  width,
  inputStyle,
  placeholder,
  multiline,
  value,
  onChange,
  style,
  disabled = false,
  delayTime = 400,
  valueCanUpdate = false,
  className,
  inputProps,
  deleteButton = false,
  multilineToggle = false,
  controlledToggle,
  priceInput,
  percentInput,
}) => {
  const [val, setVal] = useState<number | string | null>(value ?? null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const isToggled = controlledToggle?.isToggled ?? isFocused;
  const setIsToggled = controlledToggle?.setIsToggled ?? setIsFocused;

  useEffect(() => {
    if (valueCanUpdate) setVal(value ?? null);
  }, [value, valueCanUpdate]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (value !== val) onChange(val);
    }, delayTime);
    return () => clearTimeout(timeOut);
  }, [val, value, delayTime, onChange]);

  const KoIcon = (): ReactNode => (
    <InputAdornment position="end">
      <CmsIcon icon="KO" onClick={() => setVal(null)} />
    </InputAdornment>
  );

  const onChangeValue = (inputVal: any) => {
    setVal(type === 'number' && !isNaN(parseFloat(inputVal)) ? +inputVal : inputVal);
  };

  let props = { endAdornment: !deleteButton ? undefined : KoIcon(), ...inputProps };
  if (priceInput) props = { ...props, endAdornment: <span style={{ margin: '0 0.2rem' }}>€</span> };
  else if (percentInput) props = { ...props, endAdornment: <span style={{ margin: '0 0.2rem' }}>%</span> };
  if (multilineToggle)
    props.startAdornment = (
      <CmsIcon
        tooltip="passer le champ en multiligne"
        className="multi-line-icon"
        icon="resize"
        onClick={() => setIsToggled(!isToggled)}
      />
    );
  return (
    <FormField style={style} className="debounced-input">
      <Label label={label} name={name} required={required} />
      <TextField
        id={name}
        name={name}
        type={priceInput ? 'number' : type}
        className={(className ?? '') + (priceInput ? ' price-input' : '')}
        disabled={disabled}
        multiline={multiline || (isToggled && multilineToggle)}
        placeholder={placeholder}
        variant={variant}
        size="small"
        value={val ?? ''}
        InputProps={props}
        onChange={(e) => onChangeValue(e?.target?.value)}
        style={{ width: width ?? '100%', ...inputStyle }}
        inputRef={inputRef}
      />
    </FormField>
  );
};

interface HeaderFormPanelProps {
  // Titre de la page, ou tout du moins de cette partie du formulaire
  title?: string | ReactNode;
  // Sous-titre de la page, ou tout du moins de cette partie du formulaire
  subtitle?: string;
  // Gestionnaire de formulaire (géré par le hookForm)
  form: any;
  // Url de base de la page (sans edit/show/list/etc...)
  baseUrl: string;
  // Fonction de soumission du formulaire
  onSubmit?: any;
  // Id de la page (si édition ou affichage)
  pageId: number | string;
  // Défini si le bouton de retour à la liste est affiché (défaut : affiché)
  noReturnToList?: boolean;
  // Défini si le bouton de retour à l'affichage est affiché (défaut : affiché)
  noReturnToShow?: boolean;
  // Défini si le bouton d'ajout ou de mise à jour est affiché (défaut : affiché)
  noAddOrUpdate?: boolean;
  // Fonction de suppression de la page (httpdelete)
  onDelete?: React.Dispatch<any>;
  // Défini si la suppression est confirmée par une modale (défaut : modale)
  deleteWithoutModal?: boolean;
}

/**
 * Barre esthétique de formulaire, souvent utilisé pour les pages ayant des formulaire sur plusieur panneaux
 * Exemple : page création/édition d'un utilisateur
 * @param baseUrl Url de base de la page (sans edit/show/list/etc...)
 * @param deleteWithoutModal Défini si la suppression est confirmée par une modale (défaut : modale)
 * @param form Gestionnaire de formulaire (géré par le hookForm)
 * @param noAddOrUpdate Défini si le bouton d'ajout ou de mise à jour est affiché (défaut : affiché)
 * @param noReturnToList Défini si le bouton de retour à la liste est affiché (défaut : affiché)
 * @param noReturnToShow Défini si le bouton de retour à l'affichage est affiché (défaut : affiché)
 * @param onDelete Fonction de suppression de la page (httpdelete)
 * @param onSubmit Fonction de soumission du formulaire
 * @param pageId Id de la page (si édition ou affichage)
 * @param subtitle Sous-titre de la page, ou tout du moins de cette partie du formulaire
 * @param title Titre de la page, ou tout du moins de cette partie du formulaire
 */
export const HeaderFormPanel: FC<HeaderFormPanelProps> = ({
  baseUrl,
  deleteWithoutModal = false,
  form,
  noAddOrUpdate = false,
  noReturnToList = false,
  noReturnToShow = false,
  onDelete,
  onSubmit,
  pageId,
  subtitle,
  title,
}) => {
  const { handleSubmit, formState } = form;
  const { isSubmitting, isValid } = formState;
  const [modal, setModal] = useState<boolean>(false);

  const addOrUpdate = (): ReactNode => (
    <ValidButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting || !isValid}>
      {pageId ? 'Mettre à jour' : 'Créer'}
    </ValidButton>
  );

  const deleteBtn = (): ReactNode => (
    <DeleteButton onClick={deleteWithoutModal ? onDelete : () => setModal(true)}>Supprimer</DeleteButton>
  );

  const toList = (): ReactNode => <ListNavigationButton title="Retourner à la liste" to={`${baseUrl}list`} />;
  const toShow = (): ReactNode => <ViewNavigationButton title="Voir" to={`${baseUrl}${pageId}/show`} />;

  const actions = [];
  if (!noAddOrUpdate) actions.push(addOrUpdate());
  if (!!onDelete && !!pageId) actions.push(deleteBtn());
  if (!noReturnToShow && !!pageId) actions.push(toShow());
  if (!noReturnToList) actions.push(toList());

  return (
    <CmsPaper
      style={{
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginBottom: '1.5em',
      }}
    >
      <Typography variant="h4">
        {title} {subtitle && <small style={{ color: 'grey' }}>{subtitle}</small>}
      </Typography>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {actions.map((action, i) => (
          <span style={{ marginLeft: '.5em' }} key={`header-pannel-action-${i}`}>
            {action}
          </span>
        ))}
      </span>
      {!!onDelete && !deleteWithoutModal && (
        <DialogUI
          body="Êtes-vous sûr de vouloir supprimer cet élément ?"
          onClose={() => setModal(false)}
          onValidation={onDelete}
          open={modal}
          title="Supprimer"
        />
      )}
    </CmsPaper>
  );
};
