import React, { FC, useContext, useMemo } from 'react';
import { CmsLink, CmsPaper } from '../../../component/shared/Ui';
import Collapse from '@mui/material/Collapse';
import { Buttons } from '../../../component/shared';
import { QuotationContext } from './QuotationShow';
import CmsChart from '../../../component/shared/CmsChart';
import './quotation.scss';
import { ListStyleDataView } from '../../../component/shared/TabStyleDataView';
import Tooltip from '@mui/material/Tooltip';

export const QuotationStats: FC = () => {
  const [open, setOpen] = React.useState(false);
  const { quotation } = useContext(QuotationContext);

  const allStats: any = useMemo(() => {
    let byJob: any[] = [];
    let byCategory: any[] = [];
    let byType: any[] = [];
    for (let line of quotation.quotationLines ?? []) {
      for (let job of line.byJob ?? []) {
        let index = byJob.findIndex((x) => x.id === job.id);
        if (index === -1)
          byJob.push({ id: job.id, label: job.label, value: job.value, data: [job.value], color: job.color });
        else {
          byJob[index].value += job.value;
          byJob[index].data[0] += job.value;
        }
      }
      for (let category of line.byCategory ?? []) {
        let index = byCategory.findIndex((x) => x.id === category.id);
        if (index === -1) byCategory.push({ id: category.id, label: category.label, value: category.value });
        else byCategory[index].value += category.value;
      }
      for (let type of line.byType ?? []) {
        let index = byType.findIndex((x) => x.id === type.id);
        if (index === -1) byType.push({ id: type.id, label: type.label, value: type.value });
        else byType[index].value += type.value;
      }
    }
    const byTotal = [];
    byTotal.push({ id: 'costPrice', label: 'Coût de revient', data: [quotation.totalCostPrice], color: '#c23434' });
    byTotal.push({
      id: 'desiredSalePrice',
      label: 'Prix de vente souhaité',
      data: [quotation.totalDesiredSalePrice],
      color: '#c0c027',
    });
    byTotal.push({ id: 'total', label: 'Total H.T', data: [quotation.totalAmount], color: '#29bbbb' });
    return { byJob, byCategory, byType, byTotal };
  }, [quotation]);

  return (
    <CmsPaper
      title="Statistique"
      className="quotation-stats"
      actions={[<Buttons.Default onClick={() => setOpen(true)}>Afficher les statistiques</Buttons.Default>]}
    >
      <Collapse in={open}>
        <div className="cms-grid fr2">
          <div className="q-stat">
            <h3>Totaux</h3>
            <div style={{ width: '100%', padding: '0.4rem' }}>
              <CmsChart.Bar height={200} xAxis={[{ scaleType: 'band', data: ['Totaux'] }]} series={allStats.byTotal} />
            </div>
          </div>
          <div className="q-stat">
            <h3>Type</h3>
            <div className="pie">
              <CmsChart.Pie data={allStats.byType} />
            </div>
          </div>
          <div className="q-stat">
            <h3>Corps d'état</h3>
            <div className="pie">
              <CmsChart.Pie data={allStats.byJob} />
            </div>
          </div>
          <div className="q-stat">
            <h3>Catégorie</h3>
            <div className="pie">
              <CmsChart.Pie data={allStats.byCategory} />
            </div>
          </div>
          <ArticleList />
        </div>
      </Collapse>
    </CmsPaper>
  );
};

const ArticleList: FC = () => {
  const { quotation } = useContext(QuotationContext);

  const data = useMemo(() => {
    const articleList: any[] = [];
    for (let row of quotation.quotationLines ?? []) {
      for (let line of row.blQuotationStudyLine ?? []) {
        const article = line.article ?? line.referenceStudyLine?.article;
        if (!article) continue;
        const existingArticle = articleList.find((x) => x.id === article.id);
        if (existingArticle) {
          existingArticle.quantity += line.quantity;
        } else {
          articleList.push({
            id: article.id,
            label: article.label,
            quantity: line.quantity ?? 0,
            companyId: article.companyId,
            companyLabel: article.companyLabel,
          });
        }
      }
    }
    let groupedByCompanyId = articleList
      .reduce((acc, x) => {
        if (!x.companyId) return acc;
        if (!acc[x.companyId]) acc[x.companyId] = { label: x.companyLabel, id: x.companyId, articles: [] };
        acc[x.companyId].articles.push(x);
        return acc;
      }, [])
      .filter((x: any) => x);

    return { articleList, groupedByCompanyId };
  }, [quotation]);

  return [
    <div className="article-list">
      <h3>Liste des articles</h3>
      <ListStyleDataView
        className="quotation-article-list-show"
        fromList={data.articleList}
        cell={(x) => (
          <div className="flex-h" style={{ margin: '0 0.2rem' }}>
            <div className="article-quantity">{x.quantity}</div>
            <CmsLink href={`/castres/stock/article/${x.id}/edit`} label={x.label} target="_blank" defaultStyle />
          </div>
        )}
      />
    </div>,
    <div className="article-list">
      <h3>Liste des Fournisseur</h3>
      <ListStyleDataView
        className="quotation-article-list-show"
        fromList={data.groupedByCompanyId}
        cell={(x) => (
          <Tooltip
            placement="left"
            title={
              <ListStyleDataView
                title="Liste d'article rattaché a ce fournisseur :"
                fromList={x.articles}
                cell={(x) => x.quantity + ' ' + x.label}
              />
            }
          >
            <div className="flex-h" style={{ margin: '0 0.2rem' }}>
              <CmsLink href={`/castres/stock/company/${x.id}/show`} label={x.label} target="_blank" defaultStyle />
            </div>
          </Tooltip>
        )}
      />
    </div>,
  ];
};
