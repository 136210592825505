import React, { FC, useEffect, useState } from 'react';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import APIRoute from '../../constant/API.constant';
import { Grid } from '@mui/material';
import { CmsPaper, WarningBubble } from '../../component/shared/Ui';
import { Buttons, FormUI, InputUI } from '../../component/shared';
import { LoDepartment } from '../../interface/LoType';
import CRUD from '../../service/CRUD.service';
import LoadingScreen from '../../component/LoadingScreen';
import { OsmMapEdit } from '../../component/map/OsmMap';
import { GroupFilter } from 'helper/AccessFilter';
import { StationListConfig } from './StationList';
import NotificationService from '../../service/NotificationService';

export const StationCreateEdit: FC<any> = ({ id }) => {
  const title = (!id ? 'Ajouter' : 'Éditer') + ' un site';
  const baseUrl = '/castres/client/station/';
  const [file, setFile] = React.useState<File>();
  const [ref, setRef] = React.useState<string>();
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [hasBeenDragged, setHasBeenDragged] = useState<boolean>(false);
  useEffect(() => {
    if (!firstLoad) return;
    setFirstLoad(false);
    CRUD.getGeneratedRef('ClStation').then((x) => setRef(x.reference));
  }, [ref, firstLoad]);

  if (!id && !ref) return <LoadingScreen />;
  return (
    <CmsForm
      id={id}
      defaultValues={{ ref, active: true }}
      currentUrl={baseUrl}
      route={APIRoute.ClStation}
      duplicateHandler={{ columns: StationListConfig, navigateTo: (id) => `/castres/client/station/${id}/edit` }}
      renderForm={(form, submit) => {
        const { latitude, longitude, active, positionTrustLevel, adressType, imagePath } = form.watch();
        const { dirtyFields } = form.formState;
        const handleMarkerDragend = (lat: number, lng: number) => {
          form.setValue('latitude', lat, { shouldValidate: true, shouldDirty: true });
          form.setValue('longitude', lng, { shouldValidate: true, shouldDirty: true });
          setHasBeenDragged(true);
        };

        const handleAddPicture = () => {
          CRUD.postFormData(APIRoute.ClStation + '/AddPicture', { id, file }).then((result: any) => {
            NotificationService.success('Image ajoutée avec succès');
            form.setValue('imagePath', result.label);
            setFile(undefined);
          });
        };

        const handleDeletePicture = () => {
          CRUD.deleteById(APIRoute.ClStation + '/DeletePicture', id).then(() => {
            NotificationService.warning('Image supprimée avec succès');
            form.setValue('imagePath', undefined);
            setFile(undefined);
          });
        };

        const handleSubmitForm = (data: any) => {
          if (!!positionTrustLevel && positionTrustLevel > 2 && !dirtyFields.latitude && !dirtyFields.longitude) {
            NotificationService.error("Veuillez vérifier la position et l'adresse de la station!");
            return;
          }
          if (!!adressType && adressType !== 'housenumber' && !hasBeenDragged) {
            NotificationService.error(
              'Adresse imprécise, déplacer le marqueur pour confirmer la position de la station!',
            );
            return;
          }
          form.setValue('positionTrustLevel', 0, { shouldValidate: true, shouldDirty: true });
          return submit(form.getValues(), true);
        };

        return (
          <>
            <FormUI.HeaderFormPanel
              pageId={id}
              title={title}
              form={form}
              baseUrl={baseUrl}
              onSubmit={handleSubmitForm}
            />
            {!!positionTrustLevel && positionTrustLevel > 2 && (
              <WarningBubble
                style={{ marginBottom: '1rem', textAlign: 'center', fontSize: '2rem' }}
                customColor="#fcca03"
              >
                Coordonnées non fiables, vous devez les éditer !
              </WarningBubble>
            )}
            {!!latitude && !!longitude && (
              <CmsPaper>
                <OsmMapEdit setCenter={{ lat: +latitude, lng: +longitude }} onMarkerDragEnd={handleMarkerDragend} />
              </CmsPaper>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CmsPaper title="Général">
                  <CmsFormInput.Text id="ref" label="Référence" min={2} required />
                  <CmsFormInput.Text id="label" label="Libellé" min={2} required />
                  <CmsFormInput.Text id="siret" label="SIRET (modifiable uniquement par import INSEE)" readOnly />
                  <CmsFormInput.Text id="lvnetName" label="Nom de la station sur LVNET (si nécessaire)" />
                  <CmsFormInput.Select
                    id="companyId"
                    label="Enseigne"
                    options={APIRoute.ClCompany + '/Simplified'}
                    required
                  />
                  <CmsFormInput.Select id="networkId" label="Réseau" options={APIRoute.ClNetwork + '/Simplified'} />
                  <CmsFormInput.Select
                    id="serviceIdList"
                    label="Service"
                    options={APIRoute.SfService + '/Simplified?from=ClStation'}
                    multiple
                    required
                  />
                  <CmsFormInput.Select
                    id="accountManagerId"
                    label="Chargé d'affaires"
                    options={APIRoute.UsUsers + '/Simplified?roleFilter=ClStation'}
                  />
                </CmsPaper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CmsPaper title="Coordonnées">
                  <CmsFormInput.Text id="manager" label="Manager" />
                  <CmsFormInput.Select
                    id="departmentId"
                    label="Département"
                    options={APIRoute.LoDepartment}
                    optionLabel={(x: LoDepartment) => `(${x.code}) ${x.name}`}
                    required
                  />
                  <CmsFormInput.Address id="address" label="Adresse" required max={100} />
                  <CmsFormInput.Text id="postcode" label="Code postal" max={5} />
                  <CmsFormInput.Text id="city" label="Ville" max={50} />
                  <CmsFormInput.Text id="tel" label="Téléphone (sans espace et sans point ex: 012345679)" />
                  <CmsFormInput.Text id="email" label="Email principal (un seul, sinon passer par les contacts)" />
                  <CmsFormInput.Number id="latitude" label="Latitude" min={-90} max={90} isCoordinate required />
                  <CmsFormInput.Number id="longitude" label="Longitude" min={-180} max={180} isCoordinate required />
                </CmsPaper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CmsPaper title="Détails">
                  <CmsFormInput.Switch id="contractCribe" label="Contrat CRIBE" />
                  <CmsFormInput.Switch
                    id="active"
                    label="Actif"
                    disabled={!active && !GroupFilter(['Administrateur'])}
                  />
                  <CmsFormInput.Switch
                    id="stationFlowSuperiorTo1500m3"
                    label="Débit station ≥ 1500 m³/an (révision périodique)"
                  />
                  <CmsFormInput.Switch id="onCall" label="Astreinte" />
                  <CmsFormInput.Switch id="sentMail" label="Envoi des rapports par mail" />
                  <CmsFormInput.Text id="comment" label="Commentaire" multiline />
                </CmsPaper>
                <CmsPaper title="Photo de la station">
                  {(!!id && [
                    <InputUI.InputFile id="imagePath" image onFileSelected={setFile} />,
                    <div className="flex-h-bet" style={{ marginTop: '1rem' }}>
                      <Buttons.Cancel onClick={handleDeletePicture} disabled={!imagePath}>
                        Supprimer
                      </Buttons.Cancel>
                      <Buttons.Valid onClick={handleAddPicture} disabled={!file}>
                        Sauvegarder l'image
                      </Buttons.Valid>
                    </div>,
                  ]) || <div>Veuillez d'abord créer la station</div>}
                </CmsPaper>
              </Grid>
            </Grid>
            <FormUI.HeaderFormPanel
              pageId={id}
              title={title}
              form={form}
              baseUrl={baseUrl}
              onSubmit={handleSubmitForm}
            />
          </>
        );
      }}
    ></CmsForm>
  );
};
