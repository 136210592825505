import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from '../../../component/form/CmsFormInput';

const WkAgreementListConfigList: CmsColumnDef<any>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Code Total', id: 'codeTotal', Filter: CmsTableFilter.Text },
  { header: 'Code activité', id: 'codeActivity', Filter: CmsTableFilter.Text },
  { header: 'Utilisable pour un devis', id: 'usableInQuotation', Filter: CmsTableFilter.Bool },
];

export const WkAgreementList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.WkAgreement}
      columns={WkAgreementListConfigList}
      title="Liste des type de contrat"
      navigateTo={(id: number) => `/castres/work/agreement/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter une catégorie d'étude"
          to="/castres/work/agreement/create"
          access={[ROLE.ADMIN_CONFIGURATION_AGREEMENT_CREATE]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const WkAgreementCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/work/agreement/" route={APIRoute.WkAgreement} title="Catégorie d'étude" thin>
    <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
    <CmsFormInput.Text id="code" label="Code" required min={3} max={20} />
    <CmsFormInput.Text id="codeTotal" label="Code Total" min={4} max={4} />
    <CmsFormInput.Text id="codeActivity" label="Code activité" min={3} max={3} />
    <CmsFormInput.Switch id="usableInQuotation" label="Utilisable pour un devis" />
  </CmsForm>
);
