import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';
import { EqDomain } from 'interface/EqType';

const EqDomainConfigList: CmsColumnDef<EqDomain>[] = [
  {
    header: 'Nom',
    id: 'name',
    Filter: CmsTableFilter.Text,
  },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Select },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des comptes imputations de vente
 */
export const EqDomainList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_EQ_DOMAIN_CREATE])
    ? [<AddNavigationButton title="Ajouter un domaine" to="/castres/equipment/domain/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des domaines"
      route={APIRoute.EqDomain}
      columns={EqDomainConfigList}
      navigateTo={(id: number) => `/castres/equipment/domain/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const EqDomainCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/equipment/domain/', route: APIRoute.EqDomain };
  return (
    <CmsForm id={id} title="Création d'un domaine" {...props} thin>
      <CmsFormInput.Text id="name" label="Nom" required />
      <CmsFormInput.Text id="code" label="Code" required />
      <CmsFormInput.Switch id="active" label="Actif" />
      {!id && (
        <>
          <CmsFormInput.Text id="nature" label="Nature" required />
          <CmsFormInput.Text id="action" label="Action" required />
        </>
      )}
    </CmsForm>
  );
};
