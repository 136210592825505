import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { ClNetwork } from '../../../interface/ClType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const ClNetworkConfigList: CmsColumnDef<ClNetwork>[] = [{ header: 'Nom', id: 'name', Filter: CmsTableFilter.Text }];

export const ClNetworkList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_NETWORK_CREATE])
    ? [<AddNavigationButton title="Ajouter un réseau" to="/castres/client/network/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des réseaux"
      route={APIRoute.ClNetwork}
      columns={ClNetworkConfigList}
      navigateTo={(id: number) => `/castres/client/network/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const ClNetworkCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/client/network/', route: APIRoute.ClNetwork, title: 'Réseaux' };
  return (
    <CmsForm id={id} {...props} thin>
      <CmsFormInput.Text id="name" label="Nom" required min={3} max={255} />
    </CmsForm>
  );
};
