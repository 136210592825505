import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { CmsButton } from '../shared/Ui';
import { InputUI, UI } from '../shared';
import { CmsBackendTable, CmsColumnDef, CmsFrontendTable } from '../table/CmsTable';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { Breakpoint } from '@mui/system';
import CmsTableFilter from '../table/helper/CmsTableFilter';
import notificationService from '../../service/NotificationService';
import Utils from '../../helper/Utils';
import { CmsColumnDndDef, CmsDndTable } from '../table/CmsDndTable';
import { CmsForm } from 'component/form/CmsForm';
import AccessFilter from 'helper/AccessFilter';

/**
 * Interface définissant les propriétés du composant DuplicationForm.
 */
interface DuplicationFormProps {
  /**
   * Identifiant de l'objet à dupliquer.
   */
  originId: string;

  /**
   * Texte affiché sur le bouton déclencheur du formulaire de duplication.
   * Par défaut : 'Dupliquer'.
   */
  buttonLabel?: string;

  /**
   * Titre du tableau affichant les objets disponibles pour la duplication.
   * Par défaut : 'Sélectionner les objets sur lesquels dupliquer'.
   */
  tableTitle?: string;

  /**
   * URL ou route API permettant de récupérer la liste des objets disponibles pour la duplication.
   */
  getRoute: APIRoute | string;

  /**
   * URL ou route API permettant d'envoyer les données des duplications créées.
   */
  postRoute: APIRoute | string;

  /**
   * Configuration des colonnes du tableau listant les objets à sélectionner.
   */
  columns: any;

  /**
   * Fonction permettant de transformer ou de filtrer les données récupérées depuis `getRoute`.
   * S'applique uniquement pour les tableaux de type FrontEndTable.
   * Par défaut : retourne les données sans modification.
   */
  onGetData?: (data: any[]) => any[];

  /**
   * Largeur maximale de la fenêtre modale.
   * Utilise les valeurs des points de rupture de Material-UI (ex. : 'xl', 'lg', etc.).
   * Par défaut : 'xl'.
   */
  maxModalWidth?: Breakpoint | false;

  /**
   * Configuration des colonnes pour le tableau Drag-and-Drop (DndTable).
   * Permet de définir les champs éditables et leur ordre.
   */
  dndConfig: CmsColumnDndDef<any>[];

  /**
   * Titre du tableau Drag-and-Drop affiché lors de la configuration des duplications.
   * Par défaut : 'Configuration des duplications'.
   */
  dndTitle?: string;

  /**
   * Valeurs par défaut appliquées aux nouvelles duplications.
   * Peut être combiné avec un formulaire utilisateur pour les surcharger.
   */
  defaultValues?: any;

  /**
   * Nom de l'attribut contenant l'identifiant des objets sélectionnés dans la liste.
   * Exemple : 'serviceId'.
   */
  selectedIdLabel: string;

  /**
   * Composant React représentant un formulaire permettant à l'utilisateur
   * de surcharger les valeurs par défaut pour les duplications.
   */
  defaultValueForm?: ReactNode;

  /**
   * Liste des droits d'accès requis pour afficher ce composant.
   */
  access: Array<string>;

  /**
   * Indique si la liste des objets disponibles pour la duplication est vu via une BackEndTable ou via une FrontEndTable
   * Par défaut : `false` (FrontEndTable).
   */
  isBackend?: boolean;
}

/**
 * Composant DuplicationForm
 *
 * Ce composant permet de dupliquer des objets en sélectionnant une liste cible d'éléments.
 * Les données peuvent être récupérées via une API ou manipulées en local. L'utilisateur peut configurer les
 * duplications grâce à un tableau Drag-and-Drop. Il peut d'ailleurs surcharger les valeurs par défaut de ce DndTable via un formulaire.
 *
 * @param originId Identifiant de l'objet à dupliquer.
 * @param buttonLabel Texte affiché sur le bouton déclencheur du formulaire.
 * @param tableTitle Titre du tableau de sélection des objets pour la duplication.
 * @param getRoute Route API ou URL pour récupérer les objets disponibles.
 * @param postRoute Route API ou URL pour envoyer les duplications créées.
 * @param columns Configuration des colonnes pour la liste de sélection.
 * @param onGetData Fonction de transformation des données récupérées (FrontendTable uniquement).
 * @param maxModalWidth Largeur maximale de la fenêtre modale.
 * @param dndConfig Configuration des colonnes du tableau Drag-and-Drop.
 * @param dndTitle Titre du tableau Drag-and-Drop.
 * @param defaultValues Valeurs par défaut pour les duplications.
 * @param selectedIdLabel Attribut contenant l'identifiant des objets sélectionnés.
 * @param defaultValueForm Formulaire permettant de surcharger les valeurs par défaut.
 * @param access Droits d'accès requis pour afficher le composant.
 * @param isBackend Indique si la liste des objets est vu via une BackEndTable.
 *
 * @constructor
 */
export const DuplicationForm: FC<DuplicationFormProps> = ({
  originId,
  columns,
  buttonLabel = 'Dupliquer',
  tableTitle,
  getRoute,
  postRoute,
  onGetData = (x: any[]) => x,
  maxModalWidth = 'xl',
  dndConfig,
  dndTitle,
  defaultValues,
  defaultValueForm,
  selectedIdLabel,
  access,
  isBackend = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [defaultValueOpen, setDefaultValueOpen] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [dndState, setDndState] = React.useState<any>();
  const [dndOpen, setDndOpen] = React.useState(false);
  const [state, setState] = React.useState<any[]>([]);
  const [selectedList, setSelectedList] = React.useState<any[]>();

  useEffect(() => {
    if (isBackend || !open || !firstRender) return;
    setFirstRender(false);
    CRUD.getList<any>(`${getRoute}`).then((x) => {
      setState(Utils.orderListByAttr(onGetData(x), 'selected', true));
    });
  }, [firstRender, open, onGetData, getRoute, isBackend]);

  const backEndColumns = useMemo(() => createColumns(columns, 'add'), [columns]);
  const selectedlistColumns = useMemo(() => createColumns(columns, 'remove'), [columns]);
  const frontEndColumns = useMemo(() => createColumns(columns, 'frontend'), [columns]);

  if (access && !AccessFilter(access)) return <></>;

  const handleSave = () => {
    const source = isBackend ? (selectedList ?? []) : state.filter((x) => x.selected);
    const list = source.map((x) => ({
      [selectedIdLabel]: x.id,
      ...defaultValues,
    }));
    setOpen(false);
    if (!list || list.length === 0) return;
    if (defaultValueForm && list.length > 1) setDefaultValueOpen(true);
    else {
      setDndState(list);
      setDndOpen(true);
    }
  };

  const handleDefaultValues = (data: any) => {
    const source = isBackend ? (selectedList ?? []) : state.filter((x) => x.selected);
    const list = source.map((x) => ({
      [selectedIdLabel]: x.id,
      ...data,
    }));
    setDefaultValueOpen(false);
    setDndState(list);
    setDndOpen(true);
    return Promise.resolve();
  };

  const handleSubmit = () => {
    setIsSending(true);
    CRUD.post(`${postRoute}/Duplicate/${originId}`, { id: originId, list: dndState })
      .then(() => {
        notificationService.success('Duplication réussi');
        setDndOpen(false);
      })
      .finally(() => setIsSending(false));
  };

  const TableComponent = isBackend ? CmsBackendTable : CmsFrontendTable;

  return (
    <>
      <CmsButton onClick={() => setOpen(true)}>{buttonLabel}</CmsButton>
      <UI.Dialog fullWidth maxWidth={maxModalWidth} open={open} onClose={() => setOpen(false)}>
        {isBackend && selectedList && (
          <CmsFrontendTable
            columns={selectedlistColumns}
            title="Liste sélectionée"
            controlledState={{ state: selectedList, setState: setSelectedList }}
            actions={[
              <CmsButton key="cancel" color="inherit" onClick={() => setOpen(false)}>
                Abandonner
              </CmsButton>,
              <CmsButton key="save" disabled={isSending} onClick={handleSave}>
                Valider
              </CmsButton>,
            ]}
            route="none"
          />
        )}
        <TableComponent
          onCellClick={(cell) => {
            const row = cell.row.original;
            if (isBackend) {
              const list = selectedList ?? [];
              setSelectedList(list.includes(row) ? list.filter((x) => x !== row) : [...list, row]);
            } else {
              const item = state.find((x) => x.id === row.id);
              if (item) item.selected = !item.selected;
              setState([...state]);
            }
          }}
          actions={[
            <CmsButton key="cancel" color="inherit" onClick={() => setOpen(false)}>
              Abandonner
            </CmsButton>,
            !isBackend && (
              <CmsButton key="save" disabled={isSending} onClick={handleSave}>
                Valider
              </CmsButton>
            ),
          ]}
          columns={isBackend ? backEndColumns : frontEndColumns}
          title={tableTitle ?? 'Sélectionner les objets sur lesquels dupliquer'}
          route={isBackend ? getRoute : 'none'}
          controlledState={{ state, setState }}
        />
      </UI.Dialog>
      <UI.Dialog
        fullWidth
        maxWidth={maxModalWidth}
        open={defaultValueOpen}
        onClose={() => {
          setDefaultValueOpen(false);
        }}
      >
        <CmsForm
          title={'les valeurs par défaut pour les champs suivants pour toutes vos duplications'}
          currentUrl="none"
          route="none"
          onSubmit={handleDefaultValues}
          noReturn
          defaultValues={{ ...defaultValues }}
        >
          <>{defaultValueForm}</>
        </CmsForm>
      </UI.Dialog>
      <UI.Dialog
        fullWidth
        maxWidth={maxModalWidth}
        open={dndOpen}
        onClose={() => {
          setDndOpen(false);
          setDndState(null);
        }}
      >
        <CmsDndTable
          actions={[
            <CmsButton key="submit" disabled={isSending} onClick={handleSubmit}>
              Valider
            </CmsButton>,
          ]}
          title={dndTitle ?? 'Configuration des duplications'}
          stateHandler={{ state: dndState, setState: setDndState }}
          columns={dndConfig}
          withoutDnd
        />
      </UI.Dialog>
    </>
  );
};

const createColumns = (columns: any[], type: 'add' | 'remove' | 'frontend'): CmsColumnDef<any>[] => {
  const cell =
    type === 'frontend'
      ? (info: any) => <InputUI.CmsSwitch value={!!info.getValue()} onChange={() => {}} />
      : () => <CmsButton>{type === 'add' ? 'Ajouter' : 'Retirer'}</CmsButton>;

  const baseColumns =
    type === 'frontend'
      ? {
          header: 'Sélection',
          id: 'selected',
          size: 0,
          cell,
          Filter: CmsTableFilter.Bool,
        }
      : {
          header: 'Sélectionner',
          id: 'add',
          size: 0,
          cell,
        };

  return [baseColumns, ...columns];
};
