import { CellDndContext, CmsColumnDndDef, CmsDndTable } from '../../../component/table/CmsDndTable';
import { BlQuotationLine, BlQuotationStudyLine, BlStudyCategory } from '../../../interface/BlType';
import notificationService from '../../../service/NotificationService';
import { CmsMenuButton } from '../../../component/shared/Menu';
import CmsIcon from '../../../component/shared/CmsIcon';
import { DndCell, DndInput } from '../../../component/table/DnDTableCell';
import { Buttons, InputUI, UI } from '../../../component/shared';
import Tooltip from '@mui/material/Tooltip';
import Utils from '../../../helper/Utils';
import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { WkJob, WkUnit } from '../../../interface/WkType';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { QuotationArticleDataList, RefStudyDataList } from './QuotationImportPanel';
import './quotation.scss';
import { CmsPaper, CmsToolTip } from '../../../component/shared/Ui';

const ActionCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    let list = [...(x.cell.stateHandler.state as BlQuotationStudyLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleTransformChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, { id: Utils.getNewId(x.cell.stateHandler.state), chapter: true } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleCreateEmptyRow = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, { id: Utils.getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const line = x.row.original;
  return (
    <div className="flex-center">
      {(line.referenceStudyLine && (
        <CmsIcon
          icon="visible"
          tooltip={<TabStyleDataView conf={RefStudyDataList} mapFrom={line.referenceStudyLine} />}
        />
      )) ||
        (line.article && (
          <CmsIcon
            icon="visible"
            tooltip={<TabStyleDataView conf={QuotationArticleDataList} mapFrom={line.article} />}
          />
        ))}
      <CmsMenuButton
        preventClose={false}
        title={<CmsIcon icon="moreVertical" />}
        overLoadButton={(props) => (
          <CmsIcon icon="moreVertical" getMouseEvent onClick={props.handleClick} style={{ margin: '-0.2rem' }} />
        )}
      >
        {(x.row.original.chapter && []) || [<span onClick={handleTransformChapter}>Transformer en chapitre</span>]}
        <span onClick={() => handleCreateEmptyRow(false)}>Créer une minute avant</span>
        <span onClick={() => handleCreateEmptyRow(true)}>Créer une minute après</span>
        <span onClick={handleDelete}>Supprimer la minute</span>
      </CmsMenuButton>
    </div>
  );
};

const QuotationRefStudyConfig: CmsColumnDndDef<any>[] = [
  { header: 'Invariable', id: 'isFlatRate', size: 0, cell: DndInput.Switch, hideIfChapter: true },
  { header: 'Libellé', id: 'label', size: 600, cell: DndInput.Text, inputOptions: { multilineToggle: true } },
  {
    header: "Corps d'état",
    id: 'jobId',
    size: 120,
    cell: (x) => (
      <Tooltip title={x.row.original.jobName}>
        <span>
          <InputUI.AutoCompletor
            variant="standard"
            options={x.cell.lineSharedFunctionList.jobList}
            value={x.getValue()}
            onChange={(y: WkJob) => {
              let row = x.cell.stateHandler.state.find((z: any) => z.id === x.row.original.id);
              row.jobId = y.id;
              row.jobName = y.label;
              x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
            }}
            returnObject
            extendsProps={minWidthAutoComplete}
          />
        </span>
      </Tooltip>
    ),
    hideIfChapter: true,
  },
  {
    header: 'Catégorie',
    id: 'categoryId',
    size: 200,
    cell: (x) => {
      return (
        <Tooltip title={x.row.original.categoryLabel}>
          <span>
            <InputUI.AutoCompletor
              variant="standard"
              options={x.cell.lineSharedFunctionList.categoryList}
              value={x.getValue()}
              onChange={(y: BlStudyCategory) => {
                let row = x.cell.stateHandler.state.find((z: any) => z.id === x.row.original.id);
                row.categoryId = y.id;
                row.categoryLabel = y.label;
                row.markupFactor = y.markupFactor;
                x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
              }}
              extendsProps={minWidthAutoComplete}
              returnObject
            />
          </span>
        </Tooltip>
      );
    },
    hideIfChapter: true,
  },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    hideIfChapter: true,
    cell: (x) => (
      <Tooltip title={x.row.original.unitLabel}>
        <span>
          <InputUI.AutoCompletor
            variant="standard"
            options={x.cell.lineSharedFunctionList.unitList}
            value={x.getValue()}
            onChange={(y: WkUnit) => {
              let row = x.cell.stateHandler.state.find((z: any) => z.id === x.row.original.id);
              row.unitId = y.id;
              row.unitLabel = y.label;
              x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
            }}
            returnObject
            extendsProps={minWidthAutoComplete}
          />
        </span>
      </Tooltip>
    ),
  },
  { header: 'Quantité', id: 'quantity', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { header: 'Perte(%)', id: 'lossFactor', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    header: <CmsToolTip tooltip="Coût unitaire" title="Coût U." />,
    id: 'unitPrice',
    size: 0,
    hideIfChapter: true,
    cell: DndInput.Price,
  },
  {
    header: <CmsToolTip tooltip="Coût de revient" title="C.R." />,
    id: 'calculatedUnitPrice',
    size: 0,
    hideIfChapter: true,
    cell: DndCell.Price,
  },
  {
    header: <CmsToolTip tooltip="Taux de marge en pourcentage" title="T.M(%)" />,
    id: 'markupFactor',
    size: 0,
    cell: DndInput.Number,
    hideIfChapter: true,
  },
  {
    header: <CmsToolTip tooltip="Prix de vente souhaité (coût de revient x perte x marge)" title="P.V.S" />,
    id: 'calculatedMarkupPrice',
    size: 0,
    hideIfChapter: true,
    cell: DndCell.Price,
  },
  { header: 'Action', id: 'id', size: 0, cell: ActionCell },
];

const minWidthAutoComplete = {
  componentsProps: {
    paper: {
      sx: {
        minWidth: 400, // Width of the dropdown options window
      },
    },
  },
};

interface SubRowRefStudyProps {
  parentProps: any;
  state: BlQuotationLine[];
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  unitList: WkUnit[];
  categoryList: BlStudyCategory[];
  jobList: WkJob[];
}

export const SubRowRefStudy: FC<SubRowRefStudyProps> = ({
  parentProps,
  state,
  setState,
  unitList,
  categoryList,
  jobList,
}) => {
  const row = parentProps.row.original;
  const handleStateUpdate = (table: any[]) => {
    const toUpdate = state.find((x) => x.id === row.id);
    if (!toUpdate) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    toUpdate.blQuotationStudyLine = table;
    setState([...state]);
  };

  const handleCustomReorder = (draggedRowIndex: number, targetRowIndex: number) => {
    if (!parentProps.row.original.blQuotationStudyLine) return;
    const data = [...parentProps.row.original.blQuotationStudyLine];
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
    return handleStateUpdate(data.map((x, i) => ({ ...x, position: i })));
  };

  const handleCreateEmptyLine = () => {
    const data = [...parentProps.row.original.blQuotationStudyLine];
    data.push({ id: Utils.getNewId(state) } as any);
    handleStateUpdate(data);
  };

  return [
    <div
      key={'subrow-body-' + row.id}
      className="cms-grid quotation-study-line-dnd"
      style={{ gridTemplateColumns: '6rem auto', padding: '0.2rem' }}
    >
      <div className="flex-v" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h4>Minutes </h4>
        <h4>d'études: </h4>
        <Buttons.Default onClick={handleCreateEmptyLine}>Créer une ligne</Buttons.Default>
      </div>
      <CmsDndTable
        withoutPaper
        lineSharedFunctionList={{ unitList, categoryList, jobList }}
        stateHandler={{ state: row.blQuotationStudyLine, setState: handleStateUpdate as any }}
        columns={QuotationRefStudyConfig}
        rowStyle={quotationStudyLineStyle}
        handleCustomReorder={handleCustomReorder}
      />
    </div>,
  ];
};

const quotationStudyLineStyle = (row: any) => {
  let style: CSSProperties = {};
  // if (row.original.id === selectedRow?.original?.id) style = { outline: '2px solid #88FFFF' };
  if (row.original.rowColor) style = { ...style, backgroundColor: row.original.rowColor };
  if (!row.original.chapter) return style;
  return { ...style, backgroundColor: '#1c103f' };
};

export const QuotationHelpModal: FC = () => {
  const [showHelp, setShowHelp] = React.useState(false);
  return [
    <CmsIcon icon="help" tooltip="Aide pour remplir un devis" onClick={() => setShowHelp(true)} />,
    <UI.Dialog open={showHelp} onClose={() => setShowHelp(false)}>
      <CmsPaper
        title="Aide pour les lignes de devis"
        style={{ marginBottom: 0, maxHeight: '80vh' }}
        actions={[<CmsIcon icon="close" onClick={() => setShowHelp(false)} />]}
        scrollable
      >
        <ul style={{ marginLeft: '0.5rem' }}>
          <li>
            1) Vous pouvez enregistrer un devis avec des avertissements. Cependant, la validation ne sera possible
            qu'une fois tous les avertissements résolus.
          </li>
          <li>
            2) Chaque ligne de devis (à l'exception des chapitres) doit être associée à au moins une minute d'étude.
          </li>
          <li>
            3) Pour définir le « Coût unitaire » d'une ligne de devis à partir du « Prix de vente souhaité »,
            double-cliquez sur la cellule « Prix de vente souhaité » de la ligne concernée.
          </li>
        </ul>
      </CmsPaper>
    </UI.Dialog>,
  ];
};
