import CmsTableFilter from 'component/table/helper/CmsTableFilter';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton, ArrowBackNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import ROLE from '../../../constant/role.constant';
import { BlFixedCostContribution, BlFixedCostContributionLine } from 'interface/BlType';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { CmsPaper } from 'component/shared/Ui';
import CRUD from 'service/CRUD.service';
import { useNavigate } from 'react-router-dom';
import { handleErrorPage } from 'helper/handle-response';
import LoadingScreen from 'component/LoadingScreen';
import { TabStyleDataView } from 'component/shared/TabStyleDataView';
import { LabelValue } from 'interface/CommonType';
import { DuplicationForm } from 'component/form/DuplicationForm';
import { SfServiceListConfig } from '../agency/SfService.pack';
import { CellDndContext, CmsColumnDndDef } from 'component/table/CmsDndTable';
import { DndInput } from 'component/table/DnDTableCell';
import CmsIcon from 'component/shared/CmsIcon';
import NotificationService from 'service/NotificationService';
import { CmsFormInput } from 'component/form/CmsFormInput';

export const BlFixedCostContributionListLineConfig: CmsColumnDef<BlFixedCostContributionLine>[] = [
  { header: "Chiffre d'affaires minimum", id: 'caMin', Filter: CmsTableFilter.Number },
  { header: "Chiffre d'affaires maximum", id: 'caMax', Filter: CmsTableFilter.Number },
  { header: 'Pourcentage de frais fixe (en %)', id: 'fixedCostPercentage', Filter: CmsTableFilter.Number },
  {
    header: 'Service',
    id: 'serviceId',
    cell: (info) => <CmsTableCell.Ref cell={info} value={info.row.original.serviceLabel} />,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Dates de début de validité',
    id: 'startDate',
    Filter: CmsTableFilter.Date,
    defaultFilterValue: { value: new Date(), type: 10 },
  },
  {
    header: 'Dates de fin de validité',
    id: 'endDate',
    Filter: CmsTableFilter.Date,
    defaultFilterValue: { value: new Date(), type: 9 },
  },
];
const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    let list = [...(x.cell.stateHandler.state as BlFixedCostContributionLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) NotificationService.error('Erreur Dnd Table: ligne non trouvé');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      <CmsIcon icon="delete" tooltip="Supprimer" onClick={handleDelete} />
    </div>
  );
};

const dndConfig: CmsColumnDndDef<any>[] = [
  {
    header: 'Service',
    id: 'serviceId',
    size: 400,
    cell: DndInput.Select,
    inputOptions: { data: APIRoute.SfService + '/Simplified' },
  },
  {
    header: 'Date de début de validité',
    id: 'startDate',
    cell: DndInput.Date,
    size: 400,
  },

  { header: 'Actions', id: 'action', size: 20, cell: handleActions },
];

export const BlFixedCostContributionList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_CREATE])
    ? [
        <AddNavigationButton
          key="create"
          title="Ajouter une définition de frais fixes"
          to="/castres/billing/fixedcost/create"
        />,
      ]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des définitions de frais fixes"
      route={APIRoute.BlFixedCostContributionLine}
      columns={BlFixedCostContributionListLineConfig}
      navigateTo={(_, cell) => `/castres/billing/fixedcost/${cell.row.original.fixedCostContributionId}/show`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Fiche fixedCost (page show)
 * @param id identifiant de la fixedCost
 * @param setHttpCodePage fonction pour changer le code http de la page
 */
export const FixedCostDetail: React.FC = ({ id, setHttpCodePage }: any) => {
  const [fixedCost, setFixedCost] = useState<any>();
  const navigate = useNavigate();

  const returnToList = useCallback(() => {
    navigate('/castres/billing/fixedcost/list');
  }, [navigate]);

  useEffect(() => {
    if (id && !fixedCost) {
      setFixedCost({ isLoading: true });
      CRUD.getById(APIRoute.BlFixedCostContribution, id)
        .then((res: any) => (res ? setFixedCost(res) : returnToList()))
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else if (!id) returnToList();
    else if (id && fixedCost?.id && id !== fixedCost.id.toString()) setFixedCost(undefined);
  }, [id, fixedCost, navigate, returnToList, setHttpCodePage]);

  if (!fixedCost || fixedCost.isLoading) return <LoadingScreen />;

  return (
    <>
      <CmsPaper
        title="Général"
        style={{ display: 'flex', flexDirection: 'column', maxWidth: 600 }}
        actions={[
          <ArrowBackNavigationButton key="back" title="Retourner à la liste" to="/castres/billing/fixedcost/list" />,
        ]}
        tied
      >
        <TabStyleDataView conf={GeneralLabelValue} mapFrom={fixedCost} />
      </CmsPaper>
      <CmsFrontendTable
        title="Tranche de frais fixe"
        route="none"
        actions={[
          <AddNavigationButton
            key={'edit'}
            title="Editer la définition de frais fixe"
            to={`/castres/billing/fixedcost/${id}/edit`}
            access={[ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_EDIT]}
          />,
          <DuplicationForm
            key={'duplicate'}
            access={[ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_CREATE]}
            originId={id}
            getRoute={APIRoute.SfService}
            postRoute={APIRoute.BlFixedCostContribution}
            columns={SfServiceListConfig}
            dndConfig={dndConfig}
            defaultValues={{ startDate: fixedCost.startDate }}
            selectedIdLabel="serviceId"
            defaultValueForm={[
              <CmsFormInput.Date key={'startDate'} id="startDate" label="Date de début de validité" dateOnlyFormat />,
            ]}
          />,
        ]}
        showHeader="never"
        columns={localFixedCostLineColumn}
        initialPageSize={100}
        controlledState={{ state: fixedCost.blFixedCostContributionLine ?? [], setState: () => {} }}
      />
    </>
  );
};

const GeneralLabelValue: LabelValue[] = [
  { label: 'Service', value: (x: BlFixedCostContribution) => x.serviceLabel },
  { label: 'Date de début', value: (x: BlFixedCostContribution) => x.startDate },
];
const localFixedCostLineColumn: CmsColumnDef<BlFixedCostContributionLine>[] = [
  { header: "Chiffre d'affaires minimum", id: 'caMin' },
  { header: "Chiffre d'affaires maximum", id: 'caMax' },
  { header: 'Pourcentage de frais fixe (en %)', id: 'fixedCostPercentage' },
];
