import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import AccessFilter from '../../helper/AccessFilter';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  ViewNavigationButton,
} from '../../component/shared/Buttons';
import APIRoute from '../../constant/API.constant';
import { UsUsers } from '../../interface/UsType';
import CRUD from '../../service/CRUD.service';
import LoadingScreen from '../../component/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { CmsButton, CmsPaper, HeaderPanel } from '../../component/shared/Ui';
import './user.scss';
import { TabStyleDataView } from '../../component/shared/TabStyleDataView';
import { IdLabel, LabelCalc, LabelValue } from '../../interface/CommonType';
import homme from '../../style/assets/avatar-homme.svg';
import femme from '../../style/assets/avatar-femme.svg';
import Utils from '../../helper/Utils';
import {
  CaptionProps,
  ContextOptions,
  getSchedulerFilterValues,
  Scheduler,
  SchedulerFilter,
} from '../../component/scheduler/Scheduler';
import NotificationService from '../../service/NotificationService';
import CmsIcon from '../../component/shared/CmsIcon';
import { Buttons, InputUI, UI } from '../../component/shared';
import { habilitationConf } from '../rh/SfHabilitation.pack';
import { schedulerFormatter } from '../../component/scheduler/schedulerFormatter';
import { Popper } from '../../component/scheduler/Poppers';
import { BASE_URL } from '../../constant/API_URL';
import { PaidLeaveLight, Subordinates } from '../../interface/SfType';
import themingService from '../../component/ThemingService';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import CmsFilterCalculator from '../../component/table/helper/CmsTableFilterCalculator';
import { filterPlanningConf } from '../rh/SfAbsence.pack';
import { DialogVanillaUI } from '../../component/shared/DialogUI';
import { setCurrentUser } from '../../service/Authentication.service';
import { darkTheme, lightTheme } from '../../component/Theming';
import { GlobalContext } from '../../context/Global.context';
import ROLE from '../../constant/role.constant';

/**
 * Liste des colonnes de la table des utilisateurs
 */

const usUsersConfigList: CmsColumnDef<UsUsers>[] = [
  { header: 'Id', id: 'id', Filter: CmsTableFilter.Text, size: 60, hide: 'hiddenByDefault' },
  { header: "Nom d'utilisateur", id: 'username', Filter: CmsTableFilter.Text },
  { header: 'Nom', id: 'lastname', Filter: CmsTableFilter.Text },
  { header: 'Prénom', id: 'firstname', Filter: CmsTableFilter.Text },
  { header: 'Service', id: 'service', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  {
    header: 'Agence',
    id: 'agency',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Entité du contrat',
    id: 'legalEntityName',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
  },
  { header: 'Matricule (N° Sage)', id: 'sageNumber', Filter: CmsTableFilter.Text, hide: true },
  { header: "Corps d'état", id: 'job' },
  { header: 'Emploi occupé', id: 'jobTitle', hide: 'hiddenByDefault' },
  { header: 'Groupe', id: 'group', minSize: 200, cmsSortingFn: CmsFilterCalculator.sort.string },
  {
    header: "Date d'embauche",
    id: 'startDate',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Date de fin de contrat',
    id: 'endDate',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Téléphones',
    id: 'allPhone',
    hide: 'hiddenByDefault',
    cell: (info) => (
      <>
        <div>Pro :{info.row.original?.workPhone}</div>
        <div>Mob :{info.row.original?.mobile}</div>
        <div>Perso :{info.row.original?.phone}</div>
      </>
    ),
  },
  { header: 'Téléphone Pro', id: 'workPhone', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Téléphone Mobile', id: 'mobile', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Téléphone Perso', id: 'phone', Filter: CmsTableFilter.Text, hide: true },
  {
    header: "Date de péremption de la carte d'habilitation",
    id: 'isHabiliationUpToDate',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.DateWithLimit,
  },
  { header: 'Email professionnel', id: 'email', hide: 'hiddenByDefault', Filter: CmsTableFilter.Select },
  { header: 'Email personnel', id: 'emailPerso', hide: true, Filter: CmsTableFilter.Select },
  {
    header: "Corps d'état",
    id: 'jobId',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: APIRoute.WkJob },
  },
  {
    header: "Statut de péremption de la carte d'habilitation",
    id: 'deadLineStatus',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 0, label: 'Sans date de péremption' },
        { id: 1, label: 'Dans moins de 90 jours' },
        { id: 2, label: 'Dans moins de 30 jours' },
        { id: 3, label: 'Dans moins de 15 jours' },
        { id: 4, label: 'Périmé' },
      ],
    },
  },
  {
    header: 'Groupe',
    id: 'groupIdList',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: APIRoute.UsGroups },
    filterFn: CmsFilterCalculator.containsSome,
  },
  {
    header: 'Actif',
    id: 'enabled',
    Filter: CmsTableFilter.Bool,
    defaultFilterValue: true,
    hide: 'hiddenByDefault',
    size: 60,
  },
];

/**
 * Liste des indicateurs de la table des utilisateurs
 */
const UserIndicatorList: Array<LabelCalc> = [
  {
    label: "Carte d'habilitation périmée",
    revertColor: true,
    calc: (rows: Array<any>) => {
      const total = rows.length;
      const expired = rows.filter((x: any) => {
        const val = x.original.isHabiliationUpToDate;
        const time = new Date(val);
        if (!val || time.getTime() === 0) return false;
        return val < new Date();
      }).length;
      return { count: expired, ratio: expired / total };
    },
  },
];

/**
 * Page de liste des utilisateurs (GenericTablePage)
 */

export const UsUsersList: FC = () => {
  const actions = [];
  if (AccessFilter([ROLE.SONATA_USER_ADMIN_USER_EDIT]))
    actions.push(<ViewNavigationButton title="Organigramme" to="/castres/user/organizationalChart" />);
  if (AccessFilter([ROLE.SONATA_USER_ADMIN_USER_CREATE]))
    actions.push(<AddNavigationButton title="Ajouter un utilisateur" to="/sonata/user/user/create" />);
  return (
    <CmsFrontendTable
      indicatorList={UserIndicatorList}
      columns={usUsersConfigList}
      title="Liste des utilisateurs"
      route={APIRoute.UsUsers}
      navigateTo={(id: number) => `/sonata/user/user/${id}/show`}
      actions={actions}
      downloadable={AccessFilter([ROLE.SONATA_USER_ADMIN_USER_EXPORT]) && 'csv&excel'}
      setFiltersInUrl
    />
  );
};

/**
 * Page de visualisation d'un utilisateur (UsUsersShow)
 * @param id id de l'utilisateur
 */
export const UsUsersShow: FC = ({ id }: any) => {
  const [user, setUser] = useState<UsUsers>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/sonata/user/user/list');
    else CRUD.getById<UsUsers>(APIRoute.UsUsers, id).then(setUser);
  }, [setUser, id, navigate]);

  if (user === undefined) return <LoadingScreen />;
  return (
    <div className="user-container">
      <Header user={user} />
      <div className="first-line">
        <UserResume user={user} />
        <UserAdminInfo user={user} />
        <UserAuth user={user} />
        <UserContract user={user} />
      </div>
      <UserPlanning user={user} />
      <UserFormation user={user} />
      <UserAbsence user={user} />
    </div>
  );
};

/**
 * Header de la page de visualisation d'un utilisateur
 * @param user l'utilisateur
 */
const Header: FC<{ user: UsUsers }> = ({ user }) => {
  const subTitle = user.workphone ? `(Tel pro:${user.workphone ?? ''})` : '';
  return (
    <HeaderPanel
      title={user.getFormattedName}
      subtitle={subTitle}
      actions={[
        <ArrowBackNavigationButton title="Retourner à la liste" to="/sonata/user/user/list" />,
        <EditNavigationButton
          access={[ROLE.SONATA_USER_ADMIN_USER_EDIT]}
          title="Éditer l'utilisateur"
          to={`/sonata/user/user/${user.id}/edit`}
        />,
      ]}
    />
  );
};

/**
 * Photo de l'utilisateur
 * @param user l'utilisateur
 */
const UserResume: FC<{ user: UsUsers }> = ({ user }) => {
  const [userPic, setUserPic] = useState<Blob | null>();
  useEffect(() => {
    CRUD.getBlob(`${APIRoute.UsUsers}/${user.id}/Pic`, null, null, true).then(setUserPic);
  }, [user]);
  let imageUrl: string;
  const havePic = !!userPic && userPic.size > 0;
  if (havePic) {
    const urlCreator = window.URL || window.webkitURL;
    imageUrl = urlCreator.createObjectURL(userPic);
  } else imageUrl = user.gender === 'm' ? homme : femme;
  const className = havePic ? '' : ' holder';
  return (
    <div className="cms-logo-container">
      <div className="cms-logo">
        <div className={'user-icon' + className} style={{ backgroundImage: `url(${imageUrl})` }} />
      </div>
    </div>
  );
};

/**
 * Traduit un booléen en oui ou non
 * @param bool le booléen
 */
const yesNo = (bool: boolean | undefined): string => (bool ? 'oui' : 'non');
export const UserPackageStatus: any[] = [
  { id: '0', intId: 0, label: 'Non forfaitisé : paie calculé avec temps de trajet / zone' },
  { id: '1', intId: 1, label: 'Non forfaitisé : paie uniquement en temps de travail' },
  { id: '2', intId: 2, label: 'Forfaitisé' },
];

/**
 * Informations administratives de l'utilisateur
 */
const UserAdminLabelData: LabelValue[] = [
  { label: 'Nom', value: (user: UsUsers) => user.lastname },
  { label: 'Prénom', value: (user: UsUsers) => user.firstname },
  { label: 'Code OISO', value: (user: UsUsers) => user.oisoId },
  { label: 'Email personnel', value: (user: UsUsers) => user.emailPerso },
  { label: 'Sexe', value: (user: UsUsers) => (user.gender === 'm' ? 'Homme' : 'Femme') },
  { label: 'Adresse', value: (user: UsUsers) => user.address },
  { label: 'Code postal', value: (user: UsUsers) => user.postcode },
  { label: 'Ville', value: (user: UsUsers) => user.city },
  { label: 'Téléphone personnel', value: (user: UsUsers) => Utils.phoneNumber(user.phone) },
  { label: 'Téléphone pro', value: (user: UsUsers) => Utils.phoneNumber(user.workphone) },
  { label: 'Téléphone mobile', value: (user: UsUsers) => Utils.phoneNumber(user.mobile) },
  { label: 'Type de permis', value: (user: UsUsers) => user.licenseTypeListLabel },
  { label: 'Détaché', value: (user: UsUsers) => yesNo(user.detached) },
  { label: 'Voyage complet payé', value: (user: UsUsers) => yesNo(user.fullTravelingPaid) },
  { label: 'Forfait', value: (user: UsUsers) => UserPackageStatus[user.packageStatus ?? 0].label },
  { label: 'Absence: email perso', value: (user: UsUsers) => (user.usePersonalEmail ? 'oui' : 'non') },
  { label: 'Fichier de logs technicien', value: (user: UsUsers) => (user.sendApkLog ? 'oui' : 'non') },
  { label: 'Seuil de validation de devis (€)', value: (user: UsUsers) => user.maxAllowedQuotationAmount + '€' },
  { label: 'Demi-journée congé payé', value: (user: UsUsers) => (user.halfDayPaidLeave ? 'oui' : 'non') },
  { label: 'Commentaire médical', value: (user: UsUsers) => user.lastMedicalVisitComment ?? '' },
  { label: 'Risque(s) au poste', value: (user: UsUsers) => <MedicalRiskList user={user} /> },
  { label: 'Congés', value: () => 'Les données ci-dessous sont non contractuelles' },
  { label: 'Jours de congés restant avant validation par les RH', value: (x: PaidLeaveLight) => x.paidLeave },
  { label: 'Jours de congés restant estimés', value: (x: PaidLeaveLight) => x.futurPaidLeave },
  { label: 'Jours de congés restant avant validation par les RH', value: (x: PaidLeaveLight) => x.n1PaidLeave },
  { label: 'Jours de congés restant estimés', value: (x: PaidLeaveLight) => x.n1FuturPaidLeave },
  { label: 'Jours de RTT restant avant validation par les RH', value: (x: PaidLeaveLight) => x.rtt },
  { label: 'Jours de RTT restant estimés', value: (x: PaidLeaveLight) => x.futurRtt },
  { label: 'Jours de RTT restant avant validation par les RH', value: (x: PaidLeaveLight) => x.n1Rtt },
  { label: 'Jours de RTT restant estimés', value: (x: PaidLeaveLight) => x.n1FuturRtt },
  { label: 'Manager', value: (user: UsUsers) => user.managerName },
  { label: 'Subalternes directs', value: (sub: Subordinates) => <SubordinatesView list={sub.users} /> },
];

const SubordinatesView: FC<{ list?: UsUsers[] }> = ({ list }) => {
  if (!list) return <></>;
  return (
    <div style={{ overflowY: 'auto', maxHeight: '5em' }}>
      {list.map((x) => (
        <div>{x.getFormattedName}</div>
      ))}
    </div>
  );
};

/**
 * Liste des types de suivi individuel
 */
export const OccupationalMedicineList: IdLabel[] = [
  { id: 0, label: '' },
  { id: 1, label: 'Suivi Individuel renforcé' },
  { id: 2, label: 'Suivi Individuel adapté' },
  { id: 3, label: 'Suivi Individuel général' },
];

/**
 * Liste des risques médicaux
 * @param user l'utilisateur
 */
const MedicalRiskList: FC<{ user: UsUsers }> = ({ user }) => {
  const line = (x: any, i: any) => (
    <li key={i}>
      {x.label}
      {x.comment ? <div>Commentaire: {x.comment}</div> : <></>}
    </li>
  );
  return (
    <>
      <div>{OccupationalMedicineList[user.occupationalMedicine ?? -1]?.label ?? ''}</div>
      <ul style={{ marginLeft: '2em' }}>{user.sfRiskUser && user.sfRiskUser.map(line)}</ul>
    </>
  );
};

/**
 * Affiche les informations administratives de l'utilisateur
 * @param user l'utilisateur
 */
const UserAdminInfo: FC<{ user: UsUsers }> = ({ user }) => {
  const [paidLeave, setPaidLeave] = useState({});
  const [subordinates, setSubordinates] = useState({});

  useEffect(() => {
    CRUD.getById<PaidLeaveLight>(APIRoute.SfAbsence + '/AllCurrentPaidLeave', user.id).then(setPaidLeave);
  }, [user.id]);

  useEffect(() => {
    const params = '?onlyDirect=true&onlyActiveUser=true';
    CRUD.getList<UsUsers>(`${APIRoute.UsUsers}/Subordinates/${user.id}`, params).then((result: UsUsers[]) => {
      setSubordinates({ users: result ?? [] });
    });
  }, [user.id]);

  return (
    <CmsPaper title="Informations administratives" className="user-admin-info">
      <TabStyleDataView conf={UserAdminLabelData} mapFrom={{ ...paidLeave, ...user, ...subordinates }} />
    </CmsPaper>
  );
};

/**
 * Les informations contractuelles de l'utilisateur
 */
const UserContractLabelData: LabelValue[] = [
  { label: 'Service', value: (user: UsUsers) => user.serviceName },
  { label: 'Entité légale', value: (user: UsUsers) => user.legalEntityName ?? 'RH géré sur un autre CMS' },
  { label: "Corps d'état", value: (user: UsUsers) => user.jobName },
  { label: 'Emploi occupé', value: (user: UsUsers) => user.jobTitle },
  { label: 'Type de contrat', value: (user: UsUsers) => user.contract },
  { label: 'Statut', value: (user: UsUsers) => EmployeeStatusList[user.employeeStatus - 1]?.label ?? '' },
  { label: "Date d'embauche", value: (user: UsUsers) => Utils.displayDate(user.startDate) },
  { label: 'Date de fin de contrat', value: (user: UsUsers) => Utils.displayDate(user.endDate) },
  { label: 'Temps de travail', value: (user: UsUsers) => user.worktimeLabel },
  { label: 'Zone', value: (user: UsUsers) => yesNo(user.haveArea) },
  { label: 'Matricule (N° Sage)', value: (user: UsUsers) => user.sageNumber },
  { label: 'Heures de travail hebdo', value: (user: UsUsers) => user.contractualHour },
  { label: 'Nombre total annuel de congés payés', value: (user: UsUsers) => user.paidLeaveTotal },
  { label: 'Nombre total annuel de RTT', value: (user: UsUsers) => user.rttPerYear },
];

/**
 * Liste des statuts d'employé
 */
export const EmployeeStatusList: IdLabel[] = [
  { id: 1, label: 'Cadre' },
  { id: 2, label: 'Ouvrier' },
  { id: 3, label: 'Technicien agent de maitrise' },
  { id: 4, label: 'Employé' },
];

/**
 * Affiche les informations contractuelles de l'utilisateur
 * @param user l'utilisateur
 */
const UserContract: FC<{ user: UsUsers }> = ({ user }) => {
  return (
    <CmsPaper title="Contrat actuel" className="user-contract">
      <TabStyleDataView conf={UserContractLabelData} mapFrom={user} />
    </CmsPaper>
  );
};

/**
 * Les informations d'authentification de l'utilisateur
 */
const UserAuthLabelData: LabelValue[] = [
  { label: 'Identifiant', value: (user: UsUsers) => user.username },
  { label: 'Email professionnel', value: (user: UsUsers) => user.email },
  { label: 'Groupe', value: (user: UsUsers) => user.groupListName },
  { label: 'Actif', value: (user: UsUsers) => yesNo(user.enabled) },
  { label: 'Dernière connexion', value: (user: UsUsers) => Utils.displayDateTime(user.lastLogin) },
  { label: "Bloqué (trop d'erreurs de mot de passe)", value: (user: UsUsers) => yesNo(user.locked) },
  { label: 'Date dernier blocage', value: (user: UsUsers) => Utils.displayDateTime(user.lastLockLogin) },
  { label: 'Tentatives de connexion échouées', value: (user: UsUsers) => user.errorLogin ?? 0 },
  { label: 'Mot de passe joker (application mobile)', value: (user: UsUsers) => user.jokerPassword },
];

/**
 * Affiche les informations d'authentification de l'utilisateur
 * @param user l'utilisateur
 */
const UserAuth: FC<{ user: UsUsers }> = ({ user }) => {
  const actions = [<UserPreferenceShow user={user} />, <ImpersonateCurrentUser user={user} />];
  return (
    <CmsPaper title="Authentification" className="user-auth" actions={actions}>
      <TabStyleDataView conf={UserAuthLabelData} mapFrom={user} />
    </CmsPaper>
  );
};

const ImpersonateCurrentUser: FC<{ user: UsUsers }> = ({ user }) => {
  const { theming } = useContext(GlobalContext);
  if (!AccessFilter([ROLE.ALLOWED_TO_SWITCH])) return <></>;
  const handleClick = () => {
    CRUD.put<any>(APIRoute.Login + '/Impersonate', { id: user.id }).then((impersonateUser) => {
      setCurrentUser(impersonateUser);
      if (!!impersonateUser.preferences?.theme && theming.get().palette.mode !== impersonateUser.preferences.theme)
        theming.set(impersonateUser.preferences.theme === 'dark' ? darkTheme : lightTheme);
      window.location.href = '/castres/user/dashboard';
    });
  };
  return <CmsButton onClick={handleClick}>Se connecter avec cet utilisateur</CmsButton>;
};

const UserPreferenceShow: FC<{ user: UsUsers }> = ({ user }) => {
  const [open, setOpen] = useState(false);
  if (!user.preferences) return <></>;
  let value: any = undefined;
  try {
    const prefParsed = JSON.parse(user.preferences);
    if (prefParsed.settings) prefParsed.settings = JSON.parse(prefParsed.settings);
    if (prefParsed.history) prefParsed.history = JSON.parse(prefParsed.history);
    value = JSON.stringify(prefParsed, null, 2);
  } catch (e) {
    return <></>;
  }
  return (
    <>
      <CmsIcon icon="description" onClick={() => setOpen(true)} tooltip="Voir les préférences" />
      <DialogVanillaUI open={open} onClose={() => setOpen(false)}>
        <CmsPaper
          style={{ marginBottom: 0 }}
          scrollable
          title="Préférences enregistrées de l'utilisateur"
          className="user-preference"
          actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        >
          <pre>{value}</pre>;
        </CmsPaper>
      </DialogVanillaUI>
    </>
  );
};

/**
 * Liste des absences de l'utilisateur (ReactTable)
 * @param user l'utilisateur
 */
const UserAbsence: FC<{ user: UsUsers }> = ({ user }) => {
  if (!AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_LIST, ROLE.USER_IS_MANAGER])) return <></>;
  const list = filterPlanningConf('userPage');
  list.find((x) => x.id === 'statusName')!.defaultFilterValue = undefined;
  return (
    <CmsFrontendTable
      title="Liste des absences"
      route={`${APIRoute.SfAbsence}/User/${user.id}`}
      columns={list}
      initialPageSize={10}
      initialSorting={{ id: 'startDate', desc: true }}
      navigateTo={(id: number) => `/castres/staff/absence/${id}/edit`}
    />
  );
};

/**
 * Liste des habilitations de l'utilisateur (ReactTable)
 * @param user l'utilisateur
 */
const UserFormation: FC<{ user: UsUsers }> = ({ user }) => {
  const [modal, setModal] = useState(false);
  const config = useMemo(() => {
    const result = habilitationConf.filter((x) => x.id !== 'user' && x.id !== 'activeUser');
    result.find((x) => x.id === 'renewal')!.defaultFilterValue = undefined;
    return result;
  }, []);

  if (!AccessFilter([ROLE.ADMIN_STAFF_TRAINING_LIST, ROLE.USER_IS_MANAGER])) return <></>;

  const downloadExcel = () => {
    CRUD.getBlob(APIRoute.SfHabilitation + '/ExportPdf/' + user.id).then((blob) => {
      Utils.downloadFile(blob, `Habilitation ${user.getFormattedNameid}.pdf`);
    });
  };

  const qrCodeDownload = () => {
    CRUD.getBlob(APIRoute.SfHabilitation + '/QrCode/' + user.id).then((blob) => {
      Utils.downloadFile(blob, `QrCode ${user.getFormattedName}.bmp`);
    });
  };

  const habilitationDownload = () => {
    CRUD.getBlob(APIRoute.SfHabilitation + '/Pack/' + user.hashedUsername).then((blob) => {
      Utils.downloadFile(blob, `Habilitation ${user.getFormattedName}.zip`);
    });
  };

  const actions = [];
  if (AccessFilter([ROLE.ADMIN_STAFF_TRAINING_EXPORT])) {
    if (!user.isHabiliationUpToDate) {
      actions.push(
        <CmsIcon
          icon="warning"
          style={{ color: 'yellow' }}
          onClick={() => {}}
          tooltip={`Dernière Mise à jour le: ${
            !user.lastHabilitation ? 'Jamais' : Utils.displayDate(user.lastHabilitation)
          }`}
        />,
      );
    }
    actions.push(
      <CmsIcon icon="borderall" onClick={downloadExcel} tooltip="Générer une nouvelle carte d'habilation" />,
    );
    actions.push(<CmsIcon icon="upload" onClick={() => setModal(true)} tooltip="Téléverser habilitation" />);
    if (user.lastHabilitation)
      actions.push(<CmsIcon icon="download" onClick={habilitationDownload} tooltip="Télécharger habilitation" />);
    actions.push(<CmsIcon icon="qrcode" onClick={qrCodeDownload} tooltip="Télécharger QrCode" />);
  }

  return (
    <>
      <CmsFrontendTable
        title="Liste des habilitations"
        route={`${APIRoute.SfHabilitation}/User/${user.id}`}
        actions={actions}
        columns={config}
        initialPageSize={20}
        initialSorting={{ id: 'deadline', desc: false }}
        navigateTo={(id: number) => `/castres/staff/habilitation/${id}/edit`}
      />
      <HabilitationModal modal={modal} setModal={setModal} user={user} />
    </>
  );
};

interface HabilitationModalProps {
  // L'état de la modal
  modal: boolean;
  // Fonction pour changer l'état de la modal
  setModal: (open: boolean) => void;
  // L'utilisateur
  user: UsUsers;
}

/**
 * Modal pour téléverser une habilitation
 * @param modal l'état de la modal (boolean)
 * @param setModal fonction pour changer l'état de la modal
 * @param user l'utilisateur
 * @constructor
 */
const HabilitationModal: FC<HabilitationModalProps> = ({ modal, setModal, user }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<any>();

  const handleUpload = () => {
    if (!fileToUpload || !fileToUpload[0]) {
      NotificationService.error('Aucun pdf selectionné');
      return;
    }
    setIsUploading(true);
    CRUD.postFile(APIRoute.SfHabilitation + '/Pdf/' + user.id, fileToUpload[0])
      .then(() => {
        NotificationService.success('Habilitation téléverser avec succès');
        setModal(false);
      })
      .finally(() => setIsUploading(false));
  };

  return (
    <UI.Dialog open={modal} onClose={setModal}>
      <UI.Paper
        isLoading={isUploading}
        title="Téléverser l'habilitation Pdf"
        className="media-upload-modal"
        actions={[<CmsIcon icon="close" onClick={() => setModal(false)} />]}
      >
        <InputUI.InputFile name="hab-upload" id="input-file" onFileSelected={setFileToUpload} pdfOnly multiple />
        <UI.Divider />
        <div className="flex-h-around">
          <Buttons.Valid disabled={!fileToUpload || isUploading} onClick={handleUpload}>
            Téléverser
          </Buttons.Valid>
          <Buttons.Cancel onClick={() => setModal(false)}>Abandonner</Buttons.Cancel>
        </div>
      </UI.Paper>
    </UI.Dialog>
  );
};

/**
 * Liste des filtres des plannings de l'utilisateur
 */
const initialFilters: Array<SchedulerFilter> = [
  {
    title: 'Date de début',
    accessor: 'minDate',
    value: new Date(),
    isBackendFilter: true,
    isStartFilter: true,
    filter: 'date',
  },
  {
    title: 'Date de fin',
    accessor: 'maxDate',
    value: Utils.Date.addDays(new Date(), 15),
    isBackendFilter: true,
    isEndFilter: true,
    filter: 'date',
  },
  {
    title: 'Intervention ',
    accessor: 'ref',
    filter: 'AutoMultiselect',
  },
];

/**
 * Affichage du planning de l'utilisateur, contient les absences, les habilitations et les interventions (Tâches)
 * @param user l'utilisateur
 */
const UserPlanning: FC<{ user: UsUsers }> = ({ user }) => {
  const navigate = useNavigate();

  const contextualHabilitation: ContextOptions[] = [
    {
      label: "Voir l'habilition",
      isGroupClick: true,
      onClick: (x) => navigate(`/castres/staff/habilitation/${x.group?.habilitationId}/edit`),
    },
  ];

  const contextualTask: ContextOptions[] = [
    {
      label: "Voir l'intervention",
      if: (item) => item.type === 'task',
      onClick: (x) => {
        const url = `${BASE_URL}castres/work/intervention/${x.item?.value?.interventionId}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(url);
        else window.location.href = url;
      },
    },
    {
      label: "Voir l'intervention",
      isGroupClick: true,
      if: (item) => item.type === 'task',
      onClick: (x) => {
        const url = `${BASE_URL}castres/work/intervention/${x.group?.id}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(url);
        else window.location.href = url;
      },
    },
  ];

  return (
    <Scheduler
      paperTitle="Planning de la semaine"
      onFiltersChange={handleFiltering}
      initialFilters={initialFilters}
      captionList={CaptionList()}
      fetch={[
        {
          url: `${APIRoute.SfAbsence}/User/${user.id}`,
          formatter: schedulerFormatter.formatAbsence,
          accessFilter: ROLE.ADMIN_STAFF_ABSENCE_LIST,
        },
        {
          type: 'habilitation',
          url: `${APIRoute.SfHabilitation}/User/${user.id}`,
          formatter: schedulerFormatter.formatHabilitation,
          accessFilter: ROLE.ADMIN_STAFF_TRAINING_LIST,
          contextOptionList: contextualHabilitation,
        },
        {
          url: `${APIRoute.SfAssignment}/User/${user.id}`,
          formatter: schedulerFormatter.formatAssignment,
        },
        {
          type: 'task',
          url: `${APIRoute.WkTask}/ByUser/${user.id}`,
          formatter: schedulerFormatter.formatTask,
          accessFilter: ROLE.ADMIN_WORK_TASK_LIST,
          itemPopper: Popper.TaskPopper,
          groupPopper: GroupPopper,
          contextOptionList: contextualTask,
        },
      ]}
    />
  );
};

/**
 * Liste des légendes du planning
 */
const CaptionList = (): CaptionProps[] => {
  const color = themingService.get().cms.scheduler.caption;
  return [
    { type: 'absence', label: 'Absence', color: color.absence, enabled: true },
    { type: 'habilitation', label: 'Habilitation', color: color.habilitation, enabled: true },
    { type: 'assignment', label: 'Assignation', color: color.assignment, enabled: true },
    { type: 'task', label: 'Tâche', color: color.task, enabled: true },
  ];
};

/**
 * Gestion externe des filtres du planning, retourne true si les filtres sont valides
 * @param filterList liste des filtres
 */
function handleFiltering(filterList: SchedulerFilter[]): boolean {
  const { minDate, maxDate } = getSchedulerFilterValues(filterList);
  if (minDate === 'NaN-NaN-NaNTNaN:NaN:NaN' || maxDate === 'NaN-NaN-NaNTNaN:NaN:NaN') return false;
  if (new Date(minDate) <= new Date(maxDate)) return true;
  NotificationService.error('Date de début antérieure à la date de fin');
  return false;
}

/**
 * Affichage du popper d'une assignation/absence
 * @param group le groupe d'assignation/absence (ligne du planning)
 */
const GroupPopper = (group: any) => {
  if (!group) return <></>;
  if (group.type === 'absence') return <h2>Absences</h2>;
  if (group.type === 'assignment') return <h2>Assignation / Équipe</h2>;
  return (
    <>
      <h2>Intervention {group.id}</h2>
      <p>{group.title}</p>
    </>
  );
};

export const OrganizationalChart: FC = () => {
  const [svgContent, setSvgContent] = useState<string | null>(null);

  useEffect(() => {
    async function fetchSvgContent() {
      const response = await CRUD.getBlob(APIRoute.UsUsers + '/OrganizationalChart');
      const text = await response.text();
      // Retirez les attributs width et height du SVG
      const modifiedSvg = text.replace(/(width|height)="[^"]*"/g, '');
      setSvgContent(modifiedSvg);
    }
    fetchSvgContent();
  }, []);

  return svgContent ? (
    <div className="organizational-chart" dangerouslySetInnerHTML={{ __html: svgContent }} />
  ) : (
    <LoadingScreen />
  );
};
