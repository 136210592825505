import React, { FC, useMemo, useState } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkReportType } from '../../../interface/WkType';
import Grid from '@mui/material/Grid';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';
import { CellDndContext, CmsColumnDndDef, CmsDndTable, validateRequiredFields } from 'component/table/CmsDndTable';
import CmsIcon from 'component/shared/CmsIcon';
import NotificationService from 'service/NotificationService';
import { DndInput } from 'component/table/DnDTableCell';
import { Buttons } from 'component/shared';
import { CmsMenuButton } from 'component/shared/Menu';
import CRUD from 'service/CRUD.service';
import { useNavigate } from 'react-router-dom';
import Utils from 'helper/Utils';

export const WkReportTypeListConfig: CmsColumnDef<WkReportType>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Type', id: 'type', Filter: CmsTableFilter.Text },
  { header: "Code d'identification unique", id: 'uniq' },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault', defaultFilterValue: true },
];

const genericTypeCode = 'GENERIC';

/**
 * Liste des types de rapport d'intervention
 */
export const WkReportTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_REPORTTYPE_CREATE])
    ? [<AddNavigationButton title="Ajouter une ligne de rapport d'intervention" to="/castres/work/reporttype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des types de rapport d'intervention"
      route={APIRoute.WkReportType}
      columns={WkReportTypeListConfig}
      navigateTo={(id: number) => `/castres/work/reporttype/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

const createNewLine = (list: any[] = []) => ({
  id: Utils.getNewId(list),
  canRowExpand: true,
  active: true,
  wkReportItem: [{ id: Utils.getNewId(list), active: true }],
});

const SubRow: FC<{ row: any; state: any[]; setState: any }> = ({ row, state, setState }) => {
  const line = row.row.original;
  const handleStateUpdate = (table: any[]) => {
    const toUpdate = state.find((x) => x.id === line.id);
    if (!toUpdate) return NotificationService.error('Erreur Dnd Table: ligne non trouvée');
    toUpdate.wkReportItem = table;
    setState([...state]);
  };
  return (
    <CmsDndTable
      stateHandler={{ state: line.wkReportItem, setState: handleStateUpdate as any }}
      columns={reportCategoryConfig}
    />
  );
};

/**
 * Création ou édition d'un type de rapport d'intervention
 * @param id Id du type de rapport d'intervention
 */
export const WkReportTypeCreateEdit: FC = ({ id }: any) => {
  const [expanded, setExpanded] = useState<any>({});
  const [state, setState] = useState<any[]>([]);
  const [code, setCode] = useState<string>();
  const navigate = useNavigate();

  const hasExpanded = useMemo(() => {
    return Object.values(expanded).some((value) => value === true);
  }, [expanded]);

  const handleCodeChange = (x: any) => {
    if (x.target.value !== code) {
      setCode(x.target.value);
    }
    return x;
  };

  const handleAddCategory = () => {
    setState([...state, createNewLine(state)]);
    setExpanded((prevExpanded: any) => ({
      ...prevExpanded,
      [state.length]: true,
    }));
  };

  const handleToggleExpanded = () => {
    setExpanded(() => {
      const newState = Object.fromEntries(state.map((_: any, index: number) => [index, !hasExpanded]));
      return newState;
    });
  };

  return (
    <>
      <CmsForm
        id={id}
        currentUrl="/castres/work/reporttype/"
        route={APIRoute.WkReportType}
        title="un type de rapport d'intervention"
        actions={[
          <CmsIcon
            href="/help/Rapport_d'intervention/Creation_type_rapport"
            icon="help"
            tooltip="Aide"
            target="_blank"
          />,
        ]}
        defaultValues={{ active: true }}
        onGetEditData={(data) => {
          setCode(data.code);
          setExpanded(Object.fromEntries(data.wkReportCategory.map((_: any, index: number) => [index, true])));
          setState(
            data.wkReportCategory.length > 0
              ? data.wkReportCategory.map((cat: any) => ({
                  ...cat,
                  canRowExpand: true,
                  wkReportItem: cat.wkReportItem ?? [{ id: Utils.getNewId([]) }],
                }))
              : [],
          );
          return data;
        }}
        onSubmit={async (data: any, _, isRedirect?: boolean) => {
          data = {
            ...data,
            wkReportCategory: state,
          };
          if (data.code == genericTypeCode && data.wkReportCategory.length < 1) {
            NotificationService.error('Veuillez remplir au moins une categorie pour un rapport GENERIC');
            return data;
          }
          if (data.code != genericTypeCode && data.wkReportCategory.length > 0) {
            NotificationService.error('Vous ne pouvez remplir de categories pour un rapport non-GENERIC');
            return data;
          }
          if (validateRequiredFields(state, reportTypeConfig)) {
            if (
              state.every((categorie, index) =>
                validateRequiredFields(categorie.wkReportItem, reportCategoryConfig, index + 1),
              )
            ) {
              let result = await CRUD.post<any>(APIRoute.WkReportType, data, !!data.id);
              NotificationService.success(`${data.id ? 'Mise à jour' : 'Création'} réussie`);
              if (isRedirect) navigate('/castres/work/reporttype/list');
              else if (!data.id) navigate(`/castres/work/reporttype/${result.id}/edit`);
              return result;
            }
          }
          return data;
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Text id="label" label="Nom" required max={255} />
            <CmsFormInput.Text id="code" label="Code" required max={20} onChange={handleCodeChange} />
            <CmsFormInput.Text id="uniq" label="Code d'identification unique" required min={3} max={32} />
          </Grid>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Text id="type" label="Type" required max={4} />
            <CmsFormInput.Color id="color" label="Couleur" withoutSharp />
            <CmsFormInput.Switch id="active" label="Actif" />
          </Grid>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Select
              id="domainIdList"
              label="Domaines"
              options={`${APIRoute.WkDomain}/Simplified`}
              multiple
            />
            <CmsFormInput.Select
              id="campaignTypeIdList"
              label="Type de campagne"
              options={`${APIRoute.WkCampaignType}/Simplified`}
              multiple
            />
          </Grid>
        </Grid>
      </CmsForm>
      {code === genericTypeCode && (
        <CmsDndTable
          title="Catégories contenues dans ce rapport"
          stateHandler={{ state, setState }}
          columns={reportTypeConfig}
          actions={[
            <Buttons.Nav.View
              onClick={handleToggleExpanded}
              title={hasExpanded ? 'Fermer toutes les catégories' : 'Ouvrir toutes les catégories'}
            />,
            <Buttons.Nav.Add onClick={handleAddCategory} title="Ajouter une catégorie" />,
          ]}
          paperStyle={{ width: '1000px' }}
          expandStateHandler={{ state: expanded, setState: setExpanded }}
          SubRowComponent={(row) => SubRow({ row, state, setState })}
        />
      )}
    </>
  );
};

const handleActions: ((info: CellDndContext<any>, isSubRow: boolean) => any) | undefined = (x, isSubRow) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    let list = [...x.cell.stateHandler.state];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) NotificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleAddChildren = (first: boolean) => {
    const stateToUpdate = [...x.cell.stateHandler.state];
    const toUpdate = stateToUpdate.find((state) => state.id === x.row.original.id);
    const toUpdateIndex = stateToUpdate.findIndex((state) => state.id === x.row.original.id);
    toUpdate.wkReportItem = first
      ? [{ id: Utils.getNewId(toUpdate.wkReportItem), active: true }, ...toUpdate.wkReportItem]
      : [...toUpdate.wkReportItem, { id: Utils.getNewId(toUpdate.wkReportItem), active: true }];
    stateToUpdate[toUpdateIndex] = toUpdate;
    x.cell.stateHandler.setState(stateToUpdate);
  };

  const handleSubRowAdd = (first: boolean) => {
    const list = x.cell.stateHandler.state;
    if (first) x.cell.stateHandler.setState([{ id: Utils.getNewId(list), active: true }, ...list]);
    else x.cell.stateHandler.setState([...list, { id: Utils.getNewId(list), active: true }]);
  };

  return (
    <div className="flex-center">
      {x.row.original.id < 0 && x.cell.stateHandler.state.length > 1 && (
        <CmsIcon icon="delete" tooltip="Supprimer" onClick={handleDelete} />
      )}

      <CmsMenuButton
        key={'action-group' + x.row.original.id}
        preventClose={false}
        title="Menu action"
        className="button-group"
        overLoadButton={(props) => (
          <CmsIcon key={'action'} icon="moreVertical" getMouseEvent onClick={props.handleClick} />
        )}
      >
        <span onClick={() => (isSubRow ? handleSubRowAdd(true) : handleAddChildren(true))}>
          Ajouter une nouvelle ligne au début
        </span>
        <span onClick={() => (isSubRow ? handleSubRowAdd(false) : handleAddChildren(false))}>
          Ajouter une nouvelle ligne à la fin
        </span>
      </CmsMenuButton>
    </div>
  );
};

const reportTypeConfig: CmsColumnDndDef<any>[] = [
  { header: 'Nom', id: 'name', cell: DndInput.Text, required: true },
  { id: 'active', header: 'Active', cell: DndInput.Switch },
  { header: 'Actions', id: 'action', size: 20, cell: (info) => handleActions(info, false) },
];
const reportCategoryConfig: CmsColumnDndDef<any>[] = [
  { id: 'name', header: 'Nom', cell: DndInput.Text, required: true },
  { id: 'description', header: 'Description', cell: DndInput.Text },
  { id: 'active', header: 'Active', cell: DndInput.Switch },

  { header: 'Actions', id: 'action', size: 20, cell: (info) => handleActions(info, true) },
];
