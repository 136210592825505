import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import CRUD from '../../../service/CRUD.service';
import { CmsImportPanel, CmsTreeView } from '../../../component/shared/ImportPanel';
import { Buttons, InputUI, UI } from '../../../component/shared';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import {
  BlPriceSchedule,
  BlPriceScheduleGroup,
  BlQuotationLine,
  BlQuotationStudyLine,
  BlReferenceStudyGroup,
  BlReferenceStudyGroupLine,
  BlReferenceStudyLine,
  BlVat,
} from '../../../interface/BlType';
import Tooltip from '@mui/material/Tooltip';
import { CmsLink, CmsPaper, CmsToolTip } from '../../../component/shared/Ui';
import { BlReferenceStudyTypeOptions } from '../../configuration/schedule/BlStudyCategory.pack';
import Utils from '../../../helper/Utils';
import { SkArticle } from '../../../interface/SkType';
import notificationService from '../../../service/NotificationService';
import CmsIcon from '../../../component/shared/CmsIcon';
import LoadingScreen from '../../../component/LoadingScreen';
import './quotation.scss';

interface ImportPanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleImport: (importItem: any, asLine: boolean) => void;
  vat20: BlVat;
  handleSaveButtons: any;
}

interface Category {
  id: number;
  label: ReactNode;
  route: APIRoute;
}

const categories: Category[] = [
  { id: 0, label: "Référence d'étude", route: APIRoute.BlReferenceStudyLine },
  {
    id: 1,
    label: (
      <CmsToolTip
        tooltip="Attention, les lignes sont importées avec leur quantité rattachée par unité de groupe"
        title="Groupe de référence d'étude"
      />
    ),
    route: APIRoute.BlReferenceStudyGroup,
  },
  { id: 2, label: 'Article', route: APIRoute.SkArticle },
  {
    id: 3,
    label: (
      <CmsToolTip
        tooltip="Attention, les lignes sont importées avec leur quantité rattachée par unité de bordereau et de groupe de référence d'études"
        title="Bordereau"
      />
    ),
    route: APIRoute.BlPriceScheduleGroup,
  },
];

function mapRefStudyToTree(refStudy: BlReferenceStudyGroup): any {
  const children = refStudy.blReferenceStudyGroupLine?.map((x) => {
    return {
      ...x.line,
      id: 'line' + x.id,
      quantity: x.quantity * (x.line.quantity ?? 0),
      costPrice: x.quantity * (x.line.costPrice ?? 0),
      markupPrice: x.quantity * (x.line.markupPrice ?? 0),
    };
  });
  return { ...refStudy, id: 'group' + refStudy.id, children };
}

function mapPriceScheduleToTree(group: any): any {
  return { ...group, id: 'group' + group.id, children: group.children.map(recursiveScheduleMapping) };
}

function recursiveScheduleMapping(line: any) {
  if ((line.children?.length ?? 0) === 0) line.id = 'line' + line.id;
  else line = { ...line, id: 'chapter' + line.id, children: line.children.map(recursiveScheduleMapping) };
  return line;
}

function findInRecursiveTree(selectedList: any[], id: string): any {
  for (const item of selectedList) {
    if (item.id === id) return item;
    if (!item.children) continue;
    const result = findInRecursiveTree(item.children, id);
    if (result) return result;
  }
}

function getAllChildrenIdRecursively(item: any) {
  if (!item.children) return [item.id];
  return [item.id, ...item.children.map(getAllChildrenIdRecursively).flat()];
}

const handleSelectionCascade = (items: any[], treeState: any, setTreeState: any, selectedList: any[], type: any) => {
  if (type === 0 || type === 2) return setTreeState(items);
  const newId = items.find((x) => !treeState.find((y: any) => y === x))?.toString();
  const oldId = treeState.find((x: any) => !items.find((y: any) => y === x))?.toString();
  if (!newId && !oldId) return;
  if (newId) {
    if (newId.startsWith('line')) return setTreeState(items);
    if (newId.startsWith('chapter')) {
      const missingLines = findInRecursiveTree(selectedList, newId)?.children?.map(getAllChildrenIdRecursively).flat();
      return setTreeState(Utils.distinct([...items, ...(missingLines ?? [])]));
    }
    const newGroup = selectedList.find((x: any) => x.id === newId);
    if (!newGroup?.children) return notificationService.error('Groupe non trouvé');
    const missingLines = newGroup.children.map(getAllChildrenIdRecursively).flat();
    return setTreeState(Utils.distinct([...missingLines, newId]));
  } else {
    if (oldId.startsWith('line')) return setTreeState(items);
    if (oldId.startsWith('chapter')) {
      const missingLines = findInRecursiveTree(selectedList, oldId)?.children?.map(getAllChildrenIdRecursively).flat();
      if (missingLines?.length > 0)
        return setTreeState(items.filter((x: any) => !missingLines.find((y: any) => y === x)));
    }
    setTreeState([]);
  }
};

export const QuotationImportPanel: FC<ImportPanelProps> = ({
  open,
  setOpen,
  handleImport,
  vat20,
  handleSaveButtons,
}) => {
  const [selectedButton, setSelectedButton] = useState(0);
  const [search, setSearch] = useState<string | undefined>();
  const [list, setList] = useState<any[]>([]);
  const [treeState, setTreeState] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const isSimpleList = selectedButton === 0 || selectedButton === 2;

  const handleChangeCategory = (index: number) => {
    setSelectedButton(index);
    handleSearch(search, index);
  };

  const handleSearch = (search: string | undefined, category: number) => {
    setSearch(search);
    if (!search || search.length < 3) return;
    setList([]);
    setTreeState([]);
    setLoading(true);
    CRUD.getList<any>(categories[category].route + '/WithData/Suggested?search=' + search)
      .then((x) => {
        if (isSimpleList) return setList(x);
        if (category === 1) return setList(x.map(mapRefStudyToTree));
        setList(x.map(mapPriceScheduleToTree));
      })
      .finally(() => setLoading(false));
  };

  const handleCardClick = (item: any) => {
    if (selectedButton === 0 || selectedButton === 2) return setTreeState([item.id]);
    let treeStateTemp = [...treeState];
    treeStateTemp = treeState.find((x) => x === item.id)
      ? treeStateTemp.filter((x) => x !== item.id)
      : [...treeStateTemp, item.id];
    handleSelectionCascade(treeStateTemp, treeState, setTreeState, list, selectedButton);
  };

  const handleImportClick = (insertInCurrentLine = true) => {
    if (selectedButton === 0) importRefStudyLine(insertInCurrentLine, treeState, handleImport, vat20);
    else if (selectedButton === 1) importRefStudyGroup(insertInCurrentLine, treeState, handleImport, vat20);
    else if (selectedButton === 2) importArticle(insertInCurrentLine, treeState, handleImport, vat20);
    else if (selectedButton === 3) importPriceSchedule(treeState, handleImport, vat20);
  };

  return (
    <CmsImportPanel {...{ open, setOpen }} title={[<ImportHelp />, <h3>Panneau d'import</h3>]} panelWidth="40rem">
      <div className="save-button">
        <CmsIcon icon="save" tooltip="Mettre à jour" onClick={handleSaveButtons} />
      </div>
      <div className="flex-center" style={{ marginBottom: '.5rem' }}>
        <InputUI.CmsButtonGroup value={selectedButton} options={categories} onClick={handleChangeCategory} />
      </div>
      <div className="flex-v">
        {selectedButton < 3 && (
          <Buttons.Default onClick={() => handleImportClick(true)}>Insérer dans la ligne sélectionnée</Buttons.Default>
        )}
        <Buttons.Default style={{ margin: '0.5rem 0' }} onClick={() => handleImportClick(false)}>
          Créer en tant que nouvelle(s) ligne(s)
        </Buttons.Default>
      </div>
      <div>
        <InputUI.DebouncedInput
          value={search}
          valueCanUpdate
          onChange={(x?: string) => handleSearch(x, selectedButton)}
          placeholder="3 lettres minimum"
        />
        <CmsTreeView
          tree={list}
          card={[RefStudyCard, RefStudyGroupCard, ArticleCard, PriceScheduleCard][selectedButton]}
          onCardClick={handleCardClick}
          stateHandler={{
            state: treeState,
            setState: (x) => handleSelectionCascade(x, treeState, setTreeState, list, selectedButton),
          }}
          multiselect={!isSimpleList}
          checkboxSelection={!isSimpleList}
        />
        {loading && <LoadingScreen />}
        {(list ?? []).length === 0 && !loading && <h2 className="flex-center">Aucun résultat</h2>}
      </div>
    </CmsImportPanel>
  );
};

const PriceScheduleDataList: LabelValueIf[] = [
  { label: 'Nom', value: (x: any) => x.label },
  { label: 'Unité', value: (x: any) => x.data?.unitLabel },
  { label: 'Prit unitaire', value: (x: any) => Utils.ThousandSpacing(x.data?.price ?? 0, 2) + ' €' },
];

const PriceScheduleLineToolTip: FC<{ line: any }> = ({ line }) => [
  <TabStyleDataView conf={PriceScheduleDataList} mapFrom={line} />,
  <div>Contient les lignes:</div>,
  (line.data as BlPriceSchedule).blPriceScheduleLineGroup?.map((x, i) => (
    <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
      <Tooltip title={<RefStudyTooltip line={x.group} />} placement="left">
        <div className="card-label">
          <CmsLink
            href={`/castres/billing/referencestudygroup/${x.groupId}/edit`}
            label={`${x.quantity} x ${x.group.label}`}
            target="_blank"
            defaultStyle
          />
        </div>
      </Tooltip>
    </div>
  )),
];

const PriceScheduleChapterToolTip: FC<{ line: any }> = ({ line }) => {
  const chapterId = line.id.toString().match(/\d+/)?.[0];
  return (
    <div>
      <CmsLink
        href={`/castres/billing/priceschedule/${line.data.groupId}/edit`}
        label={line.label}
        target="_blank"
        defaultStyle
      />
      <div>Contient les lignes :</div>
      {line.children?.map((x: any, i: number) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<PriceScheduleLineToolTip line={x} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/priceschedule/${chapterId}/edit`}
                label={x.label}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const PriceScheduleGroupDataList: LabelValueIf[] = [
  { label: 'Référence', value: (x: any) => x.ref },
  {
    label: 'Nom',
    value: (x: any) => (
      <CmsLink
        href={`/castres/billing/priceschedule/${x.id.toString().match(/\d+/)?.[0]}/edit`}
        label={x.label}
        target="_blank"
        defaultStyle
      />
    ),
  },
  { label: 'Type', value: (x: any) => x.typeLabel },
];

const PriceScheduleGroupToolTip: FC<{ line: any }> = ({ line }) => {
  const groupId = line.id.toString().match(/\d+/)?.[0];
  return (
    <div>
      <TabStyleDataView conf={PriceScheduleGroupDataList} mapFrom={line} />
      <div>Contient les chapitre:</div>
      {line.children?.map((x: any, i: number) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<PriceScheduleChapterToolTip line={x} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/priceschedule/${groupId}/edit`}
                label={x.label}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const PriceScheduleCard: FC = (line: any, onClick) => {
  if (line.data.priceScheduleGroupTypeId)
    return (
      <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
        <Tooltip title={<PriceScheduleGroupToolTip line={line} />} placement="left">
          <span className="card-label">{line.label}</span>
        </Tooltip>
      </div>
    );
  if (line.data.chapter)
    return (
      <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
        <Tooltip title={<PriceScheduleChapterToolTip line={line} />} placement="left">
          <span className="card-label">{line.label}</span>
        </Tooltip>
      </div>
    );
  return (
    <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
      <Tooltip title={<PriceScheduleLineToolTip line={line} />} placement="left">
        <span className="card-label">{line.label}</span>
      </Tooltip>
    </div>
  );
};

export const QuotationArticleDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: any) => (
      <CmsLink href={`/castres/stock/article/${x.id}/edit`} label={x.label} target="_blank" defaultStyle />
    ),
  },
  { label: 'Prix', value: (x: any) => Utils.ThousandSpacing(x.price ?? 0, 2) + ' €' },
  { label: 'Fournisseur', value: (x: any) => x.companyLabel },
];

const ArticleCard: FC = (line: any, onClick) => (
  <div className="import-card" onClick={() => onClick(line)} style={{ width: '35rem' }}>
    <Tooltip title={<TabStyleDataView conf={QuotationArticleDataList} mapFrom={line} />} placement="left">
      <div className="card-label">{line.label}</div>
    </Tooltip>
  </div>
);

export const RefStudyDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/billing/referencestudyline/${x.id.toString().match(/\d+/)?.[0]}/edit`}
        label={x.label}
        target="_blank"
        defaultStyle
      />
    ),
  },
  {
    label: 'Article',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/stock/article/${x.articleId}/edit`}
        label={x.articleLabel ?? ''}
        target="_blank"
        defaultStyle
      />
    ),
    ifIs: (x: BlReferenceStudyLine) => !!x.articleId,
  },
  {
    label: 'Fournisseur',
    value: (x: BlReferenceStudyLine) => x.article?.companyLabel,
    ifIs: (x: BlReferenceStudyLine) => !!x.article?.companyLabel,
  },
  { label: 'Type', value: (x: BlReferenceStudyLine) => BlReferenceStudyTypeOptions[x.type ?? 0]?.label },
  { label: "Catégorie d'étude", value: (x: BlReferenceStudyLine) => x.categoryLabel },
  { label: "Corps d'état", value: (x: BlReferenceStudyLine) => x.jobLabel },
  { label: 'Unité', value: (x: BlReferenceStudyLine) => x.unitLabel },
  { label: 'Quantité', value: (x: BlReferenceStudyLine) => x.quantity },
  { label: 'Perte', value: (x: BlReferenceStudyLine) => (x.lossFactor ?? 0) + '%' },
  {
    label: 'Coût unitaire',
    value: (x: BlReferenceStudyLine) => Utils.ThousandSpacing(x.calculatedUnitPrice, 2) + ' €',
  },
  { label: 'Coût de revient', value: (x: BlReferenceStudyLine) => Utils.ThousandSpacing(x.costPrice, 2) + ' €' },
  { label: 'Prix vente', value: (x: BlReferenceStudyLine) => Utils.ThousandSpacing(x.markupPrice, 2) + ' €' },
];

const RefStudyGroupDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/billing/referencestudygroup/${x.id.toString().match(/\d+/)?.[0]}/edit`}
        label={x.label}
        target="_blank"
        defaultStyle
      />
    ),
  },
  { label: 'Unité', value: (x: BlReferenceStudyGroup) => x.unitLabel },
  { label: 'C.revient', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.costPrice ?? 0, 2) + ' €' },
  { label: 'Invariable', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.flatRateCost ?? 0, 2) + ' €' },
  { label: 'Variable', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.variableRateCost ?? 0, 2) + ' €' },
  {
    label: 'Prix vente souhaité',
    value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.markupPrice ?? 0, 2) + ' €',
  },
];

export const RefStudyTooltip: FC<{ line: BlReferenceStudyGroup }> = ({ line }) => {
  if (line.id.toString().includes('line')) return <TabStyleDataView conf={RefStudyDataList} mapFrom={line} />;
  return (
    <div>
      <TabStyleDataView conf={RefStudyGroupDataList} mapFrom={line} />
      <div>Contient les lignes:</div>
      {line.blReferenceStudyGroupLine?.map((x, i) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<TabStyleDataView conf={RefStudyDataList} mapFrom={x.line} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/referencestudyline/${x.line.id}/edit`}
                label={`${x.quantity ?? 0} x ${x.line.label}`}
                target="_blank"
                defaultStyle
              />
              {x.warningList?.length > 0 && (
                <CmsIcon icon="warning" style={{ color: 'yellow', fontSize: '1rem', margin: '-0.2rem 0' }} />
              )}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const RefStudyCard: FC = (line: any, onClick) => (
  <div className="import-card" onClick={() => onClick(line)} style={{ width: '33rem' }}>
    <Tooltip title={<TabStyleDataView conf={RefStudyDataList} mapFrom={line} />} placement="left">
      <div className="card-label">{line.label}</div>
    </Tooltip>
  </div>
);

const RefStudyGroupCard: FC = (line: any, onClick) => {
  return (
    <div className="import-card" onClick={() => onClick(line)} style={{ width: '33rem' }}>
      <Tooltip title={<RefStudyTooltip line={line} />} placement="left">
        <div className="card-label flex-h align-center">
          {line.label}
          {line.childrenHaveWarning && <CmsIcon icon="warning" style={{ color: 'yellow' }} />}
        </div>
      </Tooltip>
    </div>
  );
};

const importArticle = (insertInCurrentLine: boolean, id: any, handleImport: any, vat20?: BlVat) => {
  CRUD.getById<SkArticle>(APIRoute.SkArticle, id).then((x) => {
    const studyLine: BlQuotationStudyLine | any = {
      label: x.label,
      unitPrice: +(x.price?.toFixed(2) ?? 0),
      quantity: 1,
      type: 0, // Type Article
      unitId: x.unitId,
      articleId: x.id,
      articleLabel: x.label,
      article: x,
    };
    if (insertInCurrentLine) return handleImport([studyLine], true);
    const newQuotationLine: BlQuotationLine = {
      label: x.label,
      // price: x.price, // Ne pas renseigner par défaut le prix de l'article
      discount: 0,
      unit: x.unit,
      vatId: vat20?.id,
      vatValue: vat20?.value,
      blQuotationStudyLine: [studyLine],
    };
    handleImport(newQuotationLine, false);
  });
};

const importRefStudyLine = (insertInCurrentLine: boolean, id: any, handleImport: any, vat20?: BlVat) => {
  CRUD.getById<BlReferenceStudyLine>(APIRoute.BlReferenceStudyLine, id).then((x) => {
    const studyLine: BlQuotationStudyLine | any = {
      ...x,
      id: Utils.getNewId([]),
      unitPrice: +(x.calculatedUnitPrice ?? 0).toFixed(2),
      referenceStudyLineId: x.id,
      referenceStudyLine: x,
    };
    if (insertInCurrentLine) return handleImport([studyLine], true);
    const newQuotationLine: BlQuotationLine = {
      label: x.label,
      discount: 0,
      unit: x.unitId,
      vatId: vat20?.id,
      vatValue: vat20?.value,
      blQuotationStudyLine: [studyLine],
    };
    handleImport(newQuotationLine, false);
  });
};

const importRefStudyGroup = (insertInCurrentLine: boolean, id: any[], handleImport: any, vat20?: BlVat) => {
  const groupId = id.find((x) => x.startsWith('group'))?.replace('group', '');
  if (!groupId) {
    const idList = id.filter((x) => x.startsWith('line')).map((x) => +x.replace('line', ''));
    CRUD.post<BlReferenceStudyGroupLine[]>(APIRoute.BlReferenceStudyLine + '/GetGroupLineRange', { list: idList }).then(
      (lines) => {
        if (insertInCurrentLine) return handleImport(lines.map(mapRefStudyLineFromGroup), true);
        const quotationLines = lines.map((x) => mapRfeStudyLineAsQuotationLine(x, vat20));
        handleImport(quotationLines, false);
      },
    );
    return;
  }
  CRUD.getById<BlReferenceStudyGroup>(APIRoute.BlReferenceStudyGroup, groupId).then((x) => {
    const lineIdList = id.filter((x) => x.startsWith('line')).map((x) => x.replace('line', ''));
    const lines =
      x.blReferenceStudyGroupLine?.filter((x) => lineIdList.includes(x.id.toString())).map(mapToBlQuotationStudyLine) ??
      [];
    if (insertInCurrentLine) return handleImport(lines, true);
    const newQuotationLine = {
      id: Utils.getNewId([]),
      label: x.label,
      discount: 0,
      vatId: vat20?.id,
      vatValue: vat20?.value,
      unit: x.unitId,
      blQuotationStudyLine: (lines ?? []).map((x) => ({ ...x, article: null, articleId: null })),
    };
    handleImport(newQuotationLine, false);
  });
};

function mapRfeStudyLineAsQuotationLine(x: BlReferenceStudyGroupLine, vat20?: BlVat): BlQuotationLine {
  return {
    id: Utils.getNewId([]),
    label: x.line.label,
    price: 0, //+((x.line.markupPrice ?? 0) * (x.quantity ?? 0)).toFixed(2),
    discount: 0,
    unit: x.line.unitId,
    vatId: vat20?.id,
    vatValue: vat20?.value,
    blQuotationStudyLine: [mapRefStudyLineFromGroup(x)],
  };
}

function mapRefStudyLineFromGroup(x: BlReferenceStudyGroupLine): any {
  return {
    ...x.line,
    id: Utils.getNewId([]),
    isFlatRate: x.isFlatRate,
    quantity: x.quantity * x.line.quantity,
    unitPrice: +(x.line.calculatedUnitPrice ?? 0).toFixed(2),
    referenceStudyLineId: x.lineId,
    referenceStudyLine: x.line,
  };
}

const mapToBlQuotationStudyLine = (x: BlReferenceStudyGroupLine): BlQuotationStudyLine => {
  return {
    ...x.line,
    id: Utils.getNewId([]),
    unitPrice: +(x.line.markupPrice ?? 0).toFixed(2),
    quantity: x.quantity * x.line.quantity,
    markupFactor: x.line.category?.markupFactor,
    referenceStudyLineId: x.line.id,
    isFlatRate: x.isFlatRate,
    referenceStudyLine: x.line,
  };
};

const importPriceSchedule = (id: any[], handleImport: any, vat20?: BlVat) => {
  const groupId = id.find((x) => x.startsWith('group'))?.replace('group', '');
  if (groupId) {
    CRUD.getById<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup, groupId).then((x) => {
      let lines: any[] = [{ label: x.label, chapter: true, indent: 1, id: Utils.getNewId([]) }];
      for (let line of x.blPriceSchedule ?? []) {
        if (id.includes('chapter' + line.id)) lines.push(mapPriceScheduleChapter(line));
        else if (id.includes('line' + line.id)) lines.push(mapPriceScheduleChildren(line, vat20));
      }
      handleImport(lines, false);
    });
  } else {
    const idList = id.map((x) => +x.replace(/line|chapter/, ''));
    CRUD.post<BlPriceSchedule[]>(APIRoute.BlPriceSchedule + '/GetRangeForImport', { list: idList }).then((list) => {
      let lines: any[] = [];
      for (let line of list ?? []) {
        if (id.includes('chapter' + line.id)) lines.push(mapPriceScheduleChapter(line));
        else if (id.includes('line' + line.id)) lines.push(mapPriceScheduleChildren(line, vat20));
      }
      handleImport(lines, false);
    });
  }
};

function mapPriceScheduleChapter(line: BlPriceSchedule) {
  return {
    ...line,
    id: Utils.getNewId([]),
    chapter: true,
    imagePath: undefined,
    indent: (line.chapterLevel ?? 0) + 1,
    chapterLevel: (line.chapterLevel ?? 0) + 1,
    priceScheduleId: line.id,
  };
}

function mapPriceScheduleChildren(priceSchedule: BlPriceSchedule, vat20?: BlVat): BlQuotationLine {
  return {
    id: Utils.getNewId([]),
    discount: 0,
    price: priceSchedule.price,
    unitId: priceSchedule.unitId,
    unitLabel: priceSchedule.unitLabel,
    label: priceSchedule.label,
    priceScheduleId: priceSchedule.id,
    vatId: vat20?.id,
    vatValue: vat20?.value,
    blQuotationStudyLine: priceSchedule.blPriceScheduleLineGroup
      ?.map(
        (x) =>
          x.group?.blReferenceStudyGroupLine?.map((y: BlReferenceStudyGroupLine) => ({
            ...y.line,
            isFlatRate: y.isFlatRate,
            unitPrice: +(y.line.calculatedUnitPrice ?? 0).toFixed(2),
            id: Utils.getNewId([]),
            quantity: (y.isFlatRate ? 1 : (x.quantity ?? 1)) * y.quantity * (y.line.quantity ?? 0),
            referenceStudyLineId: y.line.id,
            referenceStudyLine: y.line,
          })) ?? [],
      )
      .flat(),
  };
}

const ImportHelp: FC = () => {
  const [open, setOpen] = useState(false);
  return [
    <CmsIcon icon="help" onClick={() => setOpen(true)} />,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        title="Aide à l'import"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        style={{ marginBottom: 0, maxHeight: '80vh' }}
        scrollable
      >
        <div>
          <h3>Insertion/Création de ligne</h3>
          <p>
            Lors de l'import d'une ligne, vous avez la possibilité de l'insérer dans la ligne sélectionnée ou de la
            créer en tant que nouvelle ligne, sauf pour les bordereaux qui ne peuvent pas être insérés dans une ligne.
          </p>
          <h3>Quantité dans les bordereaux et les groupes de référence d'études</h3>
          <p>
            Lorsque vous importez un groupe de référence d'étude ou un bordereau, les lignes enfants peuvent contenir
            des quantités induites par le groupe ou le bordereau. Vous pouvez voir les quantités dans les info-bulles
            lorsque vous survolez les lignes.
          </p>
        </div>
      </CmsPaper>
    </UI.Dialog>,
  ];
};

// const MiniWarningIcon: FC = () => <CmsIcon icon="warning" style={{ color: 'yellow', fontSize: '1rem' }} />;

interface ImportCsvProps {
  id: number;
  setState: Dispatch<SetStateAction<any>>;
  isPriceSchedule?: boolean;
}

const defaultForm = { ref: 'A', label: 'C', unit: 'D', quantity: 'F', price: 'I', startLine: 9, endLine: 0 };
export const QuotationImportCsv: FC<ImportCsvProps> = ({ id, setState, isPriceSchedule }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleImport = (target: string) => {
    if (!file) return notificationService.error('Aucun fichier sélectionné');
    if (file.name.split('.').pop()?.toLowerCase() !== 'csv')
      return notificationService.error('Le fichier doit être au format CSV');
    if (!form.ref || !form.label || !form.unit || !form.quantity || !form.price)
      return notificationService.error('Veuillez remplir les champs obligatoires');
    if (
      form.ref.length > 1 ||
      form.label.length > 1 ||
      form.unit.length > 1 ||
      form.quantity.length > 1 ||
      form.price.length > 1
    )
      return notificationService.error('Les champs colonnes doivent être une seule lettre');
    if (+form.startLine > +form.endLine && form.endLine > 0)
      return notificationService.error('La ligne de début doit être inférieure à la fin');
    setLoading(true);
    const route = isPriceSchedule ? APIRoute.BlPriceScheduleGroup : APIRoute.BlQuotation;
    if (target === 'Import') {
      CRUD.postFormData<any>(route + '/Import', { ...form, id, file })
        .then((x) => {
          setState(isPriceSchedule ? x.blPriceSchedule : x.quotationLines);
          setOpen(false);
        })
        .finally(() => setLoading(false));
    } else {
      CRUD.getBlobFromFormData(route + '/Export', { ...form, id: +id, file })
        .then((x) => {
          Utils.downloadFile(x, 'export.csv');
          setOpen(false);
        })
        .finally(() => setLoading(false));
    }
  };

  return [
    <Buttons.Default onClick={() => setOpen(true)}>Importer un fichier CSV</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        isLoading={loading}
        style={{ marginBottom: 0 }}
        title="Import CSV"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
      >
        <h3>Définissez les colonnes de l'import</h3>
        <div className="flex-h" style={{ width: '36rem' }}>
          <InputUI.CMSTextField value={form.ref} onChange={(x: string) => setForm({ ...form, ref: x })} label="Réf" />
          <InputUI.CMSTextField
            value={form.label}
            onChange={(x: string) => setForm({ ...form, label: x })}
            label="Désignation"
          />
          {!isPriceSchedule && (
            <InputUI.CMSTextField
              value={form.quantity}
              onChange={(x: string) => setForm({ ...form, quantity: x })}
              label="Quantité"
            />
          )}
          <InputUI.CMSTextField
            value={form.unit}
            onChange={(x: string) => setForm({ ...form, unit: x })}
            label="Unité"
          />
          <InputUI.CMSTextField
            value={form.price}
            onChange={(x: string) => setForm({ ...form, price: x })}
            label="Coût unitaire"
          />
        </div>
        <div className="flex-h">
          <h3>Définnissez la ligne de début et fin de l'import </h3>
          <CmsIcon icon="help" tooltip="Si la valeur de fin est à 0, l'import se fera jusqu'à la fin du fichier" />
        </div>
        <div className="flex-h" style={{ width: '36rem' }}>
          <InputUI.CMSTextField
            type="number"
            value={form.startLine}
            onChange={(x: number) => setForm({ ...form, startLine: x })}
            label="Commencer à la ligne"
          />
          <InputUI.CMSTextField
            type="number"
            value={form.endLine ?? 0}
            onChange={(x: number) => setForm({ ...form, endLine: x })}
            label="Terminer à la ligne"
          />
        </div>
        <InputUI.InputFile onFileSelected={setFile} style={{ marginBottom: '0.5rem' }} />
        <Buttons.Default onClick={() => handleImport('Import')}> Importer </Buttons.Default>
        <Buttons.Default onClick={() => handleImport('Export')}> Exporter </Buttons.Default>
      </CmsPaper>
    </UI.Dialog>,
  ];
};
