import React, { FC, useMemo } from 'react';
import { CmsBackendTable, CmsColumnDef } from '../../../component/table/CmsTable';
import { Link } from '@mui/material';
import APIRoute from '../../../constant/API.constant';
import { BlQuotation } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { BASE_URL } from 'constant/API_URL';
import { UI } from '../../../component/shared';
import { CmsPaper } from '../../../component/shared/Ui';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { ClClient } from '../../../interface/ClType';
import CmsIcon from '../../../component/shared/CmsIcon';
import AccessFilter from '../../../helper/AccessFilter';
import ROLE from '../../../constant/role.constant';
import Utils from 'helper/Utils';

const ClientContactConf: any[] = [
  { label: <CmsIcon icon="home" />, value: (client: ClClient) => client.address },
  {
    label: <CmsIcon icon="phone" />,
    value: (client: ClClient) => client.tel && <Link href={'tel:' + client.tel}> {client.tel}</Link>,
  },
  {
    label: <CmsIcon icon="email" />,
    value: (client: ClClient) => client.email && <Link href={'mailto:' + client.email}> {client.email}</Link>,
  },
];

const ClientContactInfo: FC<{ info: any }> = ({ info }) => (
  <CmsPaper title="Client et contact" style={{ marginBottom: 0 }}>
    <h3>Client : {info.row.original.clientLabel}</h3>
    <TabStyleDataView conf={ClientContactConf} mapFrom={info.row.original.client} />
    <h3 style={{ marginTop: '1rem' }}>Contact : {info.row.original.contactName}</h3>
    <TabStyleDataView conf={ClientContactConf} mapFrom={info.row.original.contact} />
  </CmsPaper>
);

const columns: CmsColumnDef<BlQuotation>[] = [
  {
    header: 'Créé le',
    id: 'createdAtBy',
    size: 10,
    accessorFn: (row) => row?.createdAt,
    cell: (info) => (
      <>
        <CmsTableCell.Date {...info} />
        <div style={{ fontSize: '.8em' }}>{info.row.original?.createdByName}</div>
      </>
    ),
  },
  {
    header: 'Référence / Titre',
    filterHeader: 'N° du devis',
    id: 'ref',
    Filter: CmsTableFilter.Text,
    size: 250,
    cell: (info) => (
      <CmsTableCell.Ref cell={info}>
        <div>{info.row.original?.title}</div>
      </CmsTableCell.Ref>
    ),
    category: 'Devis',
    defaultFilter: true,
  },
  {
    header: 'Statut',
    id: 'statusId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.BlStatus, multiple: true },
    category: 'Devis',
    hide: true,
  },
  { header: 'Code implant', id: 'stationRef', Filter: CmsTableFilter.Text, category: 'Station', hide: true },
  { header: 'Nom du site', id: 'stationLabel', Filter: CmsTableFilter.Text, category: 'Station', hide: true },
  {
    header: 'Site',
    id: 'station',
    noClick: true,
    cell: (info) => (
      <Link
        key={info.row.original?.station?.id}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/client/station/${info.row.original?.station?.id}/show`}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.row.original.station?.ref}</div>
        <div>{info.row.original.station?.name}</div>
      </Link>
    ),
  },
  { header: 'Ville', id: 'stationCity', Filter: CmsTableFilter.Text, category: 'Station', hide: 'hiddenByDefault' },
  {
    header: 'Code postal',
    id: 'stationPostcode',
    Filter: CmsTableFilter.Text,
    category: 'Station',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Client / Contact',
    filterHeader: 'Client',
    id: 'clientLabel',
    Filter: CmsTableFilter.Text,
    category: 'Devis',
    noClick: true,
    cell: (info) => (
      <UI.Pop
        asClick
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        parent={
          <>
            <div style={{ fontWeight: 'bold' }}>{info.row.original.clientLabel}</div>
            <div>{info.row.original.contactName}</div>
          </>
        }
      >
        <ClientContactInfo info={info} />
      </UI.Pop>
    ),
    size: 180,
  },
  { header: 'Contact', id: 'contactName', Filter: CmsTableFilter.Text, category: 'Devis', hide: true },
  {
    header: 'Code chantier / Intervention',
    id: 'workCode',
    Filter: CmsTableFilter.Text,
    category: 'Intervention',
    hide: true,
  },
  {
    header: "Type d'intervention",
    id: 'agreementId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkAgreement },
    category: 'Intervention',
    hide: true,
  },
  {
    header: "Corps d'état",
    id: 'jobId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkJob },
    category: 'Intervention',
    hide: true,
  },
  {
    header: 'Créé par',
    id: 'createdBy',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.BlQuotation}/QuotationUsers`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    category: 'Dates',
    hide: true,
  },
  { header: 'Titre', id: 'title', Filter: CmsTableFilter.Text, category: 'Devis', hide: true },
  { header: 'Contenu', id: 'quotationLine', Filter: CmsTableFilter.Text, category: 'Devis', hide: true },
  { header: 'N° de commande', id: 'orderRef', Filter: CmsTableFilter.Text, category: 'Devis', hide: true },
  { header: 'N° de facture', id: 'invoiceRef', Filter: CmsTableFilter.Text, category: 'Devis', hide: true },
  {
    header: 'Agence',
    id: 'agencyId',
    Filter: CmsTableFilter.Select,
    category: 'Devis',
    filterOptions: { optionList: APIRoute.ClAgency, optionFrom: 'BlQuotation' },
    hide: true,
  },
  {
    header: 'Service',
    id: 'serviceId',
    Filter: CmsTableFilter.Select,
    category: 'Devis',
    filterOptions: { optionList: APIRoute.SfService, optionFrom: 'BlQuotation' },
    hide: true,
  },
  { header: 'Service', id: 'serviceLabelWithAgency', size: 80 },
  {
    header: 'Entité juridique',
    id: 'legalEntityId',
    Filter: CmsTableFilter.Select,
    category: 'Devis',
    filterOptions: { optionList: APIRoute.ClLegalEntity },
    hide: true,
  },
  {
    header: 'Groupe (Travaux)',
    id: 'groupId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkInterventionGroup },
    category: 'Intervention',
    hide: true,
  },
  {
    header: 'Créé le',
    id: 'createdAt',
    Filter: CmsTableFilter.Date,
    hide: true,
    category: 'Dates',
    defaultFilterValue: { value: Utils.Date.addDays(new Date(), -1461), type: 9 },
  },
  {
    header: 'Accepté le',
    id: 'acceptedDate',
    Filter: CmsTableFilter.Date,
    category: 'Dates',
    hide: true,
  },
  {
    header: 'Nb Chap. Lignes',
    id: 'chapterLineCount',
    cell: (info) => (
      <div>
        <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{info.row.original?.chapterCount}</span>
        <span> {info.row.original?.lineCount}</span>
      </div>
    ),
    size: 40,
  },
  {
    header: 'Prix HT',
    id: 'totalHTAndCredit',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
    category: 'Devis',
    size: 40,
  },
  {
    header: 'Statut',
    id: 'statusLabelColor',
    cell: (info) => (
      <CmsTableCell.Colored color={info.row.original.statusColor} content={info.row.original.statusLabel} />
    ),
    size: 40,
  },
  {
    header: "Provenant d'un contrat",
    id: 'isFromContract',
    Filter: CmsTableFilter.Bool,
    category: 'Intervention',
    hide: true,
  },
  {
    header: 'Lien V2',
    id: 'id',
    seeV2: true,
    noClick: true,
    hide: 'hiddenByDefault',
    cell: (info: any) => (
      <Link
        href={`${BASE_URL}castres/billing/quotationV2/${info.row.original.id}/show`}
        target="_blank"
        underline="hover"
      >
        Voir
      </Link>
    ),
  } as any,
];

export const QuotationList: FC = () => {
  const filteredCols = useMemo(() => {
    const canSeeV2 = AccessFilter([ROLE.ADMIN_STUDY_REFERENCE_EDIT]);
    return columns.filter((x: any) => !x.seeV2 || canSeeV2);
  }, []);

  return (
    <CmsBackendTable
      title="Liste des devis"
      navigateTo={(id: string | number) => `${BASE_URL}castres/billing/quotation/${id}/show`}
      actions={[
        <CmsIcon
          href="/help/Démarrage_rapide_CMSv2/#13-gestion-des-listes-des-données"
          icon="help"
          tooltip="Aide"
          target="_blank"
        />,
        // <AddNavigationButton
        //   key="nav"
        //   title="Créer un devis"
        //   href={`${BASE_URL}castres/billing/quotation/create`}
        //   access={[ROLE.SUPER_ADMIN]}
        // />,
      ]}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip="Attention, ce filtre ne s'applique que sur les champs Référence et le Code implant du Site"
        />
      }
      columns={filteredCols}
      route={APIRoute.BlQuotation}
      setFiltersInUrl
      customFilter={true}
      canExport={AccessFilter([ROLE.ADMIN_QUOTATION_EXPORT])}
    />
  );
};
