import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlQuotationRequest } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import ROLE from '../../../constant/role.constant';

export const BlQuotationRequestConfigList: CmsColumnDef<BlQuotationRequest>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Client', id: 'clientLabel', Filter: CmsTableFilter.Select },
  { header: 'Contact', id: 'contactName', Filter: CmsTableFilter.Select },
  { header: 'Site', id: 'stationRefLabel', Filter: CmsTableFilter.Select },
  { header: 'Créer par', id: 'createdByName', Filter: CmsTableFilter.Select },
  { header: 'À faire par', id: 'todoByName', Filter: CmsTableFilter.Select },
  { header: 'À faire pour le', id: 'todoAt', Filter: CmsTableFilter.Date },
  { header: 'Traité', id: 'processed', Filter: CmsTableFilter.Bool, defaultFilterValue: false },
  { header: 'Date création entreprise', id: 'createdAt', Filter: CmsTableFilter.Date },
];

export const BlQuotationRequestList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_QUOTATION_REQUEST_CREATE])
    ? [<AddNavigationButton title="Ajouter une demande de devis" to="/castres/billing/quotationrequest/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des demandes de devis"
      columns={BlQuotationRequestConfigList}
      route={APIRoute.BlQuotationRequest}
      initialSorting={{ id: 'createdAt', desc: true }}
      navigateTo={(id: number) => `/castres/billing/quotationrequest/${id}/show`}
      actions={actions}
      setFiltersInUrl
    />
  );
};
