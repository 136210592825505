import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../context/Global.context';
import {
  Cell,
  CellContext,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import CmsFilterCalculator from './helper/CmsTableFilterCalculator';
import { Table, TableFooter } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CmsTableHandler } from './helper/CmsTableFilter';
import CmsIcon from '../shared/CmsIcon';
import TableBody from '@mui/material/TableBody';
import { CmsPaper } from '../shared/Ui';
import TableHead from '@mui/material/TableHead';
import notificationService from '../../service/NotificationService';
import LoadingScreen from '../LoadingScreen';
import CRUD from '../../service/CRUD.service';
import './table.style.scss';
import { HeaderGroup } from 'react-table';
import APIRoute from 'constant/API.constant';
import { BlPriceSchedule } from '../../interface/BlType';

/**
 * Définition d'une colonne pour le DndTable
 */
export interface CmsColumnDndDef<T> {
  // Obligatoire, identifiant de la colonne
  id: string;
  // Titre de la colonne, peut être un composant React
  header?: string | ReactNode | FC;
  // Composant de cellule, doit être un composant React / string / number
  cell?: (info: CellDndContext<T>) => any;
  // Options de l'input, pour les select, texte, radio, checkbox
  inputOptions?: DndInputOptionProps;
  // Clé de l'accessor, si différent de l'id, l'accesor définit l'attribut à rechercher dans la ligne
  accessorKey?: string;
  // Fonction d'accessor, permet de récupérer une valeur spécifique dans la ligne, surcharge l'accessorKey
  accessorFn?: (row: T) => any;
  // Taille de la colonne
  size?: number;
  // Taille minimale de la colonne
  minSize?: number;
  // Taille maximale de la colonne
  maxSize?: number;
  // La cellule est vide si la ligne est un chapitre
  hideIfChapter?: boolean;
  // Fonction retounant vrai si le champ doit être en lecture seule en se basant sur les données de la ligne
  readOnlyIf?: (row: T) => boolean;
  // Champ requis ou non, voir fonction validateRequiredFields, pour exploitation de ce champ
  required?: boolean;
}

/**
 * Options pour les inputs de type Select, Radio, Checkbox
 */
export interface DndInputOptionProps {
  // Route ou liste d'options;
  data?: string | any[];
  // Selection multiple ou non, pour les select
  multiple?: boolean;
  // Active ou désactive le mode texte multi-ligne pour les champs de type Text
  multiline?: boolean;
  // Active ou désactive le mode texte multi-ligne pour les champs de type Text
  multilineToggle?: boolean;
  // Permet de récupérer dynamiquement une liste d'options basée sur la valeur d'un autre champ de la ligne.
  // 'route' spécifie l'URL ou l'API à appeler, et 'input' correspond à l'identifiant du champ dont la valeur sera utilisée pour compléter la route.
  fetchOptionlist?: { route: string | APIRoute; input: string };
}

/**
 * Contexte de la cellule pour le DndTable Surdéfinition de CellContext
 */
export interface CellDndContext<T> extends CellContext<T, any> {
  cell: DndCell<T>;
}

/**
 * Cellule pour le DndTable, surdéfinition de Cell pour l'auto-complétion
 */
interface DndCell<T> extends Cell<any, any> {
  // Clé de la cellule
  key: string;
  // Gestionnaire d'état du tableau {state: any[], setState: (x: any[]) => void}
  stateHandler: CmsTableHandler;
  // Fonction pour mettre à jour la valeur de la cellule
  setCellValue: (x: any) => void;
  // Options de l'input que l'on retrouve dans la colonne
  inputOption?: DndInputOptionProps;
  // Liste de fonction donnée au tableau DndTable qui sont accessibles par toutes les cellules
  lineSharedFunctionList?: any;
  // Liste des options pour les cellules de type select ou radio ou checkbox
  optionList?: any[];
  // Fonction retounant vrai si le champ doit être en lecture seule en se basant sur les données de la ligne
  readOnlyIf?: (row: T) => boolean;
}

interface CmsDndTableProps {
  // Titre du tableau
  title?: string | ReactNode;
  // Gestionnaire d'état du tableau, obligatoire puisque le tableau n'est pas autonome (contrairement au CmsFrontendTable)
  stateHandler: CmsTableHandler;
  // Gestion des sous lignes ouvertes ou fermées
  expandStateHandler?: { state: any; setState: any };
  // Configurations des colonnes du tableau, voir CmsColumnDndDef
  columns: CmsColumnDndDef<any>[];
  // Style de la ligne, fonction qui retourne un style pour chaque ligne
  rowStyle?: (row: Row<any>) => CSSProperties | undefined;
  // Style de la cellule, fonction qui retourne un style pour chaque cellule
  cellStyle?: (cell: Cell<any, any>) => CSSProperties | undefined;
  // Lignes de pied de tableau, composant React pour ajouter des lignes de pied de tableau telle que des sommes
  footerLines?: ReactNode;
  // List d'élément à afficher à droite du titre
  actions?: ReactNode[];
  // Liste de fonction/objet partagée entre toutes les lignes (telle que des liste pour les multi-select)
  lineSharedFunctionList?: object;
  // Fonction de réorganisation des lignes, si non défini, utilise la fonction par défaut
  handleCustomReorder?: (draggedRowIndex: number, targetRowIndex: number) => void;
  // Composant de sous ligne, affiche des informations supplémentaires pour chaque ligne
  SubRowComponent?: FC<any>;
  // Fonction à appeler lorsqu'on clique sur une ligne
  onRowClick?: (row: Row<any>) => void;
  // Désactive le drag and drop
  withoutDnd?: boolean;
  // Désactive le papier autour du tableau
  withoutPaper?: boolean;
  // Fonction à appeler lorsqu'on quitte une ligne qui avait le focus
  onRowBlur?: any;
  // Style du papier contenant le tableau
  paperStyle?: CSSProperties;
  // Contenu pouvant être ajouter avant le tableau dans le paper
  bodyHeader?: ReactNode;
  // Contenu pouvant être ajouter après le tableau dans le paper
  bodyFooter?: ReactNode;
  ReadonlyRow?: FC<{ row: Row<any>; handleRowClick: (row: Row<any>) => void; reorderRow: any }>;
}

/**
 * Valide les champs requis dans les données du formulaire.
 * @param {Array} data - Les données du formulaire, chaque élément représente une ligne.
 * @param {Array<CmsColumnDndDef>} columns - La configuration des colonnes, chaque colonne peut contenir des options comme `required`.
 * @param {number} parentIndex - Lors de la vérification de sous-lignes, ceci représente l'index de la ligne parente, afin d'améliorer le message d'erreur
 * @returns {boolean} - Retourne `true` si toutes les validations passent, sinon `false`.
 * @see CmsColumnDndDef
 */
export const validateRequiredFields = (data: any[], columns: CmsColumnDndDef<any>[], parentIndex?: number): boolean => {
  const requiredColumns = columns.filter((column) => column.required);
  if (requiredColumns.length === 0) return true;

  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const row = data[rowIndex];
    for (const column of requiredColumns) {
      const value = row[column.id];

      if (value === undefined || value === null || value === '') {
        let messageError = !parentIndex
          ? `Le champ "${column.header}" est requis mais est vide dans la ligne ${rowIndex + 1}`
          : `Le champ "${column.header}" est requis mais est vide dans la SOUS-ligne : ${parentIndex + 1} - ${rowIndex + 1}`;
        notificationService.error(messageError);
        return false;
      }
    }
  }
  return true;
};

/**
 * tableau avec drag and drop permettant de gérer du formulaire par lignes
 * @param title titre du tableau
 * @param stateHandler gestionnaire d'état du tableau
 * @param columns colonnes du tableau
 * @param rowStyle style de la ligne
 * @param cellStyle style de la cellule
 * @param footerLines lignes de pied de tableau
 * @param actions liste d'élément à afficher à droite du titre
 * @param lineSharedFunctionList liste de fonction/objet partagée entre toutes les lignes
 * @param handleCustomReorder fonction de réorganisation des lignes
 * @param SubRowComponent composant de sous ligne
 * @param expandStateHandler gestion des sous lignes ouvertes ou fermées
 * @param onRowClick fonction à appeler lorsqu'on clique sur une ligne
 * @param withoutDnd désactive le drag and drop
 * @param onRowBlur fonction à appeler lorsqu'on quitte une ligne qui avait le focus
 * @param paperStyle style du papier contenant le tableau
 * @param bodyFooter Contenu pouvant être ajouter après le tableau
 * @param bodyHeader Contenu pouvant être ajouter avant le tableau
 * @constructor
 */
export const CmsDndTable: FC<CmsDndTableProps> = ({
  title,
  stateHandler,
  columns,
  rowStyle,
  cellStyle,
  footerLines,
  actions,
  lineSharedFunctionList,
  handleCustomReorder,
  SubRowComponent,
  expandStateHandler,
  onRowClick,
  withoutDnd,
  onRowBlur,
  paperStyle,
  withoutPaper,
  bodyFooter,
  bodyHeader,
  ReadonlyRow,
}) => {
  const { theming } = useContext(GlobalContext);
  const [optionList, setOptionList] = useState<any>();
  const [expandState, setExpandState] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const realExpandState = expandStateHandler?.state ?? expandState;
  const realSetExpandState = expandStateHandler?.setState ?? setExpandState;
  const memoColumns = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        accessorKey: column.accessorKey ?? column.id,
        header: column.required && !column.header?.toString().endsWith(' *') ? `${column.header} *` : column.header,
      })),
    [columns],
  );

  const handleRowClick = (row: Row<any>) => {
    if (row.original.id === selectedRow) return;
    setSelectedRow(row.original.id);
    onRowClick?.(row);
  };

  useEffect(() => {
    const result = [];
    const staticList: any = {};
    for (const column of columns) {
      if (!column.inputOptions?.data) continue;
      if (typeof column.inputOptions.data === 'string') result.push(column.inputOptions.data);
      else if (Array.isArray(column.inputOptions.data)) staticList[column.id] = column.inputOptions.data;
    }
    CRUD.getMultiple(result).then((x) => setOptionList({ ...x, ...staticList }));
  }, [columns]);

  const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
    realSetExpandState({});
    if (handleCustomReorder) return handleCustomReorder(draggedRowIndex, targetRowIndex);
    if (!stateHandler?.state) return;
    const data = stateHandler?.state;
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
    stateHandler.setState([...data]);
  };

  const table = useReactTable({
    data: stateHandler?.state as any,
    state: { expanded: realExpandState },
    columns: memoColumns as any,
    filterFns: { fuzzy: CmsFilterCalculator.includes },
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => !!SubRowComponent,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: realSetExpandState,
  });

  if (!optionList || !stateHandler?.state) return <LoadingScreen />;
  const rows = table.getRowModel().rows;
  const rowPayload = {
    reorderRow,
    rowStyle,
    cellStyle,
    stateHandler,
    optionList,
    lineSharedFunctionList,
    onRowClick: handleRowClick,
    withoutDnd,
    onRowBlur,
    SubRowComponent,
  };
  return (
    <CmsPaper
      style={paperStyle}
      className="cms-table cms-frontend-table"
      title={title}
      actions={actions}
      disablePaper={withoutPaper}
    >
      {bodyHeader}
      <div className={'cms-dnd-table-container react-table-header-left ' + theming.get().palette.mode}>
        <Table>
          <CmsHeader {...{ table, withoutDnd, SubRowComponent }} />
          <DndProvider backend={HTML5Backend}>
            <TableBody>
              {!ReadonlyRow
                ? rows.map((row, index) => [<DraggableRow key={row.id} {...{ row, index, ...rowPayload }} />])
                : rows.map((row, index) =>
                    (row.original as any).id === selectedRow ? (
                      <DraggableRow key={row.id} {...{ row, index, ...rowPayload }} />
                    ) : (
                      <ReadonlyRow row={row} handleRowClick={handleRowClick} key={row.id} reorderRow={reorderRow} />
                    ),
                  )}
            </TableBody>
          </DndProvider>
          {footerLines && <TableFooter>{footerLines}</TableFooter>}
        </Table>
      </div>
      {bodyFooter}
    </CmsPaper>
  );
};

interface CmsHeaderProps {
  table: any;
  withoutDnd?: boolean;
  SubRowComponent?: any;
}

/**
 * En-tête du tableau DndTable
 * @param table le reactTable
 * @param withoutDnd désactive le drag and drop
 * @param SubRowComponent Composant représentant une sous ligne de la ligne actuelle
 * @constructor
 */
const CmsHeader: FC<CmsHeaderProps> = ({ table, withoutDnd, SubRowComponent }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup: HeaderGroup) => (
        <TableRow
          className="sticky-head"
          key={headerGroup.id}
          style={{ backgroundColor: theming.get().cms.main.header }}
        >
          {(!withoutDnd || !!SubRowComponent) && (
            <TableCell>
              {(!withoutDnd && (
                <CmsIcon icon="dragIndicator" tooltip="Vous pouvez réordonnancer les lignes par 'glisser déposer'" />
              )) ||
                (!!SubRowComponent && (
                  <CmsIcon icon="expand" tooltip="Vous pouvez ouvrir ou fermer les lignes pour plus d'informations" />
                ))}
            </TableCell>
          )}
          {headerGroup.headers.map(CmsHeaderCell)}
        </TableRow>
      ))}
    </TableHead>
  );
};

/**
 * Cellule de l'en-tête du tableau DndTable
 * @param header en-tête de la colonne
 * @param index index de la colonne
 */
const CmsHeaderCell: FC<any> = (header, index: number) => {
  const { theming } = useContext(GlobalContext);
  const { id, colSpan, column, isPlaceholder, getContext, getSize } = header;
  if (isPlaceholder) return <TableCell key={id} colSpan={colSpan} />;
  const render = flexRender(column.columnDef.header, getContext());
  const backgroundColor = column.columnDef.backgroundColor?.[theming.get().palette.mode === 'dark' ? 'dark' : 'light'];
  return (
    <TableCell key={id + 'index' + index} colSpan={colSpan} style={{ width: getSize(), backgroundColor }}>
      <div className="cms-header-cell"> {render} </div>
    </TableCell>
  );
};

interface DraggableRowProps {
  row: Row<any>;
  index: number;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
  stateHandler: CmsTableHandler;
  rowStyle?: any;
  cellStyle?: (cell: Cell<any, any>) => CSSProperties | undefined;
  optionList: any;
  lineSharedFunctionList?: object;
  onRowClick?: (row: Row<any>) => void;
  withoutDnd?: boolean;
  onRowBlur?: any;
  SubRowComponent?: any;
}

/**
 * Composant de ligne de tableau faite pour le "drag and drop"
 * @param row ligne du tableau
 * @param reorderRow fonction de réorganisation des lignes
 * @param index index de la ligne
 * @param rowStyle style de la ligne (fonction pour personnaliser pour chaque lignes)
 * @param cellStyle style de la cellule (fonction pour personnaliser pour chaque cellules)
 * @param stateHandler gestionnaire d'état du tableau
 * @param optionList liste des options pour les cellules
 * @param lineSharedFunctionList fonction partagée entre toutes les lignes
 * @param onRowClick fonction à appeler lorsqu'on clique sur une ligne
 * @param withoutDnd désactive le drag and drop
 * @param onRowBlur fonction à appeler lorsqu'on quitte une ligne qui avait le focus
 * @param SubRowComponent Composant représentant une sous ligne de la ligne actuelle
 */
const DraggableRow: FC<DraggableRowProps> = ({
  row,
  reorderRow,
  index,
  rowStyle,
  cellStyle,
  stateHandler,
  optionList,
  lineSharedFunctionList,
  onRowClick,
  withoutDnd,
  onRowBlur,
  SubRowComponent,
}) => {
  const { theming } = useContext(GlobalContext);
  const [fetchedOptionList, setFetchedOptionList] = useState<Record<string, any[]>>({});
  const [isCallingBackend, setIsCallingBackend] = useState<boolean>(false);
  const mainTheme = theming.get().cms.main;
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<any>) => reorderRow(draggedRow.index, row.index),
  });

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!onRowBlur) return;
    const RowId = row.id + '_' + index;
    const subRowId = `subrow-${row.original.id}`;
    const RowElement = document.getElementById(RowId);
    const subRowElement = document.getElementById(subRowId);
    if (!RowElement?.contains(event.relatedTarget) && !subRowElement?.contains(event.relatedTarget)) onRowBlur();
  };

  const fetchOptionList = (cell: any) => {
    const { input, route: baseRoute } = cell.column.columnDef.inputOptions!.fetchOptionlist;
    const value = row.original[input ?? ''];
    const route = `${baseRoute}${value}`;
    if (!value || fetchedOptionList[route] || isCallingBackend) return route;
    setIsCallingBackend(true);
    CRUD.getList(route).then((result) => {
      setFetchedOptionList((prev) => ({
        ...prev,
        [route]: result,
      }));
      setIsCallingBackend(false);
    });
    return route;
  };

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    item: () => row,
    type: 'row',
  });

  let alternStyle = { backgroundColor: index % 2 === 1 ? mainTheme.rowOdd : mainTheme.rowEven };
  if (row.original?.rowColor) alternStyle = { backgroundColor: row.original?.rowColor };
  if (rowStyle) alternStyle = { ...alternStyle, ...rowStyle(row) };
  return [
    <TableRow
      id={row.id + '_' + index}
      onClick={() => onRowClick?.(row)}
      key={row.id + '_' + index}
      ref={previewRef}
      style={{ opacity: isDragging ? 0.5 : 1, ...alternStyle }}
      onBlur={handleBlur}
    >
      {(!withoutDnd || (row.getCanExpand() && row.original.canRowExpand)) && (
        <TableCell style={{ width: '3rem' }} ref={dropRef}>
          <div className="dnd-cell-container flex-h">
            {!withoutDnd && (
              <button ref={dragRef}>
                <CmsIcon icon="dragHandle" style={{ cursor: isDragging ? 'grabbing' : 'grab' }} />
              </button>
            )}
            <span>{index + 1}</span>
            {row.getCanExpand() && row.original.canRowExpand && (
              <CmsIcon
                style={{ margin: '-0.2rem' }}
                icon={row.getIsExpanded() ? 'down' : 'right'}
                onClick={() => row.toggleExpanded()}
              />
            )}
          </div>
        </TableCell>
      )}
      {row.getVisibleCells().map((cell: any, index) => {
        const tableCellkey = cell.id + '_' + index;
        if (cell.column.columnDef.hideIfChapter && row.original.chapter)
          return <TableCell key={tableCellkey} style={cellStyle && cellStyle(cell)} />;

        const route = cell.column.columnDef.inputOptions?.fetchOptionlist ? fetchOptionList(cell) : undefined;
        cell.key = 'cell' + row.original.id + '_' + index;
        cell.stateHandler = stateHandler;
        cell.lineSharedFunctionList = lineSharedFunctionList;
        cell.setCellValue = (x: any) => handleCellDataUpdate(stateHandler, cell, x);
        const isString = typeof cell.column.columnDef.inputOptions?.data === 'string';
        cell.inputOption = cell.column.columnDef.inputOptions;
        cell.optionList = optionList[isString ? cell.column.columnDef.inputOptions.data : cell.column.columnDef.id];
        cell.readOnlyIf = cell.column.columnDef.readOnlyIf;
        if (route && fetchedOptionList[route]) cell.optionList = fetchedOptionList[route];
        return (
          <TableCell key={tableCellkey} style={cellStyle && cellStyle(cell)}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>,
    <RenderSubRowComponent {...{ row, SubRowComponent, handleBlur }} />,
  ];
};

interface SubComponentProps {
  row: Row<any>;
  SubRowComponent?: FC<any>;
  handleBlur: any;
}

/**
 * Composant de sous ligne pour le tableau DndTable
 * @param row ligne du tableau parent
 * @param SubRowComponent composant de sous ligne
 * @param handleBlur fonction à appeler lorsqu'on quitte une ligne qui avait le focus
 */
const RenderSubRowComponent: FC<SubComponentProps> = ({ row, SubRowComponent, handleBlur }) => {
  if (!SubRowComponent || !row.getIsExpanded()) return null;
  const id = `subrow-${row.original.id}`;
  return (
    <tr id={id} key={id} className={'sub-row sub-row' + row.id} onBlur={handleBlur}>
      <td colSpan={row.getVisibleCells().length + 1}>{SubRowComponent({ row })}</td>
    </tr>
  );
};

/**
 * Met à jour la valeur d'une cellule
 * @param stateHandler gestionnaire d'état du tableau
 * @param cell cellule à mettre à jour
 * @param value nouvelle valeur
 */
function handleCellDataUpdate(stateHandler: CmsTableHandler, cell: Cell<any, any>, value: any): void {
  const originalRow = stateHandler.state.find((x) => x.id === cell.row.original.id);
  if (!originalRow) notificationService.error('Erreur Dnd Table: ligne non trouvée');
  originalRow[cell.column.id] = value;
  stateHandler.setState([...stateHandler.state]);
}

function handleReorderWithChapter(drag: number, drop: number, state: any[], setState: any) {
  if (!state) return;
  const data = [...state];
  const goingUp = drag > drop;
  const dragRow = data[drag];
  if (!dragRow.chapter) {
    data.splice(drop + +goingUp, 0, ...data.splice(drag, 1));
    return setState(data);
  }
  const wholeChapterSize = getEndOfWholeChapterSize(data, drag, dragRow.chapterLevel ?? 1);
  const prevChapterIndex = getPreviousChapterLine(data, drop);
  if (!goingUp) {
    if (drop < drag + wholeChapterSize) return;
    const prevChapterSize = getChapterSize(data, prevChapterIndex);
    data.splice(prevChapterIndex + prevChapterSize - wholeChapterSize, 0, ...data.splice(drag, wholeChapterSize));
  } else data.splice(prevChapterIndex, 0, ...data.splice(drag, wholeChapterSize));
  return setState(data);
}

function getPreviousChapterLine(table: BlPriceSchedule[], startIndex: number) {
  if (table[startIndex].chapter) return startIndex;
  const result = [...table]
    .slice(0, startIndex)
    .reverse()
    .findIndex((row) => row.chapter);
  return result === -1 ? 0 : startIndex - result - 1;
}

function getChapterSize(table: BlPriceSchedule[], startIndex: number) {
  const result = [...table].slice(startIndex).findIndex((row, i) => i !== 0 && row.chapter);
  return result === -1 ? table.length - startIndex : result;
}

function getEndOfWholeChapterSize(table: BlPriceSchedule[], startIndex: number, chapterLevel: number) {
  const result = [...table]
    .slice(startIndex)
    .findIndex((row, i) => i !== 0 && row.chapter && (row.chapterLevel ?? 0) <= chapterLevel);
  return result === -1 ? table.length - startIndex : result;
}

export const DndUtils = {
  handleReorderWithChapter,
  getPreviousChapterLine,
  getChapterSize,
  getEndOfWholeChapterSize,
};
