import React, { CSSProperties, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsPaper, WarningBubble } from '../../../component/shared/Ui';
import { UseFormReturn } from 'react-hook-form';
import {
  BlFixedCostContribution,
  BlFixedCostContributionLine,
  BlQuotation,
  BlQuotationAttachment,
  BlQuotationLine,
  BlQuotationStudyLine,
  BlStudyCategory,
  BlVat,
} from '../../../interface/BlType';
import { Buttons, InputUI, UI } from '../../../component/shared';
import { CellDndContext, CmsColumnDndDef, CmsDndTable, DndUtils } from '../../../component/table/CmsDndTable';
import CmsIcon from '../../../component/shared/CmsIcon';
import notificationService from '../../../service/NotificationService';
import CRUD from '../../../service/CRUD.service';
import { CmsMenuButton } from '../../../component/shared/Menu';
import { DndCell, DndInput } from '../../../component/table/DnDTableCell';
import { QuotationImportCsv, QuotationImportPanel } from './QuotationImportPanel';
import {
  calculateQuotationOrPriceScheduleRefAndIndent,
  ChapterPicPopperIcon,
} from '../../configuration/schedule/BlPriceSchedule.all.pack';
import { SubRowChapterPic } from '../../configuration/schedule/BlPriceScheduleTools';
import { useNavigate } from 'react-router-dom';
import { useLocationToGetParams } from '../../../component/shared/UseHook';
import LoadingScreen from '../../../component/LoadingScreen';
import { ListStyleDataView } from '../../../component/shared/TabStyleDataView';
import Utils from '../../../helper/Utils';
import { authenticationService } from '../../../service/Authentication.service';
import { WkJob, WkUnit } from '../../../interface/WkType';
import Tooltip from '@mui/material/Tooltip';
import { QuotationHelpModal, SubRowRefStudy } from './QuotationStudyLine';
import './quotation.scss';
import { Row } from '@tanstack/react-table';
import { Link } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDrop } from 'react-dnd';
import WarningIcon from '@mui/icons-material/Warning';
import { EmptyQuotationModal, QuotationFormPart } from './QuotationTools';

//#region QuotationPriceScheduleConfig

const designationCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const handleIndent = (right: boolean) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    const indent = (row?.chapterLevel ?? 0) + (right ? 1 : -1);
    row.chapterLevel = indent < 0 ? 0 : indent > 5 ? 5 : indent;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  const handleLabel = (label: string) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    row.label = label;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  if (x.row.original.chapter) {
    return (
      <div key={x.cell.key} className="cms-grid" style={{ gridTemplateColumns: 'auto 6rem' }}>
        <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
          <InputUI.DebouncedInput variant="standard" value={x.getValue()} valueCanUpdate onChange={handleLabel} />
        </div>
        <div className="flex-h align-center">
          <CmsIcon icon="unIndent" style={{ margin: '-0.2rem 0' }} onClick={() => handleIndent(false)} />
          <CmsIcon icon="indent" style={{ margin: '-0.2rem 0' }} onClick={() => handleIndent(true)} />
          <ChapterPicPopperIcon data={x.row.original} route={APIRoute.BlQuotation} />
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
      <InputUI.DebouncedInput
        variant="standard"
        value={x.getValue()}
        valueCanUpdate
        onChange={handleLabel}
        multilineToggle
      />
    </div>
  );
};

const handleVat: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const handleSelect = (value: any) => {
    const list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const item = list.find((y) => y.id === x.row.original.id);
    if (!item) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    item.vatId = value;
    item.vatValue = x.cell.optionList?.find((y: BlVat) => y.id === value)?.value;
    x.cell.stateHandler.setState(list);
  };

  return (
    <InputUI.SimpleSelect
      variant="standard"
      options={x.cell.optionList ?? []}
      onChange={handleSelect}
      value={x.getValue()}
    />
  );
};

function getEndChapterIndex(table: BlQuotationLine[], startIndex: number, withChildren = false) {
  if (!withChildren) {
    const endIndex = table.slice(startIndex).findIndex((row, i) => i !== 0 && row.chapter);
    return endIndex === -1 ? table.length - startIndex : endIndex;
  }
  const currentChapterLevel = table[startIndex].chapterLevel;
  const endIndex = table
    .slice(startIndex)
    .findIndex((row, i) => i !== 0 && row.chapter && (row.chapterLevel ?? 0) <= (currentChapterLevel ?? 0));
  return endIndex === -1 ? table.length - startIndex : endIndex;
}

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    const list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleChapterDelete = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, getEndChapterIndex(list, startIndex, true));
    x.cell.stateHandler.setState(list);
  };

  // TODO erreur de ligne de fin de chapitre a corriger
  const handleChapterAddEndChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    let endIndex = getEndChapterIndex(list, startIndex);
    list.splice(endIndex, 0, {
      id: Utils.getNewId(x.cell.stateHandler.state),
      blQuotationStudyLine: [],
      vatId: x.cell.lineSharedFunctionList.vat20.id,
      vatValue: x.cell.lineSharedFunctionList.vat20.value,
    } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleAdd = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, {
      id: Utils.getNewId(x.cell.stateHandler.state),
      blQuotationStudyLine: [],
      discount: 0,
      vatId: x.cell.lineSharedFunctionList.vat20.id,
      vatValue: x.cell.lineSharedFunctionList.vat20.value,
    } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleReplace = (attr: string) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, { id: Utils.getNewId(x.cell.stateHandler.state), [attr]: true } as any);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      {(x.row.original.warningList?.length ?? 0) > 0 && (
        <CmsIcon
          icon="warning"
          tooltip={<ListStyleDataView fromList={x.row.original.warningList} title={<h3>Avertissement :</h3>} />}
          style={{ color: 'yellow', margin: '-0.2rem 0 -0.2rem -0.2rem' }}
        />
      )}
      <CmsMenuButton
        preventClose={false}
        title={<CmsIcon icon="moreVertical" />}
        overLoadButton={(props) => (
          <CmsIcon
            key={'action'}
            icon="moreVertical"
            getMouseEvent
            onClick={props.handleClick}
            style={{ margin: '-0.2rem' }}
          />
        )}
      >
        <span onClick={handleDelete}>Supprimer cette ligne</span>
        {(x.row.original.chapter && [
          <span onClick={() => x.cell.lineSharedFunctionList.setVatOpen(true)}>
            Appliquer une TVA à tout le chapitre
          </span>,
          <span onClick={() => x.cell.lineSharedFunctionList.setCategoryApplicator(1)}>
            Appliquer un Taux de marge à tout le chapitre
          </span>,
          <span onClick={handleChapterDelete}>Supprimer le chapitre et son contenu</span>,
          <span onClick={handleChapterAddEndChapter}>Ajouter une nouvelle ligne à la fin de ce chapitre</span>,
        ]) || [<span onClick={() => handleReplace('chapter')}>Transformer cette ligne en chapitre</span>]}
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>
      </CmsMenuButton>
    </div>
  );
};

const QuotationUnitPriceCell: FC<any> = (cell: CellDndContext<any>) => {
  const quantity = !cell.cell.row.original.quantity ? 1 : cell.cell.row.original.quantity;
  const calculatedUnitPrice = +((cell.cell.row.original.calculatedPrice ?? 0) / quantity).toFixed(2);
  const setPrice = () => {
    if (cell.cell.row.original.price === calculatedUnitPrice) return;
    cell.cell.row.original.price = calculatedUnitPrice;
    cell.cell.stateHandler.setState([...cell.cell.stateHandler.state]);
  };

  return (
    <Tooltip
      placement="top"
      title={
        <div className="clickable link" onClick={setPrice}>
          Prix de vente unitaire souhaité: {calculatedUnitPrice} €
        </div>
      }
    >
      <div>
        <DndInput.Price {...cell} />
      </div>
    </Tooltip>
  );
};

const configList: CmsColumnDndDef<any>[] = [
  {
    header: 'Ref',
    id: 'ref',
    size: 40,
    cell: (x) => (
      <InputUI.DebouncedInput
        variant="standard"
        key={x.cell.key}
        valueCanUpdate
        onChange={x.cell.setCellValue}
        value={x.getValue() ?? x.row.original.calculatedRef}
      />
    ),
  },
  { header: 'Bordereau/Désignation', id: 'label', size: 500, cell: designationCell },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    hideIfChapter: true,
    cell: (x) => (
      <Tooltip title={x.row.original.unitLabel}>
        <span>
          <InputUI.AutoCompletor
            style={{ width: '6rem', marginRight: '-2rem' }}
            variant="standard"
            returnObject
            options={x.cell.lineSharedFunctionList.unitList}
            value={x.getValue()}
            onChange={(y: WkUnit) => {
              let row = x.cell.stateHandler.state.find((z: any) => z.id === x.row.original.id);
              row.unitId = y?.id;
              row.unitLabel = y?.label;
              x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
            }}
          />
        </span>
      </Tooltip>
    ),
  },
  { header: 'Quantité', id: 'quantity', size: 50, cell: DndInput.Number, hideIfChapter: true },
  { header: 'Prix Unitaire', id: 'price', size: 50, cell: QuotationUnitPriceCell, hideIfChapter: true },
  { header: 'Remise (%)', id: 'discount', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    header: 'Prix total',
    id: 'amount',
    size: 50,
    cell: (x) => {
      const val: number = x.cell.row.original.chapterAmount ?? x.cell.getValue() ?? 0;
      return (
        <Tooltip placement="top" title={'Prix de vente souhaité: ' + x.row.original.calculatedPrice + ' €'}>
          <div className={'amount-cell ' + x.row.original.amountAlert}>{Utils.ThousandSpacing(val, 2)} €</div>
        </Tooltip>
      );
    },
  },
  { id: 'costPrice', header: 'Coût revient', size: 50, cell: DndCell.Price, hideIfChapter: true },
  {
    header: (
      <Tooltip title="Coefficient de vente (Prix total / Coût de revient) en %">
        <div style={{ width: '5rem' }}>Coeff. vente</div>
      </Tooltip>
    ),
    id: 'brandCoeff',
    size: 0,
    cell: (x) => (
      <div className={'quotation-brand-coeff ' + x.row.original.amountAlert}>
        <DndCell.Percent {...x} />
      </div>
    ),
    hideIfChapter: true,
  },
  {
    header: 'TVA',
    id: 'vatId',
    size: 0,
    cell: handleVat,
    inputOptions: { data: APIRoute.BlVat },
    hideIfChapter: true,
  },
  { header: 'Action', id: 'action', size: 0, cell: handleActions },
];

export function getChapterAmount(table: BlQuotationLine[], rowId: number, attr = 'amount'): number {
  let amount = 0;
  let counting = false;
  let chapterIndent = 0;
  for (let i = 0; i < table.length; i++) {
    if (table[i].id !== rowId && !counting) continue;
    else if (table[i].id === rowId) {
      chapterIndent = table[i].chapterLevel ?? 0;
      counting = true;
    } else if (!table[i].chapter) amount += +((table[i] as any)?.[attr] ?? 0);
    if (table[i].id !== rowId && table[i].chapter && (table[i].chapterLevel ?? 0) <= chapterIndent)
      return +(amount?.toFixed(2) ?? 0);
  }
  return +(amount?.toFixed(2) ?? 0);
}

//#endregion

//#region QuotationCreateEdit

function useHandleBusinessRules(): [BlQuotationLine[], Dispatch<SetStateAction<BlQuotationLine[]>>] {
  const [state, setState] = React.useState<any[]>([]);
  const handleStateUpdate: any = (table: BlQuotationLine[]) => {
    if (!table || !table.length) return setState([]);
    handlePricing(table);
    handleWarnings(table);
    setState(calculateQuotationOrPriceScheduleRefAndIndent([...table], undefined, true));
  };
  return [state, handleStateUpdate];
}

const handlePricing = (table: BlQuotationLine[]) => {
  for (let row of table.filter((x) => !x.chapter)) {
    row.amountAlert = undefined;
    row.blQuotationStudyLine = calculateQuotationStudyLinePrices(row.blQuotationStudyLine ?? []);
    row.amount = (row.price ?? 0) * (row.quantity ?? 0);
    row.amount = +(row.amount - (row.amount * (row.discount ?? 0)) / 100).toFixed(2);
    row.vatAmount = !row.vatValue ? 0 : row.amount * (row.vatValue ?? 0);
    if (!row.quantity) {
      row.costPrice = row.brandCoeff = row.calculatedPrice = 0;
      continue;
    }
    const studyList = row.blQuotationStudyLine ?? [];
    const flat = studyList.reduce((x: number, y) => x + (y.isFlatRate ? (y.calculatedMarkupPrice ?? 0) : 0), 0);
    const notFlat = studyList.reduce((x: number, y) => x + (!y.isFlatRate ? (y.calculatedMarkupPrice ?? 0) : 0), 0);
    row.calculatedPrice = +(flat + notFlat * (row.quantity ?? 1)).toFixed(2);
    const flatCostPrice = row.blQuotationStudyLine.reduce((acc: number, x: BlQuotationStudyLine) => {
      return acc + (x.isFlatRate ? (x.calculatedUnitPrice ?? 0) : 0);
    }, 0);
    const notFlatCostPrice = row.blQuotationStudyLine.reduce((acc: number, x: BlQuotationStudyLine) => {
      return acc + (!x.isFlatRate ? (x.calculatedUnitPrice ?? 0) : 0);
    }, 0);
    row.costPrice = flatCostPrice + notFlatCostPrice * (row.quantity ?? 1);
    if (row.amount === 0 || !row.costPrice) row.brandCoeff = 0;
    else row.brandCoeff = +((row.amount / (row.costPrice ?? 1) - 1) * 100).toFixed(2);
    if ((row.costPrice ?? 0) > (row.amount ?? 0)) row.amountAlert = 'danger';
    else if ((row.calculatedPrice ?? 0) > (row.amount ?? 0)) row.amountAlert = 'warning';
  }
};

const handleWarnings = (table: BlQuotationLine[]) => {
  for (let row of table.filter((x) => !x.chapter)) {
    row.warningList = [];
    if ((row.price ?? 0) < 0) row.warningList.push('Le prix est négatif');
    if ((row.quantity ?? 0) < 0) row.warningList.push('La quantité est négative');
    if ((row.discount ?? 0) < 0) row.warningList.push('La remise négative');
    if ((row.discount ?? 0) > 100) row.warningList.push('La remise supérieure à 100%');
    if ((row.brandCoeff ?? 0) < 0) row.warningList.push('Coeff de vente négatif');
    if (row.amountAlert === 'danger') row.warningList.push('Le coût de revient est supérieur au prix de vente');
    if ((row.blQuotationStudyLine?.length ?? 0) < 1) row.warningList.push("Aucune ligne d'étude rattachée");
    else if (row.blQuotationStudyLine) {
      for (let i = 0; i < row.blQuotationStudyLine.length; i++) {
        if ((row.blQuotationStudyLine[i].quantity ?? 0) < 1)
          row.warningList.push(`La quantité de la minute d'étude ${i + 1} est inférieure à 1`);
      }
    }
    row.isWarning = row.warningList.length > 0;
  }
};

const defaultApiRoutes = {
  client: APIRoute.ClClient,
  contact: APIRoute.ClContact,
  station: APIRoute.ClStation,
  intervention: APIRoute.WkIntervention,
};

interface OtherFormValues {
  depositLabel?: string;
  depositAmount?: number;
}

const saveQuotation = async (id: number, data: any, state: any[], otherFormValues: any, navigate: any) => {
  const errorLabelList = [];
  const errorPriceList = [];
  for (const line of state.filter((x) => !x.chapter)) {
    line.rowColor = undefined;
    if (line.price && line.price < 0) {
      errorPriceList.push(line.position);
      line.rowColor = 'rgba(255, 0, 0, 0.2)';
    }
    if (!line.label) {
      line.rowColor = 'rgba(255, 0, 0, 0.2)';
      errorLabelList.push(line.position);
    }
    line.blQuotationStudyLineForm = line.blQuotationStudyLine;
  }
  if (errorPriceList.length > 0 || errorLabelList.length > 0) {
    if (errorLabelList.length)
      notificationService.error('Les lignes: ' + errorLabelList.join(', ') + ' sont incomplètes (libellé manquant)');
    if (errorPriceList.length)
      notificationService.error('Les lignes: ' + errorPriceList.join(', ') + ' ont des prix négatif');
    return Promise.resolve({ ...data, quotationLines: state, ...otherFormValues });
  }
  const attachmentIdList = data.blQuotationAttachment?.map((x: any) => x.id);
  let payload = { ...data, linesStringified: state, quotationLines: null, attachmentIdList, ...otherFormValues };
  const result: BlQuotation = await CRUD.postFormData(APIRoute.BlQuotation, payload, !!id);
  notificationService.success('Devis ' + (id ? 'modifié' : 'ajouté') + ' avec succès');
  if (id) return result;
  navigate('/castres/billing/quotationV2/' + (result?.id ?? 0) + '/edit');
};

const setFormFilter = (
  station: any,
  intervention: any,
  serviceId: number,
  setRouteList: any,
  setDefaultValues: any,
) => {
  if (!station && !intervention) return setDefaultValues({ serviceId });
  if (station) {
    setRouteList({
      ...defaultApiRoutes,
      contact: APIRoute.ClContact + '/ByStation/' + station,
      client: APIRoute.ClClient + '/ByStation/' + station,
      intervention: APIRoute.WkIntervention + '/ByStation/' + station,
    });
    CRUD.getById(APIRoute.ClStation, station).then((station: any) => {
      setDefaultValues({ stationId: station.id, stationLabel: station.label, serviceId });
    });
  } else {
    CRUD.getById(APIRoute.WkIntervention, intervention).then((intervention: any) => {
      setDefaultValues({
        interventionId: intervention.id,
        interventionRef: intervention.ref + ' - ' + intervention.name,
        stationId: intervention.stationId,
        stationLabel: intervention.station.label,
        serviceId,
      });
      setRouteList({
        ...defaultApiRoutes,
        contact: APIRoute.ClContact + '/ByStation/' + intervention.stationId,
        client: APIRoute.ClClient + '/ByStation/' + intervention.stationId,
        intervention: APIRoute.WkIntervention + '/ByStation/' + intervention.stationId,
      });
    });
  }
};

function deleteEmptyQuantityLines(state: BlQuotationLine[]) {
  const lines = [...state].filter(
    (x) => x.chapter || (x.quantity !== undefined && x.quantity !== '' && x.quantity !== null),
  );
  const result = [];
  for (let i = 0; i < lines.length; i++) {
    if (!lines[i].chapter) {
      result.push({ ...lines[i], position: i + 1 });
      continue;
    }
    const lastIndex = getEndChapterIndex(lines, i, true);
    const notChapter = lines.find((x, j) => j > i && j < i + lastIndex && !x.chapter);
    if (!notChapter) i += lastIndex - 1;
    else result.push({ ...lines[i], position: i + 1 });
  }
  return result;
}

export const QuotationCreateEdit: FC<any> = ({ id }) => {
  const serviceId = useMemo(() => authenticationService.getCurrentUser().serviceId, []);
  const [state, setState] = useHandleBusinessRules();
  const [headFormState, setHeadFormState] = useState<any>({});
  const [otherFormValues, setOtherFormValues] = useState<OtherFormValues>({});
  const [defaultValues, setDefaultValues] = useState<any>();
  const [open, setOpen] = useState(false);
  const [vatOpen, setVatOpen] = useState(false);
  const [categoryApplicator, setCategoryApplicator] = useState<number>(0); // 1 Chapitre, 2 Tout le devis
  const [selectedRow, setSelectedRow] = useState<any>();
  const baseUrl = '/castres/billing/quotationV2/';
  const [routeList, setRouteList] = useState<any>(defaultApiRoutes);
  const navigate = useNavigate();
  const { station, intervention } = useLocationToGetParams();
  const [unitList, setUnitList] = useState<WkUnit[]>();
  const [categoryList, setCategoryList] = useState<BlStudyCategory[]>();
  const [jobList, setJobList] = useState<WkJob[]>();
  const [vat20, setVat20] = useState<BlVat>();
  const [loading, setLoading] = useState(false);
  const [emptyQuantityModal, setEmptyQuantityModal] = useState<'onSave' | 'button'>();

  const onHeadFormChange = (data: any) => {
    if (JSON.stringify(headFormState) === JSON.stringify(data)) return;
    setHeadFormState(data);
  };

  useEffect(() => {
    const func = (x: any) => ({ ...x, label: `${x.typeLabel} - ${x.label} - ${x.markupFactor}%` });
    CRUD.getList<WkUnit>(APIRoute.WkUnit).then(setUnitList);
    CRUD.getList<BlStudyCategory>(APIRoute.BlStudyCategory).then((result) => setCategoryList(result.map(func)));
    CRUD.getList<WkJob>(APIRoute.WkJob + '/Simplified').then(setJobList);
    CRUD.getList<BlVat>(APIRoute.BlVat).then((x) => setVat20(x.find((x) => x.value === 0.2) as BlVat));
  }, []);

  const handleSaveButtons = async () => {
    if (deleteEmptyQuantityLines(state).length !== state.length) return setEmptyQuantityModal('onSave');
    setLoading(true);
    const result = await saveQuotation(id, headFormState, state, otherFormValues, navigate);
    setLoading(false);
    return result;
  };

  const handleSaveWithEmptyLines = (action: 'delete' | 'noDelete' | 'noSave' | 'deleteNoSave') => {
    setEmptyQuantityModal(undefined);
    if (action === 'noSave') return;
    if (action === 'deleteNoSave') return setState(deleteEmptyQuantityLines(state));
    const filteredState = action === 'noDelete' ? state : deleteEmptyQuantityLines(state);
    setLoading(true);
    saveQuotation(id, headFormState, filteredState, otherFormValues, navigate)
      .then((x) => setState(x.quotationLines))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setFormFilter(station, intervention, serviceId, setRouteList, setDefaultValues);
  }, [station, intervention, serviceId]);

  const handleImport = (importItem: any, insertInCurrentLine: boolean) => {
    if (!selectedRow) return notificationService.error("Veuillez sélectionner une ligne avant d'importer");
    if (selectedRow.original.chapter && insertInCurrentLine)
      return notificationService.error("Impossible d'importer dans un chapitre");
    if (insertInCurrentLine) {
      const rowInList = state.find((x) => x.id === selectedRow.original.id);
      if (!rowInList) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
      rowInList.blQuotationStudyLine = [...(rowInList.blQuotationStudyLine ?? []), ...importItem];
      return setState([...state]);
    }
    let list = [...state];
    let index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    Array.isArray(importItem) ? list.splice(index + 1, 0, ...importItem) : list.splice(index + 1, 0, importItem);
    setState(list);
  };

  const addFirstLine = () => {
    if (state.length === 0)
      setState([{ id: Utils.getNewId(state), vatId: vat20?.id, vatValue: vat20?.value } as BlQuotationLine]);
  };

  const handleSelectedRow = (row: any) => {
    if (selectedRow?.id === row.original?.id) return;
    setSelectedRow(row);
  };

  if (((!!station || !!intervention) && !defaultValues) || !categoryList || !unitList || !jobList || !vat20)
    return <LoadingScreen />;
  return (
    <div className="quotation-page">
      {loading && <LoadingScreen fullScreen />}
      <QuotationFormPart
        {...{
          id,
          defaultValues,
          handleSave: handleSaveButtons,
          setState,
          routeList,
          setRouteList,
          baseUrl,
          setOtherFormValues,
          onHeadFormChange,
        }}
      />
      {!!id && (
        <CmsDndTable
          title="Lignes du devis"
          actions={[
            <Buttons.Default onClick={() => setEmptyQuantityModal('button')}>
              Supprimer lignes quantité vide
            </Buttons.Default>,
            <QuotationHelpModal />,
            state.length === 0 && (
              <Buttons.Valid onClick={addFirstLine}>Ajouter la première ligne du devis</Buttons.Valid>
            ),
            <AddLineButtons {...{ state, setState, selectedRow, vat20 }} />,
            <Buttons.Default onClick={() => setCategoryApplicator(2)}>Marge</Buttons.Default>,
            <QuotationImportCsv id={id ?? 0} setState={setState} />,
            <Buttons.Valid onClick={() => setOpen(!open)}>Import</Buttons.Valid>,
            <CmsIcon icon="save" onClick={handleSaveButtons} />,
          ]}
          bodyHeader={<QuotationHeader {...{ state, otherFormValues, setOtherFormValues, headFormState }} />}
          stateHandler={{ state, setState }}
          handleCustomReorder={(x, y) => DndUtils.handleReorderWithChapter(x, y, state, setState)}
          columns={configList}
          lineSharedFunctionList={{ setVatOpen, setCategoryApplicator, vat20, unitList }}
          rowStyle={(row) => rowStyle(row, selectedRow)}
          onRowClick={handleSelectedRow}
          ReadonlyRow={state.length > 100 ? ReadonlyRow : undefined}
          SubRowComponent={(props) => {
            const data = props.row.original;
            const postRoute = APIRoute.BlQuotation + '/ChapterPic';
            const studyProps = { state, setState, unitList, categoryList, jobList };
            if (!data.chapter)
              return <SubRowRefStudy key={'sub-' + data.id} {...{ parentProps: props, ...studyProps }} />;
            return <SubRowChapterPic key={'sub-' + data.id} {...{ data, state, setState, postRoute }} />;
          }}
        />
      )}
      <QuotationImportPanel {...{ open, setOpen, handleImport, vat20, handleSaveButtons }} />
      <BlVatByChapter {...{ state, setState, selectedRow, open: vatOpen, setOpen: setVatOpen }} />
      <BlStudyCategoryApplicator
        {...{ state, setState, categoryList, selectedRow, categoryApplicator, setCategoryApplicator }}
      />
      <EmptyQuotationModal {...{ open: emptyQuantityModal, onCloseWithResponse: handleSaveWithEmptyLines }} />
    </div>
  );
};

interface ReadOnlyRowProps {
  row: Row<any>;
  handleRowClick: any;
  reorderRow: any;
}

const ReadonlyRow: FC<ReadOnlyRowProps> = ({ row, handleRowClick, reorderRow }) => {
  const data = row.original as BlQuotationLine;
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<any>) => reorderRow(draggedRow.index, row.index),
  });
  if (data.chapter) {
    return (
      <TableRow
        className={'quotation-chapter level' + data.indent}
        ref={dropRef}
        key={'readonly' + data.id}
        onClick={() => handleRowClick(row)}
      >
        <TableCell className="drop">{+row.id + 1}</TableCell>
        <TableCell>{data.ref ?? data.calculatedRef}</TableCell>
        <TableCell colSpan={5} className="chapter-label">
          {data.label}
        </TableCell>
        <TableCell className="quotation-chapter-price">{data.amount} €</TableCell>
        <TableCell colSpan={4}></TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow
      className={'quotation-data level' + data.indent}
      ref={dropRef}
      key={'readonly' + data.id}
      onClick={() => handleRowClick(row)}
    >
      <TableCell className="drop">{+row.id + 1}</TableCell>
      <TableCell>{data.ref ?? data.calculatedRef}</TableCell>
      <TableCell className="chapter-label">{data.label}</TableCell>
      <TableCell className="quotation-unit">{data.unitLabel}</TableCell>
      <TableCell className="quotation-number">{data.quantity}</TableCell>
      <TableCell className="quotation-price">{data.price} €</TableCell>
      <TableCell className="quotation-percent">{data.discount} %</TableCell>
      <TableCell className="quotation-price">
        <div className={'amount-cell ' + data.amountAlert}>{data.amount} €</div>
      </TableCell>
      <TableCell className="quotation-price">{data.costPrice} €</TableCell>
      <TableCell className="quotation-percent">
        <div className={'amount-cell ' + data.amountAlert}>{data.brandCoeff} %</div>
      </TableCell>
      <TableCell className="quotation-percent">{(data.vatValue ?? 0) * 100} %</TableCell>
      <TableCell className="quotation-warning">{data.isWarning && <WarningIcon />}</TableCell>
    </TableRow>
  );
};

function rowStyle(row: Row<BlQuotationLine>, selectedRow: any): CSSProperties {
  let style: CSSProperties = {};
  if (row.original.id === selectedRow?.original?.id) style = { outline: '2px solid #88FFFF' };
  if (row.original.rowColor) style = { ...style, backgroundColor: row.original.rowColor };
  if (!row.original.chapter) return style;
  return { backgroundColor: handleChapterColor(row.original.indent ?? 0), ...style };
}

interface BlVatByChapterProps {
  state: BlQuotationLine[];
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  selectedRow: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const BlVatByChapter: FC<BlVatByChapterProps> = ({ state, setState, selectedRow, open, setOpen }) => {
  const [blVatList, setBlVatList] = useState<BlVat[]>([]);
  const [selectedVat, setSelectedVat] = useState<BlVat>();

  useEffect(() => {
    CRUD.getList<BlVat>(APIRoute.BlVat).then(setBlVatList);
  }, []);

  const handleApplyVat = () => {
    if (!selectedVat) return notificationService.error('Veuillez sélectionner une TVA');
    const list = [...state];
    const index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    const endIndex = getEndChapterIndex(list, index, true);
    for (let i = index + 1; i < endIndex + index; i++) {
      list[i].vatId = selectedVat.id;
      list[i].vatValue = selectedVat.value;
    }
    setState(list);
    setOpen(false);
  };

  return (
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper title="Appliquer une TVA par lot" style={{ marginBottom: 0 }}>
        <p>Attention, Vous allez appliquer une TVA à l'ensemble des lignes du devis contenue dans ce chapitre</p>
        <InputUI.AutoCompletor options={blVatList} value={selectedVat} onChange={setSelectedVat} returnObject />
        <Buttons.Valid onClick={handleApplyVat}>Appliquer la TVA à la sélection</Buttons.Valid>
      </CmsPaper>
    </UI.Dialog>
  );
};

interface BlStudyCategoryProps {
  state: BlQuotationLine[];
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  categoryList: BlStudyCategory[];
  selectedRow: any;
  categoryApplicator: number;
  setCategoryApplicator: Dispatch<SetStateAction<number>>;
}

const BlStudyCategoryApplicator: FC<BlStudyCategoryProps> = ({
  state,
  setState,
  selectedRow,
  categoryList,
  categoryApplicator,
  setCategoryApplicator,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [markupFactor, setMarkupFactor] = useState<number>();

  const handleApplyCategory = () => {
    if (markupFactor === undefined || markupFactor === null)
      return notificationService.error('Veuillez sélectionner un taux de marge');
    let list = [...state];
    let index = 0;
    let endIndex = list.length;
    if (categoryApplicator === 1) {
      index = list.findIndex((x) => x.id === selectedRow.original.id);
      if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
      endIndex = getEndChapterIndex(list, index, true);
    }
    let quotationLineCount = 0;
    let blQuotationStudyLineCount = 0;
    for (let i = index + 1; i < endIndex + index; i++) {
      const studyLines = list[i].blQuotationStudyLine;
      if (!studyLines) continue;
      let updateList = false;
      for (let studyLine of studyLines) {
        if (studyLine.categoryId !== selectedCategory) continue;
        studyLine.markupFactor = markupFactor;
        updateList = true;
        blQuotationStudyLineCount++;
      }
      if (updateList) {
        quotationLineCount++;
        list[i].blQuotationStudyLine = [...studyLines];
      }
    }
    if (blQuotationStudyLineCount !== 0) {
      setState(list);
      const message = `Taux de marge de ${markupFactor}% appliqué à ${blQuotationStudyLineCount} minutes d'étude dans ${quotationLineCount} lignes de devis`;
      notificationService.info(message);
    } else notificationService.error("Aucune minute d'étude ne correspond à la catégorie sélectionnée");
    setCategoryApplicator(0);
  };

  return (
    <UI.Dialog open={!!categoryApplicator} onClose={() => setCategoryApplicator(0)}>
      <CmsPaper
        title="Appliquer un taux de marge par lot"
        actions={[<CmsIcon icon={'close'} onClick={() => setCategoryApplicator(0)} />]}
        style={{ marginBottom: 0, maxWidth: '30rem' }}
      >
        {(categoryApplicator === 2 && (
          <p>Attention, vous allez appliquer un taux de marge à l'ensemble des lignes du devis</p>
        )) || (
          <p>
            Attention, vous allez appliquer un taux de marge à l'ensemble des lignes du devis contenues dans ce chapitre
          </p>
        )}
        <InputUI.AutoCompletor options={categoryList} value={selectedCategory} onChange={setSelectedCategory} />
        <InputUI.DebouncedInput type="number" value={markupFactor} onChange={setMarkupFactor} label="Taux de marge" />
        <Buttons.Valid onClick={handleApplyCategory}>Appliquer le taux de marge à la sélection</Buttons.Valid>
      </CmsPaper>
    </UI.Dialog>
  );
};

const AddLineButtons: FC<{ state: any; setState: any; selectedRow: any; vat20: BlVat }> = ({
  state,
  setState,
  selectedRow,
  vat20,
}) => {
  if (state.length === 0) return <></>;
  const handleAddLine = (up: boolean) => {
    if (!selectedRow) return notificationService.error("Veuillez sélectionner une ligne avant d'ajouter une ligne");
    const list = [...state];
    const index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    list.splice(index + (up ? 0 : 1), 0, {
      id: Utils.getNewId(state),
      vatId: vat20.id,
      discount: 0,
      vatValue: vat20.value,
    } as BlQuotationLine);
    setState(list);
  };

  return [
    <Buttons.Default style={{ marginRight: '0.5rem' }} onClick={() => handleAddLine(true)}>
      Ajouter une ligne avant
    </Buttons.Default>,
    <Buttons.Default onClick={() => handleAddLine(false)}>Ajouter une ligne après</Buttons.Default>,
  ];
};

interface QuotationHeaderProps {
  state: BlQuotationLine[];
  otherFormValues?: OtherFormValues;
  setOtherFormValues: Dispatch<SetStateAction<OtherFormValues>>;
  headFormState: BlQuotation;
}

export const QuotationHeader: FC<QuotationHeaderProps> = ({
  state,
  otherFormValues,
  setOtherFormValues,
  headFormState,
}) => {
  const [fixedCostList, setFixedCostList] = useState<BlFixedCostContributionLine[]>([]);

  useEffect(() => {
    if (!headFormState?.serviceId) return;
    CRUD.getById<BlFixedCostContribution>(
      APIRoute.BlFixedCostContribution + '/ByService',
      headFormState?.serviceId ?? 0,
    ).then((x) => {
      setFixedCostList(x.blFixedCostContributionLine ?? []);
    });
  }, [headFormState.serviceId]);

  let ht = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.amount ?? 0)), 0);
  ht -= otherFormValues?.depositAmount ?? 0;
  const taxTotal = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.vatAmount ?? 0)), 0);
  const totalPvs = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.calculatedPrice ?? 0)), 0);
  const totalCostPrice = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.costPrice ?? 0)), 0);
  const markRate = ht === 0 ? 0 : ((ht - totalCostPrice) / ht) * 100;
  const sellCoefficient = ht === 0 ? 0 : (ht / totalCostPrice - 1) * 100;
  const costTrigger = fixedCostList.find((x) => (x.caMin ?? 0) <= ht);
  let customColor: any = undefined;
  if (costTrigger && (costTrigger.fixedCostPercentage ?? 0) > sellCoefficient) customColor = '#FFFF00';
  if (sellCoefficient < 0) customColor = '#FF0000';

  return (
    <div className="flex-h-bet" style={{ marginBottom: '1rem' }}>
      <div className="flex-h align-center wrap text-no-wrap">
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          Total H.T: {Utils.ThousandSpacing(ht.toFixed(2))} €
        </WarningBubble>
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          TVA: {Utils.ThousandSpacing(taxTotal.toFixed(2))} €
        </WarningBubble>
        <WarningBubble type="info">Total TTC: {Utils.ThousandSpacing(ht + taxTotal, 2)} €</WarningBubble>
        <WarningBubble type="info">Total Prix de vente souhaité: {Utils.ThousandSpacing(totalPvs, 2)} €</WarningBubble>
        <WarningBubble type="info">Total coût de revient: {Utils.ThousandSpacing(totalCostPrice, 2)} €</WarningBubble>
        <WarningBubble type="info"> Taux de marque: {Utils.ThousandSpacing(markRate, 2)} %</WarningBubble>
        <WarningBubble customColor={customColor} type={customColor ? undefined : 'info'}>
          Taux de marge: {Utils.ThousandSpacing(sellCoefficient, 2)} %
        </WarningBubble>
      </div>
      <div className="flex-h">
        <InputUI.DebouncedInput
          className="deposit-input-label"
          style={{ marginRight: '0.5rem' }}
          label="Libellé remise exceptionnelle"
          value={otherFormValues?.depositLabel}
          valueCanUpdate
          onChange={(x: any) => setOtherFormValues({ ...otherFormValues, depositLabel: x })}
        />
        <InputUI.DebouncedInput
          type="number"
          priceInput
          label="Valeur remise exceptionnelle"
          valueCanUpdate
          value={otherFormValues?.depositAmount}
          onChange={(x: any) => setOtherFormValues({ ...otherFormValues, depositAmount: x })}
        />
      </div>
    </div>
  );
};

const handleChapterColor = (indent: number) => {
  return ['#10253f', '#17375d', '#375f91', '#548dd3', '#94b2d5', '#b8cbe3'][indent];
};

export const BlQuotationAttachmentList: FC<{ formState: UseFormReturn; isShow: boolean }> = ({ formState, isShow }) => {
  const { blQuotationAttachment } = formState.watch() as BlQuotation;

  const handleDownload = (attachment: BlQuotationAttachment) => {
    CRUD.getBlob(APIRoute.BlQuotation + '/GetAttachments', { id: attachment.id }).then((blob) => {
      Utils.downloadFile(blob, attachment.filename);
    });
  };

  const handleDelete = (id: number) => {
    const filtered = blQuotationAttachment.filter((x) => x.id !== id);
    formState.setValue('blQuotationAttachment', filtered);
  };

  return (
    <ListStyleDataView
      title="Pièces Jointes"
      style={{ overflowY: 'auto', maxHeight: '20rem' }}
      fromList={blQuotationAttachment}
      cell={(x) => (
        <div className="flex-h-bet align-center clickable">
          <Link style={{ marginLeft: '0.2rem' }} onClick={() => handleDownload(x)}>
            {x.filename}
          </Link>
          {!isShow && <CmsIcon icon="delete" onClick={() => handleDelete(x.id)} />}
        </div>
      )}
    />
  );
};

function calculateQuotationStudyLinePrices(list: BlQuotationStudyLine[]) {
  for (let line of list ?? []) {
    const markupFactorMultiplier = (line.markupFactor ?? 0) / 100 + 1;
    const lossFactorMultiplier = (line.lossFactor ?? 0) / 100 + 1;
    line.calculatedUnitPrice = (line.unitPrice ?? 0) * lossFactorMultiplier * (line.quantity ?? 0);
    line.calculatedMarkupPrice = line.calculatedUnitPrice * markupFactorMultiplier;
  }
  return list;
}

//#endregion
