import { HomePage } from './page/HomePage';
import React from 'react';
import { SfTeamCreateEdit, SfTeamList } from './page/rh/SfTeam.pack';
import { SfHabilitationTypeCreateEdit, SfHabilitationTypeList } from './page/configuration/rh/SfHabilitationType.pack';
import { ImportEquipementImportedList, ImportEquipementList } from './page/import/equipement/ImportEquipementList';
import ImportEquipement from './page/import/equipement/ImportEquipement';
import GreenfluxCdrList from './page/greenflux/CdrList';
import { CdrDetail } from './page/greenflux/CdrDetail';
import GreenfluxStationsList from './page/greenflux/StationsList';
import { StationGfDetail } from './page/greenflux/StationGFDetail';
import { WkGreenfluxStationEdit } from './page/greenflux/StationGFEdit';
import { WkGreenfluxSetting } from './page/greenflux/GreenfluxSetting';
import GreenfluxNoteDeVirementList from './page/greenflux/NoteDeVirementList';
import GreenfluxNoteDeVirementPageList from './page/greenflux/NoteDeVirementPageList';
import GreenfluxNdvListExport from './page/greenflux/NoteDeVirementListExport';
import { BlAccountCreateEdit, BlAccountList } from './page/configuration/compta/BlAccount.pack';
import { BlAccountOrderCreateEdit, BlAccountOrderList } from './page/configuration/compta/BlAccountOrder.pack';
import { ImportStationImportedList, ImportStationList } from './page/import/station/ImportStationList';
import ImportStation from './page/import/station/ImportStation';
import StationList from './page/station/StationList';
import { StationDetail } from './page/station/StationDetail';
import { MediaPage } from './page/station/Media.pack';
import { AlarmList } from './page/station/AlarmList';
import { BlPaymentModeCreateEdit, BlPaymentModeList } from './page/configuration/compta/BlPaymentMode.pack';
import { ClNetworkCreateEdit, ClNetworkList } from './page/configuration/sites/ClNetwork.pack';
import { ClCompanyCreateEdit, ClCompanyList } from './page/configuration/sites/ClCompany.pack';
import { BlVatCreateEdit, BlVatList } from './page/configuration/compta/BlVat.pack';
import ToolEventList from './page/materiel/ToolEventList';
import { SkToolMarkCreateEdit, SkToolMarkList } from './page/configuration/materiel/SkToolMark.pack';
import { SkToolModelCreateEdit, SkToolModelList } from './page/configuration/materiel/SkToolModel.pack';
import { SkToolTypeCreateEdit, SkToolTypeList } from './page/configuration/materiel/SkToolType.pack';
import { ToolCreateEdit } from './page/materiel/ToolCreateEdit';
import ToolList from './page/materiel/ToolList';
import ToolShow from './page/materiel/ToolShow';
import {
  ClLegalEntityCreateEdit,
  ClLegalEntityList,
  ClLegalEntityShow,
} from './page/configuration/compta/ClLegalEntity.pack';
import { ClAgencyCreateEdit, ClAgencyList } from './page/configuration/agency/ClAgency.pack';
import { SfServiceCreateEdit, SfServiceList } from './page/configuration/agency/SfService.pack';
import { WkDomainCreateEdit, WkDomainList } from './page/configuration/intervention/WkDomain.pack';
import { WkReportTypeCreateEdit, WkReportTypeList } from './page/configuration/interventionReport/WkReportType.pack';
import { WkReportCategoryList } from './page/configuration/interventionReport/WkReportCategory.pack';
import { WkReportItemList } from './page/configuration/interventionReport/WkReportItem.pack';
import { WkSourceList, WkSourceCreateEdit } from './page/configuration/intervention/WkSource.pack';
import { SkCompanyCreateEdit, SkCompanyList, SkCompanyShow } from './page/configuration/article/SkCompany.pack';
import { SkFamilyCreateEdit, SkFamilyList } from './page/configuration/article/SkFamily.pack';
import { SkStatusCreateEdit, SkStatusList } from './page/configuration/article/SkStatus.pack';
import { SkSupplierCreateEdit, SkSupplierList, SkSupplierShow } from './page/configuration/article/SkSupplier.pack';
import { SfWorktimeCreateEdit, SfWorktimeList } from './page/configuration/rh/SfWorktime.pack';
import { SfLicenseTypeCreateEdit, SfLicenseTypeList } from './page/configuration/rh/SfLicenseType.pack';
import { SfAbsenceTypeCreateEdit, SfAbsenceTypeList } from './page/configuration/rh/SfAbsenceType.pack';
import { OrganizationalChart, UsUsersList, UsUsersShow } from './page/user/User.pack';
import { UsUsersCreateEdit } from './page/user/UserCreateEdit';
import { UsUserMacroHandling } from './page/configuration/rh/UsUserMacroHandling';
import { UsGroupList, UsGroupsCreateEdit, UsRoleReverseListPage } from './page/user/UsGroups.pack';
import { SfAbsenceCreateEdit, SfAbsenceExport, SfAbsenceList } from './page/rh/SfAbsence.pack';
import { WkJobCreateEdit, WkJobList } from './page/configuration/intervention/WkJob.pack';
import { WkCampaignTypeList, WkCampaignTypeCreateEdit } from './page/configuration/campaign/WkCampaignType.pack';
import { WkUnitCreateEdit, WkUnitList } from './page/configuration/billing/WkUnit.pack';
import {
  SfHabilitationCellPositionCreateEdit,
  SfHabilitationCellPositionList,
} from './page/configuration/rh/SfHabilitationCellPosition.pack';
import { SfFormationListPage, SfHabilitationCreateEdit, SfHabilitationList } from './page/rh/SfHabilitation.pack';
import { BlStatusCreateEdit, BlStatusList } from './page/configuration/billing/BlStatus.pack';
import { BlStatusOrderCreateEdit, BlStatusOrderList } from './page/configuration/billing/BlStatusOrder.pack';
import { ClClientCreateEdit, ClClientList, ClClientShow } from './page/billing/client/ClClient.pack';
import { ClClientCodeCreateEdit, ClClientCodeList } from './page/configuration/compta/ClClientCode.pack';
import { ClContactCreateEdit, ClContactList, ClContactShow } from './page/billing/contact/ClContact.pack';
import { BlQuotationRequestList } from './page/billing/quotationRequest/BlQuotationRequest.pack';
import { PriceScheduleAllPack } from './page/configuration/schedule/BlPriceSchedule.all.pack';
import { BlPriceScheduleGroupList } from './page/configuration/schedule/BlPriceScheduleList.all';
import { UsNotifFamilyCreateEdit, UsNotifFamilyList } from './page/configuration/notification/UsNotifFamily.pack';
import { UsNotifConfCreateEdit, UsNotifConfList } from './page/configuration/notification/UsNotifConf.pack';
import TimeReportingAdministration from './page/timeReporting/TimeReportingAdministration';
import TimeReportingReport from './page/timeReporting/TimeReportingReportProps';
import { TimeReportingInterventionReport } from './page/timeReporting/TimeReportingInterventionReportProps';
import TimeReportAdminList from './page/timeReporting/TimeReportingAdminList';
import TimeReportingPage from './page/timeReporting/TimeReporting';
import { QuotationList } from './page/billing/quotation/QuotationList';
import { OrderList } from './page/billing/order/OrderList';
import { DeliveryList } from './page/billing/delivery/DeliveryList';
import { TaskList } from './page/activity/task/TaskList';
import { InterventionList, InterventionResultList } from './page/activity/intervention/InterventionList';
import { Logout } from './page/Logout';
import NotificationDashBoard from './page/notification/NotificationDashBoard';
import { UserDashboard } from './page/dashboard/UserDashboard';
import { MyParams } from './page/MyParams';
import Login from './page/login/Login';
import ResetPassword from './page/login/ResetPassword';
import {
  BlContractFrequencyInvoiceCreateEdit,
  BlContractFrequencyInvoiceList,
} from './page/configuration/billing/BlContractFrequencyInvoice.pack';
import {
  BlContractTypeInvoiceCreateEdit,
  BlContractTypeInvoiceList,
} from './page/configuration/billing/BlContractTypeInvoice.pack';
import { BlContractStatusCreateEdit, BlContractStatusList } from './page/configuration/billing/BlContractStatuspack';
import {
  BlContractLineInterventionStatusCreateEdit,
  BlContractLineInterventionStatusList,
} from './page/configuration/billing/BlContractLineInterventionStatus';
import {
  BlPriceScheduleTypeCreateEdit,
  BlPriceScheduleTypeList,
} from './page/configuration/schedule/BlPriceScheduleType.pack';
import {
  BlPriceScheduleGroupTypeCreateEdit,
  BlPriceScheduleGroupTypeList,
} from './page/configuration/schedule/BlPriceScheduleGroupType.pack';
import ROLE from 'constant/role.constant';
import { StationCreateEdit } from './page/station/StationCreateEdit';
import { WkConfigurationSettings } from 'page/configuration/Settings';
import { EqDomainCreateEdit, EqDomainList } from 'page/configuration/dispenserDevices/EqDomain.pack';
import { EqNatureCreateEdit, EqNatureList } from 'page/configuration/dispenserDevices/EqNature.pack';
import { EqActionCreateEdit, EqActionList } from 'page/configuration/dispenserDevices/EqAction.pack';
import { OsmMapFromList } from './component/map/OsmMap';
import { OsmMap } from './component/map/OsmMapPlanning';
import { BlStudyCategoryCreateEdit, BlStudyCategoryList } from './page/configuration/schedule/BlStudyCategory.pack';
import {
  BlReferenceStudyLineCreateEdit,
  BlReferenceStudyLineList,
} from './page/configuration/schedule/BlReferenceStudyLine.pack';
import {
  BlReferenceStudyGroupCreateEdit,
  BlReferenceStudyGroupList,
} from './page/configuration/schedule/BlReferenceStudyGroup.pack';
import { SkArticleCreateEdit, SkArticleList } from './page/billing/article/SkArticle.pack';
import { QuotationShow } from './page/billing/quotation/QuotationShow';
import { QuotationCreateEdit } from './page/billing/quotation/QuotationCreateEdit';
import { LoDepartmentCreateEdit, LoDepartmentList } from 'page/configuration/sites/LoDepartment.pack';
import { BlFixedCostContributionList, FixedCostDetail } from 'page/configuration/billing/BlFixedCostContribution.pack';
import { BlFixedCostContributionCreateEdit } from 'page/configuration/billing/BlFixedCostContributionCreateEdit';
import {
  WkEmergencyLevelCreateEdit,
  WkEmergencyLevelList,
} from 'page/configuration/intervention/WkEmergencyLevel.pack';
import { BlManpowerCreateEdit, BlManPowerList } from './page/configuration/billing/BlManPower.pack';
import { WkReportCreateEdit } from 'page/activity/report/ReportCreateEdit';
import { InterventionNoteList } from 'page/activity/intervention/InterventionNotesList';
import { WkAgreementCreateEdit, WkAgreementList } from './page/configuration/intervention/WkAgreement.pack';

interface RoutingConfigProps {
  path: string;
  Component: React.FC<any>;
  title: string;
  access?: string[];
  devOnly?: boolean;
  openRoute?: boolean;
}
export const routingConfig: RoutingConfigProps[] = [
  { path: '/login', Component: Login, title: 'LoginV2', openRoute: true },
  {
    path: 'castres/billing/contractfrequencyinvoice/list',
    Component: BlContractFrequencyInvoiceList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_LIST],
    title: 'Fréquence de facturation',
  },
  {
    path: 'castres/billing/contractfrequencyinvoice/create',
    Component: BlContractFrequencyInvoiceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_CREATE],
    title: 'Fréquence de facturation',
  },
  {
    path: 'castres/billing/contractfrequencyinvoice/:id/edit',
    Component: BlContractFrequencyInvoiceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_EDIT],
    title: 'Fréquence de facturation',
  },
  {
    path: 'castres/billing/contracttypeinvoice/list',
    Component: BlContractTypeInvoiceList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_LIST],
    title: 'Type de facturation',
  },
  {
    path: 'castres/billing/contracttypeinvoice/create',
    Component: BlContractTypeInvoiceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_CREATE],
    title: 'Type de facturation',
  },
  {
    path: 'castres/billing/contracttypeinvoice/:id/edit',
    Component: BlContractTypeInvoiceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_EDIT],
    title: 'Type de facturation',
  },
  {
    path: 'castres/billing/contractstatus/list',
    Component: BlContractStatusList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_LIST],
    title: 'Statut de contrat',
  },
  {
    path: 'castres/billing/contractstatus/create',
    Component: BlContractStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_CREATE],
    title: 'Statut de contrat',
  },
  {
    path: 'castres/billing/contractstatus/:id/edit',
    Component: BlContractStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_EDIT],
    title: 'Statut de contrat',
  },
  {
    path: 'castres/billing/contractlineinterventionstatus/list',
    Component: BlContractLineInterventionStatusList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_LIST],
    title: "Statut des factures de contrats à l'acte",
  },
  {
    path: 'castres/billing/contractlineinterventionstatus/create',
    Component: BlContractLineInterventionStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_CREATE],
    title: "Statut des factures de contrats à l'acte",
  },
  {
    path: 'castres/billing/contractlineinterventionstatus/:id/edit',
    Component: BlContractLineInterventionStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_EDIT],
    title: "Statut des factures de contrats à l'acte",
  },
  {
    path: 'castres/billing/pricescheduletype/list',
    Component: BlPriceScheduleTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_LIST],
    title: 'Type de ligne de bordereau',
  },
  {
    path: 'castres/billing/pricescheduletype/create',
    Component: BlPriceScheduleTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_CREATE],
    title: 'Type de ligne de bordereau',
  },
  {
    path: 'castres/billing/pricescheduletype/:id/edit',
    Component: BlPriceScheduleTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_EDIT],
    title: 'Type de ligne de bordereau',
  },
  {
    path: 'castres/billing/priceschedulegrouptype/list',
    Component: BlPriceScheduleGroupTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_LIST],
    title: 'Type de bordereau',
  },
  {
    path: 'castres/billing/priceschedulegrouptype/create',
    Component: BlPriceScheduleGroupTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_CREATE],
    title: 'Type de bordereau',
  },
  {
    path: 'castres/billing/priceschedulegrouptype/:id/edit',
    Component: BlPriceScheduleGroupTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_EDIT],
    title: 'Type de bordereau',
  },
  {
    path: '/castres/equipment/equipment/import/all-imported',
    Component: ImportEquipementImportedList,
    access: [ROLE.ADMIN_PARK_EQUIPMENT_MASTER],
    title: 'Import equipement Total',
  },
  {
    path: '/castres/equipment/equipment/import/:id',
    Component: ImportEquipement,
    access: [ROLE.ADMIN_PARK_EQUIPMENT_MASTER],
    title: 'Import equipement Total',
  },
  {
    path: '/castres/equipment/equipment/import',
    Component: ImportEquipementList,
    access: [ROLE.ADMIN_PARK_EQUIPMENT_MASTER],
    title: 'Import equipement Total',
  },
  {
    path: '/castres/equipment/domain/list',
    Component: EqDomainList,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_DOMAIN_LIST],
    title: 'Liste des domaines',
  },
  {
    path: '/castres/equipment/domain/:id/edit',
    Component: EqDomainCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_DOMAIN_EDIT],
    title: "Edition d'un domaine",
  },
  {
    path: '/castres/equipment/domain/create',
    Component: EqDomainCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_DOMAIN_CREATE],
    title: "Création d'un domaine",
  },
  {
    path: '/castres/equipment/nature/list',
    Component: EqNatureList,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_NATURE_LIST],
    title: 'Liste des natures',
  },
  {
    path: '/castres/equipment/nature/:id/edit',
    Component: EqNatureCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_NATURE_EDIT],
    title: "Edition d'une nature",
  },
  {
    path: '/castres/equipment/nature/create',
    Component: EqNatureCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_NATURE_CREATE],
    title: "Création d'une nature",
  },
  {
    path: '/castres/equipment/action/list',
    Component: EqActionList,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_ACTION_LIST],
    title: 'Liste des actions',
  },
  {
    path: '/castres/equipment/action/:id/edit',
    Component: EqActionCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_ACTION_EDIT],
    title: "Edition d'une action",
  },
  {
    path: '/castres/equipment/action/create',
    Component: EqActionCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EQ_ACTION_CREATE],
    title: "Création d'une action",
  },
  {
    path: '/castres/greenflux/cdr/list',
    Component: GreenfluxCdrList,
    access: [ROLE.ADMIN_GF_CDR_LIST],
    title: 'Liste des CDR de Greenflux',
  },
  {
    path: '/castres/greenflux/cdr/:id/show',
    Component: CdrDetail,
    access: [ROLE.ADMIN_GF_CDR_VIEW],
    title: "Détails d'un CDR",
  },
  {
    path: '/castres/greenflux/stations/list',
    Component: GreenfluxStationsList,
    access: [ROLE.ADMIN_GF_STATION_LIST],
    title: 'Liste des stations de Greenflux',
  },
  {
    path: '/castres/greenflux/stations/:id/show',
    Component: StationGfDetail,
    access: [ROLE.ADMIN_GF_STATION_VIEW],
    title: "Détails d'une station de Greenflux",
  },
  {
    path: '/castres/greenflux/stations/:id/edit',
    Component: WkGreenfluxStationEdit,
    access: [ROLE.ADMIN_GF_STATION_EDIT],
    title: 'Éditer une station Greenflux',
  },
  {
    path: '/castres/greenflux/setting',
    Component: WkGreenfluxSetting,
    access: [ROLE.ADMIN_GF_SETTING_VIEW],
    title: "Permet d'éditer la configuration Greenflux",
  },
  {
    path: '/castres/greenflux/NoteDeVirement/list',
    Component: GreenfluxNoteDeVirementList,
    access: [ROLE.ADMIN_GF_NOTEDEVIREMENT_LIST],
    title: 'Liste des notes de virement',
  },
  {
    path: '/castres/greenflux/NoteDeVirement/:id/:method/show',
    Component: GreenfluxNoteDeVirementPageList,
    access: [ROLE.ADMIN_GF_NOTEDEVIREMENT_VIEW],
    title: 'Note de virement',
  },
  {
    path: '/castres/greenflux/NoteDeVirement/:id/export',
    Component: GreenfluxNdvListExport,
    access: [ROLE.ADMIN_GF_NOTEDEVIREMENT_EXPORT],
    title: 'Export des notes de virement',
  },
  {
    path: '/castres/billing/account/list',
    Component: BlAccountList,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNT_LIST],
    title: 'Liste des comptes imputations de vente',
  },
  {
    path: '/castres/billing/account/create',
    Component: BlAccountCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNT_CREATE],
    title: "Création d'un compte imputation de vente",
  },
  {
    path: '/castres/billing/account/:id/edit',
    Component: BlAccountCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNT_EDIT],
    title: "Édition d'un compte imputation de vente",
  },
  {
    path: '/castres/billing/accountorder/list',
    Component: BlAccountOrderList,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNTORDER_LIST],
    title: "Liste des comptes imputations d'achat",
  },
  {
    path: '/castres/billing/accountorder/create',
    Component: BlAccountOrderCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNTORDER_CREATE],
    title: "Création d'un compte imputation d'achat",
  },
  {
    path: '/castres/billing/accountorder/:id/edit',
    Component: BlAccountOrderCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ACCOUNTORDER_EDIT],
    title: "Édition d'un compte imputation d'achat",
  },
  {
    path: '/castres/client/station/import/all-imported',
    Component: ImportStationImportedList,
    access: [ROLE.ADMIN_PARK_STATION_MASTER],
    title: 'Import station Total',
  },
  {
    path: '/castres/client/station/import/:id',
    Component: ImportStation,
    access: [ROLE.ADMIN_PARK_STATION_MASTER],
    title: 'Import station Total',
  },
  {
    path: '/castres/client/station/import',
    Component: ImportStationList,
    access: [ROLE.ADMIN_PARK_STATION_MASTER],
    title: 'Import station Total',
  },
  {
    path: '/castres/client/station/list',
    Component: StationList,
    access: [ROLE.ADMIN_PARK_STATION_LIST],
    title: 'Liste des sites',
  },
  {
    path: '/castres/client/station/:id/show',
    Component: StationDetail,
    access: [ROLE.ADMIN_PARK_STATION_LIST],
    title: "Détails d'un site",
  },
  {
    path: '/castres/client/station/create',
    Component: StationCreateEdit,
    access: [ROLE.ADMIN_PARK_STATION_CREATE],
    title: "Création d'un site",
  },
  {
    path: '/castres/client/station/:id/edit',
    Component: StationCreateEdit,
    access: [ROLE.ADMIN_PARK_STATION_EDIT],
    title: "Édition d'un site",
  },
  {
    path: '/castres/client/station/:id/media',
    Component: MediaPage,
    access: [ROLE.ADMIN_PARK_STATION_LIST],
    title: 'Galerie',
  },
  {
    path: '/castres/work/alarmalx/list',
    Component: AlarmList,
    access: [ROLE.ADMIN_ALARMALX_LIST],
    title: 'Liste des alarmes',
  },
  {
    path: '/castres/billing/paymentmode/list',
    Component: BlPaymentModeList,
    access: [ROLE.ADMIN_CONFIGURATION_PAYMENTMODE_LIST],
    title: 'Liste des modes de paiement',
  },
  {
    path: '/castres/billing/paymentmode/create',
    Component: BlPaymentModeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_PAYMENTMODE_CREATE],
    title: "Création d'un mode de paiement",
  },
  {
    path: '/castres/billing/paymentmode/:id/edit',
    Component: BlPaymentModeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_PAYMENTMODE_EDIT],
    title: "Édition d'un mode de paiement",
  },
  {
    path: '/castres/client/network/list',
    Component: ClNetworkList,
    access: [ROLE.ADMIN_CONFIGURATION_NETWORK_LIST],
    title: 'Liste des réseaux',
  },
  {
    path: '/castres/client/network/create',
    Component: ClNetworkCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_NETWORK_CREATE],
    title: "Création d'un réseau",
  },
  {
    path: '/castres/client/network/:id/edit',
    Component: ClNetworkCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_NETWORK_EDIT],
    title: "Édition d'un réseau",
  },
  {
    path: '/castres/location/department/list',
    Component: LoDepartmentList,
    access: [ROLE.ADMIN_PARK_STATION_LIST],
    title: 'Liste des départements',
  },
  {
    path: '/castres/location/department/:id/edit',
    Component: LoDepartmentCreateEdit,
    access: [ROLE.ADMIN_PARK_STATION_EDIT],
    title: "Édition d'un département",
  },
  {
    path: '/castres/client/company/list',
    Component: ClCompanyList,
    access: [ROLE.ADMIN_CONFIGURATION_COMPANY_LIST],
    title: 'Liste des enseignes',
  },
  {
    path: '/castres/client/company/create',
    Component: ClCompanyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_COMPANY_CREATE],
    title: 'Créer une enseigne',
  },
  {
    path: '/castres/client/company/:id/edit',
    Component: ClCompanyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_COMPANY_EDIT],
    title: 'Modifier une enseigne',
  },
  {
    path: '/castres/billing/vat/list',
    Component: BlVatList,
    access: [ROLE.ADMIN_CONFIGURATION_VAT_LIST],
    title: 'Liste des TVA<',
  },
  {
    path: '/castres/billing/vat/create',
    Component: BlVatCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_VAT_CREATE],
    title: "Création d'une TVA<",
  },
  {
    path: '/castres/billing/vat/:id/edit',
    Component: BlVatCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_VAT_EDIT],
    title: "Édition d'une TVA<",
  },
  {
    path: '/castres/stock/toolevent/list',
    Component: ToolEventList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_LIST],
    title: "Liste des évènements d'outils",
  },
  {
    path: '/castres/stock/toolmark/list',
    Component: SkToolMarkList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMARK_LIST],
    title: "Liste des marques d'outil",
  },
  {
    path: '/castres/stock/toolmark/create',
    Component: SkToolMarkCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMARK_CREATE],
    title: "Création d'outil",
  },
  {
    path: '/castres/stock/toolmark/:id/edit',
    Component: SkToolMarkCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMARK_EDIT],
    title: "Édition d'une marque d'outil",
  },
  {
    path: '/castres/stock/toolmodel/list',
    Component: SkToolModelList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMODEL_LIST],
    title: "Liste des modèles d'outils",
  },
  {
    path: '/castres/stock/toolmodel/create',
    Component: SkToolModelCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMODEL_CREATE],
    title: "Création d'une modèle d'outil",
  },
  {
    path: '/castres/stock/toolmodel/:id/edit',
    Component: SkToolModelCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMODEL_EDIT],
    title: "Édition d'un modèle d'outil",
  },
  {
    path: '/castres/stock/tooltype/list',
    Component: SkToolTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLTYPE_LIST],
    title: "Liste des types d'outils",
  },
  {
    path: '/castres/stock/tooltype/create',
    Component: SkToolTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLTYPE_CREATE],
    title: "Création d'un type d'outil",
  },
  {
    path: '/castres/stock/tooltype/:id/edit',
    Component: SkToolTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_TOOLTYPE_EDIT],
    title: "Édition d'un type d'outil",
  },
  {
    path: '/castres/stock/tool/create',
    Component: ToolCreateEdit,
    access: [ROLE.ADMIN_STOCK_TOOL_CREATE],
    title: "Création d'outil",
  },
  {
    path: '/castres/stock/tool/list',
    Component: ToolList,
    access: [ROLE.ADMIN_STOCK_TOOL_LIST],
    title: "Liste d'outils",
  },
  {
    path: '/castres/stock/tool/:id/edit',
    Component: ToolCreateEdit,
    access: [ROLE.ADMIN_STOCK_TOOL_EDIT],
    title: "Édition d'outil",
  },
  {
    path: '/castres/stock/tool/:id/show',
    Component: ToolShow,
    access: [ROLE.ADMIN_STOCK_TOOL_VIEW],
    title: 'Outil',
  },
  {
    path: '/castres/client/legalentity/list',
    Component: ClLegalEntityList,
    access: [ROLE.ADMIN_CONFIGURATION_LEGALENTITY_LIST],
    title: 'Liste des entités juridiques',
  },
  {
    path: '/castres/client/legalentity/:id/show',
    Component: ClLegalEntityShow,
    access: [ROLE.ADMIN_CONFIGURATION_LEGALENTITY_VIEW],
    title: 'Voir une entité juridique',
  },
  {
    path: '/castres/client/legalentity/create',
    Component: ClLegalEntityCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_LEGALENTITY_CREATE],
    title: 'Créer une entité juridique',
  },
  {
    path: '/castres/client/legalentity/:id/edit',
    Component: ClLegalEntityCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_LEGALENTITY_EDIT],
    title: 'Éditer une entité juridique',
  },
  {
    path: '/castres/client/agency/list',
    Component: ClAgencyList,
    access: [ROLE.ADMIN_CONFIGURATION_AGENCY_LIST],
    title: 'Liste des agences',
  },
  {
    path: '/castres/client/agency/create',
    Component: ClAgencyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_AGENCY_CREATE],
    title: "Création d'agence",
  },
  {
    path: '/castres/client/agency/:id/edit',
    Component: ClAgencyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_AGENCY_EDIT],
    title: "Édition d'agence",
  },
  {
    path: '/castres/staff/service/list',
    Component: SfServiceList,
    access: [ROLE.ADMIN_STAFF_SERVICE_LIST],
    title: 'Liste des services',
  },
  {
    path: '/castres/staff/service/create',
    Component: SfServiceCreateEdit,
    access: [ROLE.ADMIN_STAFF_SERVICE_CREATE],
    title: "Création d'un service",
  },
  {
    path: '/castres/staff/service/:id/edit',
    Component: SfServiceCreateEdit,
    access: [ROLE.ADMIN_STAFF_SERVICE_EDIT],
    title: "Édition d'un service",
  },
  {
    path: '/castres/work/campaigntype/list',
    Component: WkCampaignTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_CAMPAIGNTYPE_LIST],
    title: 'Liste des types de campagne',
  },
  {
    path: '/castres/work/campaigntype/create',
    Component: WkCampaignTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_CAMPAIGNTYPE_CREATE],
    title: 'Création de type de campagne',
  },
  {
    path: '/castres/work/campaigntype/:id/edit',
    Component: WkCampaignTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_CAMPAIGNTYPE_EDIT],
    title: 'Édition de type de campagne',
  },
  {
    path: 'castres/work/agreement/list',
    Component: WkAgreementList,
    access: [ROLE.ADMIN_CONFIGURATION_AGREEMENT_LIST],
    title: 'Liste des type de contrat',
  },
  {
    path: 'castres/work/agreement/create',
    Component: WkAgreementCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_AGREEMENT_CREATE],
    title: 'Créer un type de contrat',
  },
  {
    path: 'castres/work/agreement/:id/edit',
    Component: WkAgreementCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_AGREEMENT_EDIT],
    title: 'Metre à jour un type de contrat',
  },
  {
    path: '/castres/work/domain/list',
    Component: WkDomainList,
    access: [ROLE.ADMIN_CONFIGURATION_DOMAIN_LIST],
    title: 'Liste de domaines',
  },
  {
    path: '/castres/work/domain/create',
    Component: WkDomainCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_DOMAIN_CREATE],
    title: "Création d'un domaine",
  },
  {
    path: '/castres/work/domain/:id/edit',
    Component: WkDomainCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_DOMAIN_EDIT],
    title: "Édition d'un domaine",
  },
  {
    path: '/castres/work/emergencylevel/list',
    Component: WkEmergencyLevelList,
    access: [ROLE.ADMIN_CONFIGURATION_EMERGENCYLEVEL_LIST],
    title: "Liste de niveaux d'urgence",
  },
  {
    path: '/castres/work/emergencylevel/create',
    Component: WkEmergencyLevelCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EMERGENCYLEVEL_CREATE],
    title: "Création d'un niveau d'urgence",
  },
  {
    path: '/castres/work/emergencylevel/:id/edit',
    Component: WkEmergencyLevelCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_EMERGENCYLEVEL_EDIT],
    title: "Édition d'un niveau d'urgence",
  },
  {
    path: '/castres/billing/fixedcost/list',
    Component: BlFixedCostContributionList,
    access: [ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_LIST],
    title: 'Liste des frais fixes',
  },
  {
    path: '/castres/billing/fixedcost/create',
    Component: BlFixedCostContributionCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_CREATE],
    title: "Création d'un frais fixe",
  },
  {
    path: '/castres/billing/fixedcost/:id/edit',
    Component: BlFixedCostContributionCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_EDIT],
    title: "Édition d'un frais fixe",
  },
  {
    path: '/castres/work/task/:id/report/edit',
    Component: WkReportCreateEdit,
    access: [ROLE.ADMIN_WORK_REPORT_EDIT],
    title: "Édition d'un rapport",
  },
  {
    path: '/castres/billing/fixedcost/:id/show',
    Component: FixedCostDetail,
    access: [ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_VIEW],
    title: 'Un frais fixe',
  },
  {
    path: '/castres/work/reporttype/list',
    Component: WkReportTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_REPORTTYPE_LIST],
    title: 'Liste des types de rapport',
  },
  {
    path: '/castres/work/reporttype/create',
    Component: WkReportTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_REPORTTYPE_CREATE],
    title: "Création d'un type de rapport",
  },
  {
    path: '/castres/work/reporttype/:id/edit',
    Component: WkReportTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_REPORTTYPE_EDIT],
    title: "Édition d'un type de rapport",
  },
  {
    path: '/castres/work/reportcategory/list',
    Component: WkReportCategoryList,
    access: [ROLE.ADMIN_CONFIGURATION_REPORTCATEGORY_LIST],
    title: 'Liste categories de constat',
  },
  {
    path: '/castres/work/reportitem/list',
    Component: WkReportItemList,
    access: [ROLE.ADMIN_CONFIGURATION_REPORTITEM_LIST],
    title: 'Liste lignes de constat',
  },
  {
    path: '/castres/work/source/list',
    Component: WkSourceList,
    access: [ROLE.ADMIN_CONFIGURATION_SOURCE_LIST],
    title: 'Liste des sources',
  },
  {
    path: '/castres/work/source/create',
    Component: WkSourceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_SOURCE_CREATE],
    title: 'Création de source',
  },
  {
    path: '/castres/work/source/:id/edit',
    Component: WkSourceCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_SOURCE_EDIT],
    title: 'Édition de source',
  },
  {
    path: '/castres/stock/company/list',
    Component: SkCompanyList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_LIST],
    title: 'Liste des fournisseurs',
  },
  {
    path: '/castres/stock/company/:id/show',
    Component: SkCompanyShow,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_VIEW],
    title: 'Un fournisseur',
  },
  {
    path: '/castres/stock/company/create',
    Component: SkCompanyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_CREATE],
    title: "Création d'un fournisseur",
  },
  {
    path: '/castres/stock/company/:id/edit',
    Component: SkCompanyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT],
    title: "Édition d'un fournisseur",
  },
  {
    path: '/castres/stock/family/list',
    Component: SkFamilyList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_FAMILY_LIST],
    title: "Liste de familles d'articles",
  },
  {
    path: '/castres/stock/family/create',
    Component: SkFamilyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_FAMILY_CREATE],
    title: "Création d'une famille d'articles",
  },
  {
    path: '/castres/stock/family/:id/edit',
    Component: SkFamilyCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_FAMILY_EDIT],
    title: "Édition d'une famille d'articles",
  },
  {
    path: '/castres/stock/article/list',
    Component: SkArticleList,
    access: [ROLE.ADMIN_STOCK_ARTICLE_LIST],
    title: 'Liste des articles fournisseurs',
  },
  {
    path: '/castres/stock/article/create',
    Component: SkArticleCreateEdit,
    access: [ROLE.ADMIN_STOCK_ARTICLE_CREATE],
    title: 'Créer un article',
  },
  {
    path: '/castres/stock/article/:id/edit',
    Component: SkArticleCreateEdit,
    access: [ROLE.ADMIN_STOCK_ARTICLE_EDIT],
    title: 'Éditer un article',
  },
  {
    path: '/castres/stock/status/list',
    Component: SkStatusList,
    access: [ROLE.ADMIN_CONFIGURATION_STATUS_LIST],
    title: "Liste des états d'articles",
  },
  {
    path: '/castres/stock/status/create',
    Component: SkStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STATUS_CREATE],
    title: "Création d'un état d'articles",
  },
  {
    path: '/castres/stock/status/:id/edit',
    Component: SkStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STATUS_EDIT],
    title: "Édition d'un état d'articles",
  },
  {
    path: '/castres/stock/supplier/list',
    Component: SkSupplierList,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_LIST],
    title: 'Liste des magasins',
  },
  {
    path: '/castres/stock/supplier/:id/show',
    Component: SkSupplierShow,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_VIEW],
    title: 'Un magasin',
  },
  {
    path: '/castres/stock/supplier/create',
    Component: SkSupplierCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_CREATE],
    title: "Création d'un magasin",
  },
  {
    path: '/castres/stock/supplier/:id/edit',
    Component: SkSupplierCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_EDIT],
    title: "Édition d'un magasin",
  },
  {
    path: '/castres/staff/worktime/list',
    Component: SfWorktimeList,
    access: [ROLE.ADMIN_CONFIGURATION_WORKTIME_LIST],
    title: 'liste des temps de travail',
  },
  {
    path: '/castres/staff/worktime/create',
    Component: SfWorktimeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_WORKTIME_CREATE],
    title: 'Ajouter un temps de travail',
  },
  {
    path: '/castres/staff/worktime/:id/edit',
    Component: SfWorktimeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_WORKTIME_EDIT],
    title: 'Éditer un temps de travail',
  },
  {
    path: '/castres/staff/licensetype/list',
    Component: SfLicenseTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_LICENSETYPE_LIST],
    title: 'Types de permis',
  },
  {
    path: '/castres/staff/licensetype/create',
    Component: SfLicenseTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_LICENSETYPE_CREATE],
    title: 'Ajouter un type de permis',
  },
  {
    path: '/castres/staff/licensetype/:id/edit',
    Component: SfLicenseTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_LICENSETYPE_EDIT],
    title: 'Éditer un type de permis',
  },
  {
    path: '/castres/staff/absencetype/list',
    Component: SfAbsenceTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_ABSENCETYPE_LIST],
    title: "Types d'absence",
  },
  {
    path: '/castres/staff/absencetype/create',
    Component: SfAbsenceTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ABSENCETYPE_CREATE],
    title: "Ajouter un type d'absence",
  },
  {
    path: '/castres/staff/absencetype/:id/edit',
    Component: SfAbsenceTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_ABSENCETYPE_EDIT],
    title: "Éditer un type d'absence",
  },
  {
    path: '/sonata/user/user/list',
    Component: UsUsersList,
    access: [ROLE.SONATA_USER_ADMIN_USER_LIST, ROLE.USER_IS_MANAGER],
    title: 'Liste des utilisateurs',
  },
  {
    path: '/sonata/user/user/:id/show/',
    Component: UsUsersShow,
    access: [ROLE.SONATA_USER_ADMIN_USER_VIEW, ROLE.USER_IS_MANAGER],
    title: 'Voir un utilisateur',
  },
  {
    path: '/sonata/user/user/create',
    Component: UsUsersCreateEdit,
    access: [ROLE.SONATA_USER_ADMIN_USER_CREATE],
    title: 'Ajouter un utilisateur',
  },
  {
    path: '/sonata/user/user/:id/edit',
    Component: UsUsersCreateEdit,
    access: [ROLE.SONATA_USER_ADMIN_USER_EDIT],
    title: 'Éditer un utilisateur',
  },
  {
    path: '/sonata/user/user/macro',
    Component: UsUserMacroHandling,
    access: [ROLE.SONATA_USER_ADMIN_USER_MASTER],
    title: 'Mise à jour globale CP et RTT ',
  },
  {
    path: '/castres/user/organizationalChart',
    Component: OrganizationalChart,
    access: [ROLE.SONATA_USER_ADMIN_USER_EDIT],
    title: 'Organigramme',
  },
  {
    path: '/sonata/user/group/list',
    Component: UsGroupList,
    access: [ROLE.SONATA_USER_ADMIN_GROUP_LIST],
    title: 'Liste des groupes',
  },
  {
    path: '/sonata/user/group/create',
    Component: UsGroupsCreateEdit,
    access: [ROLE.SONATA_USER_ADMIN_GROUP_CREATE],
    title: 'Ajouter un groupe',
  },
  {
    path: '/sonata/user/group/:id/edit',
    Component: UsGroupsCreateEdit,
    access: [ROLE.SONATA_USER_ADMIN_GROUP_EDIT],
    title: 'Éditer un groupe',
  },
  {
    path: '/sonata/user/roles',
    Component: UsRoleReverseListPage,
    access: [ROLE.SONATA_USER_ADMIN_GROUP_MASTER],
    title: 'Liste des rôles',
  },
  {
    path: '/castres/staff/absence/list',
    Component: SfAbsenceList,
    access: [ROLE.ADMIN_STAFF_ABSENCE_VIEW],
    title: 'Liste des absences',
  },
  {
    path: '/castres/staff/absence/create',
    Component: SfAbsenceCreateEdit,
    access: [ROLE.ADMIN_STAFF_ABSENCE_CREATE],
    title: "Création d'une absence",
  },
  {
    path: '/castres/staff/absence/:id/edit',
    Component: SfAbsenceCreateEdit,
    access: [ROLE.ADMIN_STAFF_ABSENCE_CREATE],
    title: "Édition d'une absence",
  },
  {
    devOnly: true,
    path: '/castres/staff/absence/export',
    Component: SfAbsenceExport,
    access: [ROLE.ADMIN_STAFF_ABSENCE_EXPORT],
    title: 'Export des absences',
  },
  {
    path: '/castres/work/job/list',
    Component: WkJobList,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_LIST],
    title: "Liste des corps d'états",
  },
  {
    path: '/castres/work/job/create',
    Component: WkJobCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_CREATE],
    title: "Création d'un corps d'état",
  },
  {
    path: '/castres/work/job/:id/edit',
    Component: WkJobCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_EDIT],
    title: "Édition d'un corps d'état",
  },
  {
    path: '/castres/work/unit/list',
    Component: WkUnitList,
    access: [ROLE.ADMIN_WORK_UNIT_LIST],
    title: 'Liste des unités de travail',
  },
  {
    path: '/castres/work/unit/create',
    Component: WkUnitCreateEdit,
    access: [ROLE.ADMIN_WORK_UNIT_EDIT],
    title: "Création d'une unité de travail",
  },
  {
    path: '/castres/work/unit/:id/edit',
    Component: WkUnitCreateEdit,
    access: [ROLE.ADMIN_WORK_UNIT_EDIT],
    title: "Édition d'une unité de travail",
  },
  {
    path: '/castres/staff/team/list',
    Component: SfTeamList,
    access: [ROLE.ADMIN_STAFF_TEAM_LIST],
    title: 'Liste des équipes',
  },
  {
    path: '/castres/staff/team/create',
    Component: SfTeamCreateEdit,
    access: [ROLE.ADMIN_STAFF_TEAM_CREATE],
    title: "Création d'une équipe",
  },
  {
    path: '/castres/staff/team/:id/edit',
    Component: SfTeamCreateEdit,
    access: [ROLE.ADMIN_STAFF_TEAM_EDIT],
    title: "Édition d'une équipe",
  },
  {
    path: '/castres/staff/habilitationtype/list',
    Component: SfHabilitationTypeList,
    access: [ROLE.ADMIN_CONFIGURATION_TRAININGTYPE_LIST],
    title: "Liste des types d'habilitations",
  },
  {
    path: '/castres/staff/habilitationtype/create',
    Component: SfHabilitationTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_TRAININGTYPE_CREATE],
    title: "Création d'un type d'habilitation",
  },
  {
    path: '/castres/staff/habilitationtype/:id/edit',
    Component: SfHabilitationTypeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_TRAININGTYPE_EDIT],
    title: "Édition d'un type d'habilitation",
  },
  {
    path: '/castres/staff/habilitationcellposition/list',
    Component: SfHabilitationCellPositionList,
    access: [ROLE.HABILITATION_CELL_POSITION],
    title: "Liste des cellules de la carte d'habilitation",
  },
  {
    path: '/castres/staff/habilitationcellposition/:id/edit',
    Component: SfHabilitationCellPositionCreateEdit,
    access: [ROLE.HABILITATION_CELL_POSITION],
    title: "Édition d'une cellule de la carte d'habilitation",
  },
  {
    path: '/castres/staff/habilitation/list',
    Component: SfHabilitationList,
    access: [ROLE.ADMIN_STAFF_TRAINING_LIST],
    title: 'Liste des habilitations',
  },
  {
    path: '/castres/staff/habilitation/create',
    Component: SfHabilitationCreateEdit,
    access: [ROLE.ADMIN_STAFF_TRAINING_CREATE],
    title: "Création d'habilitation",
  },
  {
    path: '/castres/staff/habilitation/create/:idUser/:idType',
    Component: SfHabilitationCreateEdit,
    access: [ROLE.ADMIN_STAFF_TRAINING_CREATE],
    title: "Création d'habilitation",
  },
  {
    path: '/castres/staff/habilitation/:id/edit',
    Component: SfHabilitationCreateEdit,
    access: [ROLE.ADMIN_STAFF_TRAINING_EDIT],
    title: "Édition d'habilitation",
  },
  {
    path: '/castres/staff/formation/list',
    Component: SfFormationListPage,
    access: [ROLE.ADMIN_STAFF_TRAINING_LIST],
    title: 'Liste des formations',
  },
  {
    path: '/castres/billing/studycategory/list',
    Component: BlStudyCategoryList,
    access: [ROLE.ADMIN_STUDY_REFERENCE_LIST],
    title: "Liste des catégories d'études",
  },
  {
    path: '/castres/billing/studycategory/:id/edit',
    Component: BlStudyCategoryCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Édition des catégories d'études",
  },
  {
    path: '/castres/billing/studycategory/create',
    Component: BlStudyCategoryCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Création des catégories d'études",
  },
  {
    path: '/castres/billing/manpower/list',
    Component: BlManPowerList,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_LIST],
    title: "Liste des taux de main d'œuvre",
  },
  {
    path: '/castres/billing/manpower/:id/edit',
    Component: BlManpowerCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_EDIT],
    title: "Édition des taux de main d'œuvre",
  },
  {
    path: '/castres/billing/manpower/create',
    Component: BlManpowerCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_JOB_CREATE],
    title: "Création des taux de main d'œuvre",
  },
  {
    path: '/castres/billing/referencestudyline/list',
    Component: BlReferenceStudyLineList,
    access: [ROLE.ADMIN_STUDY_REFERENCE_LIST],
    title: "Liste des catégories d'études",
  },
  {
    path: '/castres/billing/referencestudyline/:id/edit',
    Component: BlReferenceStudyLineCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Édition des catégories d'études",
  },
  {
    path: '/castres/billing/referencestudyline/create',
    Component: BlReferenceStudyLineCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Création des catégories d'études",
  },
  {
    path: '/castres/billing/referencestudygroup/list',
    Component: BlReferenceStudyGroupList,
    access: [ROLE.ADMIN_STUDY_REFERENCE_LIST],
    title: "Groupes de référence d'études",
  },
  {
    path: '/castres/billing/referencestudygroup/create',
    Component: BlReferenceStudyGroupCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Groupes de référence d'études",
  },
  {
    path: '/castres/billing/referencestudygroup/:id/edit',
    Component: BlReferenceStudyGroupCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: "Groupes de référence d'études",
  },
  {
    path: '/castres/billing/status/list',
    Component: BlStatusList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUS_LIST],
    title: 'Liste des statuts des devis / factures',
  },
  {
    path: '/castres/billing/status/create',
    Component: BlStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUS_CREATE],
    title: 'Statuts des devis / factures',
  },
  {
    path: '/castres/billing/status/:id/edit',
    Component: BlStatusCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUS_EDIT],
    title: 'Statuts des devis / factures',
  },
  {
    path: '/castres/billing/statusorder/list',
    Component: BlStatusOrderList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUSORDER_LIST],
    title: 'Liste des statuts commandes',
  },
  {
    path: '/castres/billing/statusorder/create',
    Component: BlStatusOrderCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUSORDER_CREATE],
    title: 'Statuts des commandes',
  },
  {
    path: '/castres/billing/statusorder/:id/edit',
    Component: BlStatusOrderCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_STATUSORDER_EDIT],
    title: 'Statuts des commandes',
  },
  {
    path: '/castres/client/client/create',
    Component: ClClientCreateEdit,
    access: [ROLE.ADMIN_CLIENT_CREATE],
    title: "Création d'un client",
  },
  {
    path: '/castres/client/client/:id/edit',
    Component: ClClientCreateEdit,
    access: [ROLE.ADMIN_CLIENT_EDIT],
    title: "Édition d'un client",
  },
  {
    path: '/castres/client/client/list',
    Component: ClClientList,
    access: [ROLE.ADMIN_CLIENT_LIST],
    title: 'Liste des clients',
  },
  {
    path: '/castres/client/client/:id/show',
    Component: ClClientShow,
    access: [ROLE.ADMIN_CLIENT_LIST],
    title: 'Client',
  },
  {
    path: '/castres/client/client/:id/edit',
    Component: ClClientCreateEdit,
    access: [ROLE.ADMIN_CLIENT_EDIT],
    title: "Édition d'un client",
  },
  {
    path: '/castres/client/clientcode/list',
    Component: ClClientCodeList,
    access: [ROLE.ADMIN_CONFIGURATION_CLIENTCODE_LIST],
    title: 'Liste des codes clients',
  },
  {
    path: '/castres/client/clientcode/create',
    Component: ClClientCodeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_CLIENTCODE_CREATE],
    title: "Création d'un code client",
  },
  {
    path: '/castres/client/clientcode/:id/edit',
    Component: ClClientCodeCreateEdit,
    access: [ROLE.ADMIN_CONFIGURATION_CLIENTCODE_EDIT],
    title: "Édition d'un code client",
  },
  {
    path: '/castres/client/contact/list',
    Component: ClContactList,
    access: [ROLE.ADMIN_CONTACT_LIST],
    title: 'Liste des contacts',
  },
  {
    path: '/castres/client/contact/:id/show',
    Component: ClContactShow,
    access: [ROLE.ADMIN_CONTACT_VIEW],
    title: 'Voir un contact',
  },
  {
    path: '/castres/client/contact/create',
    Component: ClContactCreateEdit,
    access: [ROLE.ADMIN_CONTACT_CREATE],
    title: "Création d'un contact",
  },
  {
    path: '/castres/client/contact/:id/edit',
    Component: ClContactCreateEdit,
    access: [ROLE.ADMIN_CONTACT_EDIT],
    title: "Édition d'un contact",
  },
  {
    devOnly: true,
    path: '/castres/billing/quotationrequest/list',
    Component: BlQuotationRequestList,
    access: [ROLE.ADMIN_QUOTATION_REQUEST_LIST],
    title: 'Liste des demandes de devis',
  },
  {
    path: '/castres/billing/priceschedule/create',
    Component: PriceScheduleAllPack,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EDIT],
    title: 'Édition bordereaux',
  },
  {
    path: '/castres/billing/priceschedule/:id/edit',
    Component: PriceScheduleAllPack,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EDIT],
    title: 'Édition bordereaux',
  },
  {
    path: '/castres/billing/priceschedule/list',
    Component: BlPriceScheduleGroupList,
    access: [ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_LIST],
    title: 'Liste des bordereaux',
  },
  {
    path: '/castres/configuration/notification/family/list',
    Component: UsNotifFamilyList,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Configuration d'une famille de notification",
  },
  {
    path: '/castres/configuration/notification/family/create',
    Component: UsNotifFamilyCreateEdit,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Création d'une famille de notification",
  },
  {
    path: '/castres/configuration/notification/family/:id/edit',
    Component: UsNotifFamilyCreateEdit,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Édition d'une famille de notification",
  },
  {
    path: '/castres/configuration/notification/family/list',
    Component: UsNotifFamilyList,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Configuration d'une famille de notification",
  },
  {
    path: '/castres/configuration/notification/family/create',
    Component: UsNotifFamilyCreateEdit,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Création d'une famille de notification",
  },
  {
    path: '/castres/configuration/notification/family/:id/edit',
    Component: UsNotifFamilyCreateEdit,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT],
    title: "Édition d'une famille de notification",
  },
  {
    path: '/castres/configuration/settings/edit',
    Component: WkConfigurationSettings,
    access: [ROLE.SUPER_ADMIN],
    title: 'Édition des configurations externes',
  },
  {
    path: '/castres/configuration/notification/list',
    Component: UsNotifConfList,
    access: [ROLE.SUPER_ADMIN],
    title: 'Liste des configuration de notification',
  },
  {
    path: '/castres/configuration/notification/create',
    Component: UsNotifConfCreateEdit,
    access: [ROLE.SUPER_ADMIN],
    title: "Création d'une configuration de notification",
  },
  {
    path: '/castres/configuration/notification/:id/edit',
    Component: UsNotifConfCreateEdit,
    access: [ROLE.SUPER_ADMIN],
    title: "Édition d'une configuration de notification",
  },
  {
    path: '/castres/timereporting/administration/:id/:date',
    Component: TimeReportingAdministration,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT, ROLE.USER_IS_MANAGER],
    title: "Administration d'une feuille d'heure ",
  },
  {
    path: '/castres/timereporting/administration/report',
    Component: TimeReportingReport,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EXPORT],
    title: "Rapport des feuilles d'heure",
  },
  {
    path: '/castres/timereporting/administration/intervention_report',
    Component: TimeReportingInterventionReport,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_EXPORT],
    title: "Rapport d'intervention des feuilles d'heures",
  },
  {
    path: '/castres/timereporting/administration',
    Component: TimeReportAdminList,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_LIST, ROLE.USER_IS_MANAGER],
    title: "Administration des feuilles d'heure",
  },
  {
    path: '/castres/timereporting/:paramId/:paramDate/:emulatedId?',
    Component: TimeReportingPage,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_CREATE, ROLE.USER_IS_MANAGER],
    title: "Feuille d'heures",
  },
  {
    path: '/castres/timereporting/',
    Component: TimeReportingPage,
    access: [ROLE.ADMIN_TIMEREPORTINGWEEK_CREATE, ROLE.USER_IS_MANAGER],
    title: "Feuille d'heures",
  },
  {
    path: '/castres/billing/quotation/list',
    Component: QuotationList,
    access: [ROLE.ADMIN_QUOTATION_LIST],
    title: 'Liste des devis',
  },
  {
    path: '/castres/location/department/:id/edit',
    Component: LoDepartmentCreateEdit,
    access: [ROLE.ADMIN_PARK_STATION_EDIT],
    title: "Édition d'un département",
  },
  {
    path: '/castres/location/department/list',
    Component: LoDepartmentList,
    access: [ROLE.ADMIN_PARK_STATION_LIST],
    title: 'Liste des départements',
  },
  // TODO : Rechanger les droits et route à la fin de la migration => rechercher /quotationV2/ et remplacer par /quotation/
  {
    path: '/castres/billing/quotationV2/list',
    Component: QuotationList,
    access: [ROLE.ADMIN_QUOTATION_LIST],
    title: 'Liste des devis',
  },
  {
    path: '/castres/billing/quotationV2/:id/show',
    Component: QuotationShow,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: 'Devis',
  },
  {
    path: '/castres/billing/quotationV2/create',
    Component: QuotationCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: 'Devis',
  },
  {
    path: '/castres/billing/quotationV2/:id/edit',
    Component: QuotationCreateEdit,
    access: [ROLE.ADMIN_STUDY_REFERENCE_EDIT],
    title: 'Devis',
  },
  {
    path: '/castres/billing/order/list',
    Component: OrderList,
    access: [ROLE.ADMIN_ORDER_LIST],
    title: 'Liste des commandes',
  },
  {
    path: '/castres/billing/delivery/list',
    Component: DeliveryList,
    access: [ROLE.ADMIN_ORDER_DELIVERY_LIST],
    title: 'Liste des livraisons',
  },
  {
    path: '/castres/work/task/list',
    Component: TaskList,
    access: [ROLE.ADMIN_WORK_TASK_LIST],
    title: 'Liste des tâches',
  },
  {
    path: '/castres/work/intervention/list',
    Component: InterventionList,
    access: [ROLE.ADMIN_WORK_INTERVENTION_LIST],
    title: 'Liste des interventions',
  },
  {
    path: '/castres/work/intervention/note/list',
    Component: InterventionNoteList,
    access: [ROLE.ADMIN_WORK_INTERVENTION_LIST],
    title: "Liste des notes d'interventions",
  },
  {
    path: '/castres/work/intervention/result',
    Component: InterventionResultList,
    access: [ROLE.ADMIN_WORK_INTERVENTION_EXPORT],
    title: "Liste des résultats d'intervention",
  },
  { path: '/logout', Component: Logout, title: 'logout', openRoute: true },
  { path: '/logoutV2', Component: Logout, title: 'logout', openRoute: true },
  {
    path: '/castres/notification/dashboard',
    Component: NotificationDashBoard,
    title: 'Notifications',
  },
  {
    path: '/castres/user/dashboard',
    Component: UserDashboard,
    title: 'Tableau de bord utilisateur',
  },
  { path: '/myparams', Component: MyParams, title: 'Mes paramètres', openRoute: true },
  { path: '/loginV2', Component: Login, title: 'LoginV2', openRoute: true },
  { path: '/reset/request', Component: Login, title: 'LoginV2', openRoute: true },
  { path: '/resetV2/:id', Component: ResetPassword, title: 'Réinitialisation de mot de passe', openRoute: true },
  { path: '/castres/homepage', Component: HomePage, title: "Page d'accueil", openRoute: true },
  { path: '/homepage', Component: HomePage, title: "Page d'accueil", openRoute: true },
  { path: '/', Component: UserDashboard, title: 'Tableau de bord utilisateur' },
  {
    path: '/schedule/map',
    Component: OsmMap,
    access: [ROLE.ADMIN_WORK_INTERVENTION_LIST, ROLE.ADMIN_WORK_TASK_LIST],
    title: 'Carte Planning tâches',
  },
  {
    path: '/generateMap/:latitude/:longitude',
    Component: OsmMapFromList,
    access: [ROLE.ADMIN_CONFIGURATION_AGENCY_LIST, ROLE.ADMIN_PARK_STATION_LIST],
    title: 'Carte Osm',
  },
];
