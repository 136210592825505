import React, { CSSProperties, FC } from 'react';
import { Effect, LazyLoadImage } from 'react-lazy-load-image-component';

interface CmsLazyLoadImageProps {
  key?: string;
  alt: string;
  effect?: Effect;
  src: string;
  style?: CSSProperties;
  className?: string;
}

/**
 * Lazy load image component, abtrait pour éviter de dépendre de la librairie react-lazy-load-image-component
 */
export const CmsLazyLoadImage: FC<CmsLazyLoadImageProps> = (props) => {
  return <LazyLoadImage {...props} />;
};
