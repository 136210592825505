import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import CRUD from '../../../service/CRUD.service';
import {
  BlQuotation,
  BlQuotationAttachment,
  BlQuotationHistory,
  BlQuotationLine,
  BlStatus,
} from '../../../interface/BlType';
import APIRoute from '../../../constant/API.constant';
import { CmsDivider, CmsLink, CmsPaper, HeaderPanel, WarningBubble } from '../../../component/shared/Ui';
import { LabelValueIf, ListStyleDataView, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { ArrowBackNavigationButton, EditNavigationButton, NavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { Link, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { ClClient, ClContact, ClStation } from '../../../interface/ClType';
import './quotation.scss';
import LoadingScreen from '../../../component/LoadingScreen';
import { getChapterAmount } from './QuotationCreateEdit';
import { API_URL } from '../../../constant/API_URL';
import Utils from '../../../helper/Utils';
import { QuotationStats } from './QuotationStats';
import { Buttons, InputUI, UI } from 'component/shared';
import CmsIcon from '../../../component/shared/CmsIcon';
import { useNavigate } from 'react-router-dom';
import { CmsNote, CmsNotesPanel } from '../../../component/notes/notes';
import notificationService from '../../../service/NotificationService';
import { CmsRichTextEditor } from '../../../component/shared/CmsRichTextEditor';
import { CmsLazyLoadImage } from '../../../component/shared/CmsLazyLoadImage';

interface QuotationContextProps {
  quotation: BlQuotation;
  updateQuotation: (quotation: BlQuotation) => void;
  notes: CmsNote[];
  setNotes: (notes: CmsNote[]) => void;
}

export const QuotationContext = React.createContext<QuotationContextProps>({
  quotation: {} as BlQuotation,
  updateQuotation: () => {},
  notes: [],
  setNotes: () => {},
});

function groupNotes(notes: CmsNote[]): CmsNote[] {
  const groupedNotes: any = [];
  for (let note of notes) {
    let alreadyExist = groupedNotes.find((x: any) => x.date === note.date && x.title === note.title);
    if (!alreadyExist && !note.fileRoute) groupedNotes.push(note);
    else if (alreadyExist) {
      alreadyExist.fileList = [
        ...(alreadyExist.fileList ?? []),
        { fileRoute: note.fileRoute, filename: note.filename },
      ];
    } else groupedNotes.push({ ...note, fileList: [{ fileRoute: note.fileRoute, filename: note.filename }] });
  }
  return groupedNotes;
}

export const QuotationShow: FC<any> = ({ id }) => {
  const [quotation, setQuotation] = React.useState<any>();
  const [notes, setNotes] = React.useState<CmsNote[]>([]);

  const updateQuotation = (data: BlQuotation) => {
    let currentIdent = 0;
    for (const line of data.quotationLines ?? []) {
      if (line.chapter) {
        currentIdent = line.chapterLevel ?? 0;
        line.chapterAmount = getChapterAmount(data.quotationLines ?? [], line.id ?? -1, 'totalAmount');
      }
      line.indent = currentIdent;
    }
    setQuotation(data);
  };

  useEffect(() => {
    CRUD.getById<BlQuotation>(APIRoute.BlQuotation, id).then(updateQuotation);
    CRUD.getById<CmsNote[]>(APIRoute.blQuotationAttachment, id).then(setNotes);
  }, [id]);

  if (!quotation) return <LoadingScreen />;
  return (
    <QuotationContext.Provider value={{ quotation, updateQuotation, notes, setNotes }}>
      <QuotationHeader quotation={quotation} />
      <QuotationNotes />
      <div className="quotation-show-container">
        <GeneralInfo />
        <SiteInfo />
        <Status />
        <StationPicture />
        <QuotationComment />
        <ClientInfo />
      </div>
      <QuotationStats />
      <QuotationPriceList />
    </QuotationContext.Provider>
  );
};

const QuotationNotes: FC = () => {
  const { notes, setNotes, quotation } = useContext(QuotationContext);
  const groupedNotes = useMemo(() => groupNotes(notes), [notes]);

  const handleAddNote = (note: CmsNote) => {
    return CRUD.postFormData<CmsNote>(APIRoute.blQuotationAttachment, { ...note, quotationId: quotation.id }).then(
      (data) => {
        setNotes([data, ...notes]);
        notificationService.success('Note ajoutée');
      },
    );
  };

  return (
    <CmsNotesPanel
      title="Notes"
      card={CustomNoteCard}
      notes={groupedNotes}
      onSubmitNewNote={handleAddNote}
      formOverride={QuotationNoteForm}
    />
  );
};

const CustomNoteCard: (note: any) => ReactNode = (note) => {
  const handleDownload = (fileRoute: string, filename: string) => {
    CRUD.getBlob(fileRoute).then((x) => {
      Utils.downloadFile(x, filename);
    });
  };

  return (
    <div className="default-note-card">
      <h3 className="default-note-title">{note.title}</h3>
      <div className="default-note-date" style={{ textAlign: 'right' }}>
        {Utils.displayDateTime(note.date)}
      </div>
      <div className="dangerouslySetInnerHTML" dangerouslySetInnerHTML={{ __html: note.comment ?? '' }} />
      {!note.fileList && note.filename && (
        <div className="default-note-file flex-h">
          <CmsIcon icon="download" tooltip="Pièce jointe" />
          <div className="link" onClick={() => handleDownload(note.fileRoute, note.filename)}>
            {note.filename}
          </div>
        </div>
      )}
      {note.fileList &&
        note.fileList.map((item: any) => (
          <div className="default-note-file flex-h">
            <CmsIcon icon="download" tooltip="Pièces jointe" />
            <div className="link" onClick={() => handleDownload(item.fileRoute, item.filename)}>
              {item.filename}
            </div>
          </div>
        ))}
      <CmsDivider />
    </div>
  );
};

const QuotationNoteForm = (data: any, setData: (data: any) => void, onSubmit: any) => {
  return (
    <div>
      <h3>Ajouter une note</h3>
      <CmsRichTextEditor content={data.comment} setContent={(x: any) => setData({ ...data, comment: x })} />
      <div className="flex-h-bet" style={{ margin: '1rem 0' }}>
        <InputUI.InputFile
          onFileSelected={(x: any) => setData({ ...data, file: x })}
          buttonLabel="Ajouter une pièce jointe"
        />
        <Buttons.Valid onClick={onSubmit}>Ajouter une note</Buttons.Valid>
      </div>
    </div>
  );
};

// const noteCard = (note: any) => {};

const Status: FC = () => {
  const { quotation } = useContext(QuotationContext);
  const style: CSSProperties = { textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' };
  return (
    <CmsPaper className="status" title="Statut" actions={[<QuotationHistory />, <QuotationStatusModifier />]} tied>
      <WarningBubble style={style} customColor={'#' + (quotation.status?.color ?? '00AAFF')}>
        {quotation.status?.label ?? 'En cours de création'}
      </WarningBubble>
    </CmsPaper>
  );
};

const QuotationHistory: FC = () => {
  const [open, setOpen] = useState(false);
  const { quotation } = useContext(QuotationContext);
  return [
    <Buttons.Default onClick={() => setOpen(true)}>Historique</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        style={{ minWidth: '50rem', marginBottom: 0 }}
        className="quotation-history"
        title="Historique"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        tied
      >
        <ListStyleDataView
          fromList={quotation.blQuotationHistory ?? []}
          cell={(x: BlQuotationHistory) => (
            <div>
              <div className="history-round" style={{ backgroundColor: '#' + x.status.color }} />
              {Utils.setToKebabDate(new Date(x.eventDate))} - {x.status?.label} - {x.userName}
            </div>
          )}
        />
      </CmsPaper>
    </UI.Dialog>,
  ];
};

const StationPicture: FC = () => {
  const { quotation, notes } = useContext(QuotationContext);
  const handleDownload = (attachment: BlQuotationAttachment) => {
    CRUD.getBlob(APIRoute.BlQuotation + '/GetAttachments', { id: attachment.id }).then((blob) => {
      Utils.downloadFile(blob, attachment.filename);
    });
  };

  const filteredList = useMemo(() => {
    return notes.filter((x) => x.fileRoute && x.filename);
  }, [notes]);

  return (
    <CmsPaper className="station-picture" title="Pièces jointes" tied>
      {quotation.imagePath && (
        <div className="flex-center">
          <CmsLazyLoadImage
            alt="Image entete devis"
            src={`${API_URL}${APIRoute.BlQuotation}/Pic/${quotation.id}`}
            style={{
              maxHeight: '10rem',
              maxWidth: '100%',
              borderRadius: '0.3rem',
              boxShadow: '0 0 0.5rem 0.1rem rgba(0,0,0,0.3)',
            }}
          />
        </div>
      )}
      <ListStyleDataView
        title="Pièces Jointes"
        style={{ overflowY: 'auto', maxHeight: '20rem' }}
        fromList={filteredList}
        cell={(x) => (
          <Link style={{ marginLeft: '0.2rem' }} className="clickable" onClick={() => handleDownload(x)}>
            {x.filename}
          </Link>
        )}
      />
    </CmsPaper>
  );
};

const QuotationComment: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="quotation-comment" title="Commentaire" tied>
      <div dangerouslySetInnerHTML={{ __html: quotation.comment ?? '' }} />
    </CmsPaper>
  );
};

const QuotationHeader: FC<any> = ({ quotation }) => {
  return (
    <HeaderPanel
      title={'Devis N° ' + quotation.ref}
      actions={[
        <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/billing/quotation/list" />,
        <NavigationButton
          to={`/castres/billing/quotation/${quotation.id}/print?hidePrice=true`}
          title="Imprimé sans prix"
        />,
        <NavigationButton to={`/castres/billing/quotation/${quotation.id}/print`} title="Imprimé" />,
        <QuotationVersioning />,
        <QuotationDuplicator />,
        <EditNavigationButton
          access={[ROLE.ADMIN_QUOTATION_EDIT]}
          title="Éditer le devis"
          to={`/castres/billing/quotationV2/${quotation.id}/edit`}
        />,
      ]}
    />
  );
};

const GeneralConf: LabelValueIf[] = [
  { label: 'Référence', value: (x: BlQuotation) => x.ref },
  { label: 'Titre', value: (x: BlQuotation) => x.title },
  { label: 'En date du', value: (x: BlQuotation) => x.createdAt },
  // { label: "Corps d'état", value: (x: BlQuotation) => 'TODO' },
  { label: 'Groupe', value: (x: BlQuotation) => x.group?.label },
  { label: 'Service', value: (x: BlQuotation) => x.service?.label },
  { label: 'Contrat', value: () => 'TODO' },
  { label: 'Créer par', value: (x: BlQuotation) => x.createdBy?.nameReversed },
  { label: 'Modifier par', value: (x: BlQuotation) => x.updatedBy?.nameReversed },
  { label: 'Contenu modifié le', value: (x: BlQuotation) => Utils.setToKebabDate(new Date(x.updatedAt)) },
  { label: 'N° de commande', value: () => 'TODO' },
  { label: 'Devis Excel', value: () => 'TODO' },
];

const GeneralInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  const action = [];
  if (quotation.mainVersionId) {
    const url = `/castres/billing/quotationV2/${quotation.mainVersionId}/show`;
    action.push(<CmsLink href={url} label="Version précédente" target="_blank" defaultStyle />);
  }
  if (quotation.inverseMainVersionId) {
    const url = `/castres/billing/quotationV2/${quotation.inverseMainVersionId}/show`;
    action.push(<CmsLink href={url} label="Version suivante" target="_blank" defaultStyle />);
  }
  return (
    <CmsPaper className="general-info" title="General" actions={action} tied>
      <TabStyleDataView conf={GeneralConf} mapFrom={quotation} />
    </CmsPaper>
  );
};

const ClientInfoConf: LabelValueIf[] = [
  {
    label: 'Client',
    value: (x: ClClient) => (
      <CmsLink href={`/castres/client/client/${x.id}/show`} label={x.label} target="_blank" defaultStyle />
    ),
  },
  {
    label: 'Adresse',
    value: (x: ClClient) =>
      `${x.address ?? 'non renseigné'}, ${x.postcode ?? 'non renseigné'}, ${x.city ?? 'non renseigné'}`,
  },
  { label: 'Tel', value: (x: ClClient) => x.tel },
  { label: 'Email', value: (x: ClClient) => x.email },
];

const ContactInfoConf: LabelValueIf[] = [
  {
    label: 'Destinataire',
    value: (x: ClContact) =>
      x.getFormattedName ? (
        <CmsLink
          href={`/castres/client/contact/${x.id}/show`}
          label={x.getFormattedName}
          target="_blank"
          defaultStyle
        />
      ) : (
        <></>
      ),
  },
  {
    label: 'Adresse',
    value: (x: ClContact) =>
      `${x.address ?? 'non renseigné'}, ${x.postcode ?? 'non renseigné'}, ${x.city ?? 'non renseigné'}`,
  },
  { label: 'Tel', value: (x: ClContact) => x.tel },
  { label: 'Email', value: (x: ClContact) => x.email },
];

const ClientInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="client-info" title="Destinataire" tied>
      <div className="client-contact-container">
        <div>
          <TabStyleDataView conf={ClientInfoConf} mapFrom={quotation.client} />
        </div>
        <div>
          <TabStyleDataView conf={ContactInfoConf} mapFrom={quotation.contact} />
        </div>
      </div>
    </CmsPaper>
  );
};

const SiteInfoConf: LabelValueIf[] = [
  {
    label: 'Intervention',
    value: (x: BlQuotation) => {
      if (!x.interventionId) return 'Aucune intervention associée';
      return (
        <CmsLink
          href={`/castres/work/intervention/${x.interventionId}/show`}
          label={x.interventionRef ?? ''}
          target="_blank"
          defaultStyle
        />
      );
    },
  },
  { label: 'Enseigne', value: () => 'TODO' },
  {
    label: 'Site',
    value: (x: BlQuotation) => {
      if (!x.stationResource?.id) return 'Aucune Station associée';
      const label = x.stationResource.ref + '_' + x.stationResource?.label;
      return (
        <CmsLink
          href={`/castres/client/station/${x.stationResource.id}/show`}
          label={label}
          target="_blank"
          defaultStyle
        />
      );
    },
  },
  {
    label: 'Adresse',
    value: (x: BlQuotation) => {
      return `${x.stationResource?.address}, ${x.stationResource?.postcode}, ${x.stationResource?.city}`;
    },
  },
  { label: 'Facture', value: () => 'TODO' },
];

const SiteInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="site-info" title="Site" tied>
      <TabStyleDataView conf={SiteInfoConf} mapFrom={quotation} />
    </CmsPaper>
  );
};

const tableConfig: any[] = [
  { label: 'Désignation', value: null },
  { label: 'Unité', value: null },
  { label: 'Quantité', value: null },
  { label: 'Coût Unitaire', value: null },
  { label: 'Remise (%)', value: null },
  { label: 'Total H.T', value: '3rem' },
  { label: 'TVA', value: '3rem' },
  // { label: 'Montant H.T', value: '7rem' },
  { label: 'Facturé / Comptabilisé', value: '6rem' },
  { label: 'Dont avoirs', value: '6rem' },
];

const QuotationPriceList: FC = () => {
  const { quotation } = useContext(QuotationContext);
  const chapterCount = quotation.quotationLines?.filter((x) => x.chapter).length ?? 0;
  const lineCount = quotation.quotationLines?.filter((x) => !x.chapter).length ?? 0;
  return (
    <CmsPaper title={'Devis N° ' + quotation.ref}>
      <div className="flex-h" style={{ marginBottom: '1rem' }}>
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          Total H.T: {Utils.ThousandSpacing(quotation.totalAmount ?? 0)} €
        </WarningBubble>
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          TVA: {Utils.ThousandSpacing(quotation.totalTva ?? 0, 2)} €
        </WarningBubble>
        <WarningBubble type="info">Total TTC: {Utils.ThousandSpacing(quotation.totalTtc ?? 0, 2)} €</WarningBubble>
        <WarningBubble type="info">
          Total Prix de vente souhaité: {Utils.ThousandSpacing(quotation.totalDesiredSalePrice ?? 0, 2)} €
        </WarningBubble>
        <WarningBubble type="info">
          Total coût de revient: {Utils.ThousandSpacing(quotation.totalCostPrice ?? 0, 2)} €
        </WarningBubble>
        <WarningBubble type="info">
          Coeff de vente: {Utils.ThousandSpacing(quotation.brandCoeff ?? 0, 2)} %
        </WarningBubble>
        <WarningBubble type="info">Nombre de chapitre/lignes: {`${chapterCount}/${lineCount}`} </WarningBubble>
      </div>
      <TableContainer style={{ borderRadius: '6px', border: '2px solid #888', overflow: 'auto' }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableConfig.map((column, i) => (
                <TableCell key={i} style={{ width: column.value ?? 'auto', backgroundColor: 'rgba(0,0,0,0.2)' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Body />
        </Table>
      </TableContainer>
    </CmsPaper>
  );
};

const Body: FC<any> = () => {
  const { quotation } = useContext(QuotationContext);
  if (!quotation?.quotationLines) return <></>;
  return (
    <TableBody className="body-show">
      {quotation.quotationLines.map((line, i) => {
        if (line.chapter) return <ChapterRow line={line} index={i} />;
        return <QuotationGroupRow line={line} index={i} />;
      })}
    </TableBody>
  );
};

const ChapterRow: FC<{ line: BlQuotationLine; index: number }> = ({ line, index }) => {
  const amountColIndex = tableConfig.findIndex((x) => x.label === 'Total H.T');
  return (
    <TableRow className="chapter-row" key={'chapter' + index} style={{ backgroundColor: 'rgba(0,100,255,0.2)' }}>
      <TableCell>
        <div style={{ marginLeft: (line.indent ?? 0) + 'rem' }}>{line.label}</div>
      </TableCell>
      <TableCell colSpan={amountColIndex - 1} />
      <TableCell className="col-show">{Utils.ThousandSpacing(line.chapterAmount ?? 0, 2)} €</TableCell>
      <TableCell colSpan={tableConfig.length - amountColIndex - 1} />
    </TableRow>
  );
};

const QuotationGroupRow: FC<{ line: BlQuotationLine; index: number }> = ({ line, index }) => {
  const style = index % 2 === 0 ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {};
  return (
    <TableRow className="row-show" key={index} style={style}>
      <TableCell>
        <div style={{ marginLeft: (line.indent ?? 0) + 1 + 'rem' }}>{line.label}</div>
      </TableCell>
      <TableCell>{line.unit}</TableCell>
      <TableCell>{line.quantity}</TableCell>
      <TableCell className="col-show">{Utils.ThousandSpacing(line.price, 2)} €</TableCell>
      <TableCell className="col-show">{line.discount} %</TableCell>
      <TableCell className="col-show">{Utils.ThousandSpacing(line.totalAmount ?? 0, 2)} €</TableCell>
      <TableCell className="col-show">{(line.vatValue ?? 0) * 100} %</TableCell>
      {/*<TableCell className="col-show">{line.totalAmount ?? 0} €</TableCell>*/}
      <TableCell className="col-show">TODO</TableCell>
      <TableCell className="col-show">TODO</TableCell>
    </TableRow>
  );
};

const QuotationVersioning: FC = () => {
  const [open, setOpen] = React.useState(false);
  const { quotation } = useContext(QuotationContext);
  const navigate = useNavigate();

  const handleVersioning = () => {
    CRUD.post<BlQuotation>(APIRoute.BlQuotation + '/Versioning', { id: quotation.id }).then((data) => {
      navigate(`/castres/billing/quotationV2/${data.id}/edit`);
    });
  };

  if (quotation.inverseMainVersionId || !quotation.isQuotationAtLeastSent) return <></>;
  return [
    <Buttons.Default onClick={() => setOpen(true)}>Versionner</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        title="Créer une nouvelle version de devis"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        style={{ marginBottom: 0 }}
      >
        <p style={{ marginBottom: '1rem' }}>Vous vous apprêtez à créer une nouvelle version de ce devis. </p>
        <div className="flex-h-around">
          <Buttons.Cancel onClick={() => setOpen(false)}>Annuler</Buttons.Cancel>
          <Buttons.Valid onClick={handleVersioning}> Créer une nouvelle version </Buttons.Valid>
        </div>
      </CmsPaper>
    </UI.Dialog>,
  ];
};

const QuotationDuplicator: FC = () => {
  const [open, setOpen] = React.useState(false);
  const { quotation } = useContext(QuotationContext);
  const [stationList, setStationList] = React.useState<any[]>([]);
  const [stationId, setStationId] = React.useState<number>();
  const navigate = useNavigate();

  useEffect(() => {
    CRUD.getList<ClStation>(APIRoute.ClStation + '/Simplified').then(setStationList);
  }, []);

  const handleDuplication = () => {
    CRUD.post<BlQuotation>(APIRoute.BlQuotation + '/Duplicate', { id: quotation.id, stationId }).then((data) => {
      navigate(`/castres/billing/quotationV2/${data.id}/edit`);
    });
  };

  return [
    <Buttons.Default onClick={() => setOpen(true)}>Dupliquer</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        title="Duplication du devis"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        style={{ marginBottom: 0 }}
      >
        <p>Veuillez sélectionner la station à rattacher à la duplication </p>
        <InputUI.AutoCompletor required options={stationList} value={stationId} onChange={setStationId} />
        <div className="flex-h-around">
          <Buttons.Cancel onClick={() => setOpen(false)}>Annuler</Buttons.Cancel>
          <Buttons.Valid disabled={!stationId} onClick={handleDuplication}>
            Dupliquer
          </Buttons.Valid>
        </div>
      </CmsPaper>
    </UI.Dialog>,
  ];
};

const QuotationStatusModifier: FC = () => {
  const [open, setOpen] = useState(false);
  const { quotation, updateQuotation } = useContext(QuotationContext);
  const [statusList, setStatusList] = useState<BlStatus[]>();

  useEffect(() => {
    CRUD.getList<BlStatus>(APIRoute.BlStatus + '/WithCanGoTo').then(setStatusList);
  }, []);

  const canGoToList = useMemo(() => {
    const currentStatus = statusList?.find((x) => x.id === quotation.status?.id ?? -1);
    if (!currentStatus) return [];
    return statusList?.filter((x) => currentStatus.canGoToCode?.includes(x.code));
  }, [statusList, quotation]);

  const handleStatusChange = (nextStatus: BlStatus) => {
    CRUD.post<BlQuotation>(APIRoute.BlQuotation + '/Status', { id: quotation.id, value: nextStatus.id }).then(
      (data) => {
        setOpen(false);
        updateQuotation(data);
      },
    );
  };

  if (!statusList) return <></>;
  return [
    <Buttons.Default onClick={() => setOpen(true)}>Modifier le statut</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        title="Changer le statut"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        style={{ marginBottom: 0 }}
      >
        {canGoToList?.map((status: BlStatus) => (
          <Buttons.Default style={{ marginBottom: '0.5rem' }} onClick={() => handleStatusChange(status)}>
            {status.label}
          </Buttons.Default>
        ))}
      </CmsPaper>
    </UI.Dialog>,
  ];
};
