import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BarChart, PieChart, BarChartProps } from '@mui/x-charts';

interface CmsBarChartProps extends BarChartProps {
  // Style du conteneur
  containerStyle?: React.CSSProperties;
}

// Marge par défaut
const margin = { top: 50, right: 0, bottom: 20, left: 30 };
// Style pour la légende
const sx = {
  '--ChartsLegend-itemWidth': '70px',
  '--ChartsLegend-itemMarkSize': '20px',
  '--ChartsLegend-labelSpacing': '5px',
  '--ChartsLegend-rootSpacing': '20%',
};

/**
 * Surcharge du composant BarChart de la librairie @mui/x-charts
 * @param props Se référer à la documentation de la librairie @mui/x-charts
 */
const CmsBarChart: FC<CmsBarChartProps> = (props) => {
  const elRef: any = useRef();
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(elRef.current.offsetWidth);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => setWidth(elRef.current.clientWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', ...props.containerStyle }} ref={elRef}>
      <BarChart margin={margin} width={width} sx={sx} height={300} {...(props as any)} />
    </div>
  );
};

interface DataPieChart {
  id: number;
  value: number;
  label: string;
}

interface CmsPieChartProps {
  // Style du conteneur
  containerStyle?: React.CSSProperties;
  data: DataPieChart[];
}

const CmsPieChart: FC<CmsPieChartProps> = ({ data }) => {
  return <PieChart leftAxis={{ position: 'left' }} series={[{ data }]} width={600} height={200} />;
};

/**
 * SurCms pour la librairie @mui/x-charts
 * Toujous créer un composant pour chaque type de graphique, ne pas le créer directement dans la page.
 */
const CmsChart = {
  Bar: CmsBarChart,
  Pie: CmsPieChart,
};

export default CmsChart;
