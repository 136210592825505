import React, { FC, useContext } from 'react';
import { Buttons } from '../component/shared';
import notificationService from '../service/NotificationService';
import { GlobalContext } from '../context/Global.context';
import { CmsPaper } from 'component/shared/Ui';
import { CmsRichTextEditor } from '../component/shared/CmsRichTextEditor';

export const MyParams: FC = () => {
  const [content, setContent] = React.useState<string>('');
  const { globalHistory } = useContext(GlobalContext);
  const reinitFilters = () => {
    globalHistory.clean();
    notificationService.info('Les filtres ont été réinitialisés');
  };

  return (
    <CmsPaper style={{ maxWidth: '400px' }} title="Mes paramètres">
      <Buttons.Valid onClick={reinitFilters}>Réinitialiser tous les filtres des listes de la solution</Buttons.Valid>
      <CmsRichTextEditor content={content} setContent={setContent} />
    </CmsPaper>
  );
};
