import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { BlQuotation, BlQuotationLine } from '../../../interface/BlType';
import { CmsForm } from '../../../component/form/CmsForm';
import APIRoute from '../../../constant/API.constant';
import { UseFormReturn } from 'react-hook-form';
import CmsIcon from '../../../component/shared/CmsIcon';
import { Buttons, FormUI, InputUI, UI } from '../../../component/shared';
import { CmsFormInput } from '../../../component/form/CmsFormInput';
import { CmsPaper } from '../../../component/shared/Ui';
import NotificationService from '../../../service/NotificationService';
import CRUD from '../../../service/CRUD.service';
import { IdLabel } from '../../../interface/CommonType';
import { API_URL } from '../../../constant/API_URL';
import { BlQuotationAttachmentList } from './QuotationCreateEdit';
import { CmsLazyLoadImage } from '../../../component/shared/CmsLazyLoadImage';

interface QuotationFormPartProps {
  id: number;
  defaultValues?: any;
  handleSave: any;
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  baseUrl: string;
  routeList: any;
  setRouteList: Dispatch<SetStateAction<any>>;
  setOtherFormValues: Dispatch<SetStateAction<any>>;
  onHeadFormChange?: any;
}

export const QuotationFormPart: FC<QuotationFormPartProps> = ({
  id,
  defaultValues,
  handleSave,
  setState,
  baseUrl,
  routeList,
  setRouteList,
  setOtherFormValues,
  onHeadFormChange,
}) => {
  const [showForm, setShowForm] = useState(!id);
  return (
    <CmsForm
      id={id}
      onChange={onHeadFormChange}
      defaultValues={defaultValues}
      currentUrl={'/castres/billing/quotation'}
      route={APIRoute.BlQuotation}
      overLoadOnSubmit={handleSave}
      onGetEditData={(quotation: BlQuotation) => {
        setState(quotation?.quotationLines ?? []);
        setOtherFormValues({ depositLabel: quotation.depositLabel, depositAmount: quotation.depositAmount });
        return quotation;
      }}
      renderForm={(formState: UseFormReturn, submit: any) => {
        const { interventionRef, clientLabel, contactName, ref, clientId, stationLabel } = formState.watch();
        const title = [
          <CmsIcon icon={showForm ? 'down' : 'right'} onClick={() => setShowForm(!showForm)} />,
          id ? 'Éditer le devis ' + ref : 'Créer un devis',
        ];
        if (!showForm)
          return [
            <FormUI.HeaderFormPanel pageId={id} {...{ title, form: formState, baseUrl, onSubmit: submit }} />,
            <div style={{ display: 'none' }}>
              <CmsFormInput.Text id="title" label="Titre" required multiline />,
            </div>,
          ];
        return [
          <FormUI.HeaderFormPanel pageId={id} {...{ title, form: formState, baseUrl, onSubmit: submit }} />,
          <div className="cms-grid fr3">
            <CmsPaper title="Informations générales">
              <CmsFormInput.Text id="title" label="Titre" required multiline />
              {/*<CmsFormInput.Select id="jobId" label="Corps d'état" options={APIRoute.WkJob + '/Simplified'} />*/}
              <CmsFormInput.Select
                required
                id="agreementId"
                label="Type de contrat"
                options={APIRoute.WkAgreement + '/SimplifiedForQuotation'}
              />
              <CmsFormInput.Select id="groupId" label="Groupe" options={APIRoute.WkInterventionGroup + '/Simplified'} />
              <CmsFormInput.Suggest
                id="interventionId"
                label="Chantier – Intervention"
                defaultValueHolder={interventionRef}
                optionLabel="ref"
                route={routeList.intervention}
              />
              <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} />
            </CmsPaper>
            <CmsPaper title="Destinataire">
              <CmsFormInput.Suggest
                id="clientId"
                label="Client"
                defaultValueHolder={clientLabel}
                route={routeList.client}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  setRouteList({
                    ...routeList,
                    contact: APIRoute.ClContact + '/ByClient/' + item.id,
                    station: APIRoute.ClStation + '/ByClient/' + item.id,
                  });
                }}
                required
              />
              <CmsFormInput.Suggest
                id="contactId"
                label="Contact"
                defaultValueHolder={contactName}
                route={routeList.contact}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  let payload: any = { ...formState.watch(), contactId: item.id };
                  if (!!item.value?.id && clientId !== item.value?.id)
                    payload = { ...payload, clientId: item.value.id, clientLabel: item.value.label };
                  formState.reset(payload);
                }}
                required
              />
              <CmsFormInput.Suggest
                id="stationId"
                label="Site"
                defaultValueHolder={stationLabel}
                route={routeList.station}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  setRouteList({
                    ...routeList,
                    contact: APIRoute.ClContact + '/ByStation/' + item.id,
                    client: APIRoute.ClClient + '/ByStation/' + item.id,
                  });
                }}
                required
              />
            </CmsPaper>
            <QuotationPicAndFiles formState={formState} />
          </div>,
          <CmsPaper>
            <CmsFormInput.RichText id="comment" label="Commentaire avec style" />
          </CmsPaper>,
        ];
      }}
    />
  );
};

const QuotationPicAndFiles: FC<{ formState: UseFormReturn }> = ({ formState }) => {
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { id, imagePath } = formState.watch() as BlQuotation;
  const [fileUrl, setFileUrl] = useState<string | undefined>(imagePath);
  const [files, setFiles] = useState<FileList | undefined>();
  const handleFileToUpload = (file: File) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') return setFileToUpload(file);
    NotificationService.error(`Le fichier ${file.name} n'est pas une image au format JPG ou PNG`);
    setFileToUpload(undefined);
  };

  const handleUpload = () => {
    if (!fileToUpload) return NotificationService.error('Veuillez sélectionner une image à télécharger');
    CRUD.postFormData<IdLabel>(APIRoute.BlQuotation + '/Pic', {
      file: fileToUpload,
      id,
    }).then((x) => {
      NotificationService.success("L'image a été téléchargée avec succès");
      setFileToUpload(undefined);
      setFileUrl(x.label);
      formState.setValue('imagePath', x.label);
    });
  };

  const handleDelete = () => {
    formState.setValue('imagePath', undefined);
    setFileUrl(undefined);
  };

  const handleFileUpload = () => {
    if (!files)
      return NotificationService.error('Veuillez sélectionner au moins un fichier (maximum 50 Mo par fichier)');
    for (let i = 0; i < files.length; i++) {
      if (files[i].size < 52428800) continue;
      const message = 'Le fichier ' + files[i].name + ' est trop volumineux (50Mo max)';
      return NotificationService.error(message);
    }
    CRUD.postFile(APIRoute.blQuotationAttachment + '/Attachments/' + id, files).then(() => {
      NotificationService.success('Les fichiers ont été téléversés avec succès');
      setFiles(undefined);
    });
  };

  return (
    <CmsPaper className="pic-form" title="Pièces jointes">
      <div className="pic-buttons">
        {!!fileUrl && (
          <div className="flex-center">
            <CmsLazyLoadImage
              alt="En-tête du devis"
              effect="opacity"
              src={`${API_URL}${APIRoute.BlQuotation}/Pic/${id}`}
              style={{
                maxHeight: '10rem',
                maxWidth: '100%',
                borderRadius: '0.3rem',
                boxShadow: '0 0 0.5rem 0.1rem rgba(0,0,0,0.3)',
              }}
            />
          </div>
        )}
        {(!!id && [
          <InputUI.InputFile
            style={{ marginBottom: '0.5rem' }}
            name="quotation-pic"
            id="input-file-quotation-picture"
            onFileSelected={handleFileToUpload}
            image
          />,
          <div className="flex-h-around">
            <Buttons.Valid style={{ marginBottom: '0.5rem' }} disabled={!fileToUpload} onClick={handleUpload}>
              Télécharger l'image
            </Buttons.Valid>
            <Buttons.Delete style={{ marginBottom: '0.5rem' }} disabled={!fileUrl} onClick={handleDelete}>
              Supprimer l'image
            </Buttons.Delete>
          </div>,
        ]) ||
          "Veuillez d'abord enregistrer le devis pour pouvoir ajouter une photo"}
      </div>
      <UI.Divider />
      {(!!id && (
        <InputUI.InputFile
          id="linkedFiles"
          label="Pièces jointes"
          buttonLabel="Sélectionner (50 Mo max)"
          multiple
          onFileSelected={setFiles}
        />
      )) ||
        "Veuillez d'abord enregistrer le devis pour pouvoir ajouter des pièces jointes"}
      <Buttons.Valid
        style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}
        disabled={!files?.length}
        onClick={handleFileUpload}
      >
        Téléverser des pièces jointes (50 Mo max)
      </Buttons.Valid>
      <BlQuotationAttachmentList formState={formState} isShow={false} />
    </CmsPaper>
  );
};

interface EmptyQuotationModalProps {
  open?: 'onSave' | 'button';
  onCloseWithResponse: (action: 'delete' | 'noDelete' | 'noSave' | 'deleteNoSave') => void;
}

export const EmptyQuotationModal: FC<EmptyQuotationModalProps> = ({ open, onCloseWithResponse }) => {
  return (
    <UI.Dialog open={!!open} onClose={() => onCloseWithResponse('noSave')}>
      <CmsPaper title="Lignes sans quantité:" style={{ marginBottom: 0 }}>
        {(open == 'onSave' && (
          <p>
            Des lignes sans quantité ont été détectées. Il est recommandé de les supprimer avant l'enregistrement.
            Souhaitez-vous les supprimer maintenant ?
          </p>
        )) || <p>Vous êtes sur le point de supprimer toutes les lignes sans quantité. Voulez-vous continuer ?</p>}
        <p>Note : Les chapitres ne contenant aucune ligne seront également supprimés.</p>
        <div className="flex-h-around">
          <Buttons.Default onClick={() => onCloseWithResponse('noSave')}>Annuler</Buttons.Default>
          {(open == 'onSave' && [
            <Buttons.Delete onClick={() => onCloseWithResponse('delete')}>
              Supprimer les lignes sans quantité
            </Buttons.Delete>,
            <Buttons.Valid onClick={() => onCloseWithResponse('noDelete')}>Sauvegarder tel quel</Buttons.Valid>,
          ]) || (
            <Buttons.Delete onClick={() => onCloseWithResponse('deleteNoSave')}>
              Supprimer toutes les lignes sans quantité
            </Buttons.Delete>
          )}
        </div>
      </CmsPaper>
    </UI.Dialog>
  );
};
