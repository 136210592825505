import React, { FC, ReactNode } from 'react';
import './notes.scss';
import CmsIcon from '../shared/CmsIcon';
import { Buttons, InputUI, UI } from '../shared';
import { CmsDivider, CmsPaper } from '../shared/Ui';
import Utils from '../../helper/Utils';
import CRUD from '../../service/CRUD.service';
import { BASE_URL } from 'constant/API_URL';

export interface CmsNote {
  id: string | number;
  [key: string]: any;
  arrowColor?: string;
  fileRoute?: string;
}

interface CmsNoteParentProps {
  title: string;
  notes: CmsNote[];
  card?: (note: any) => ReactNode;
  onSubmitNewNote?: (note: any) => Promise<any>;
  formOverride?: (data: any, setData: (data: any) => void, onSubmit: (data: any) => void) => ReactNode;
}

const DefaultCard: (note: any) => ReactNode = (note) => {
  const handleDownload = () => {
    CRUD.getBlob(BASE_URL + note.fileRoute).then((x) => {
      Utils.downloadFile(x, note.filename);
    });
  };

  return (
    <div className="default-note-card">
      <h3 className="default-note-title">{note.title}</h3>
      <div className="default-note-date" style={{ textAlign: 'right' }}>
        {Utils.displayDateTime(note.date)}
      </div>
      <div className="default-note-content">{note.comment}</div>
      {note.filename && (
        <div className="default-note-file flex-h">
          <CmsIcon icon="download" tooltip="Pièce jointe" />
          <div className="link" onClick={handleDownload}>
            {note.filename}
          </div>
        </div>
      )}
      <CmsDivider />
    </div>
  );
};

const alternateColor = (index: number) => {
  return index % 2 === 0 ? 'black' : 'white';
};

export const CmsNotesPanel: FC<CmsNoteParentProps> = ({
  notes,
  title = 'Notes',
  card = DefaultCard,
  onSubmitNewNote,
  formOverride,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <div className={'notes-base ' + (open ? ' open' : '')}>
      <div className="notes-buttons">
        <div className="open-button">
          <CmsIcon icon="info" onClick={() => setOpen(!open)} />
        </div>
      </div>
      <div className="notes-header">
        {title}
        <CmsIcon icon="add" onClick={() => setOpenModal(!openModal)} />
      </div>
      <div className="overflow-container">
        <NoteContainer notes={notes} card={card} />
      </div>
      <NoteModal
        onSubmit={onSubmitNewNote}
        open={openModal}
        setOpen={setOpenModal}
        notes={notes}
        card={card}
        formOverride={formOverride}
      />
    </div>
  );
};

interface NoteContainerProps {
  notes: CmsNote[];
  card?: (note: any) => ReactNode;
}

const NoteContainer: FC<NoteContainerProps> = ({ notes, card = DefaultCard }) => {
  return (
    <div className="note-container">
      {notes?.map((note, index) => (
        <div key={note.id} className="note-item-container">
          <div className="arrow">
            <div className="arrow-up" style={{ borderBottomColor: note.arrowColor ?? alternateColor(index) }}></div>
            <div className="arrow-body" style={{ backgroundColor: note.arrowColor ?? alternateColor(index) }}></div>
            <div className="arrow-bubble" />
          </div>
          <div className="note-item">{card(note)}</div>
        </div>
      ))}
    </div>
  );
};

interface NoteModalProps extends NoteContainerProps {
  onSubmit?: (note: any) => Promise<any>;
  open: boolean;
  setOpen: (open: boolean) => void;
  formOverride?: (data: any, setData: (data: any) => void, onSubmit: (data: any) => void) => ReactNode;
}

const NoteModal: FC<NoteModalProps> = ({ onSubmit, open, setOpen, notes, card, formOverride }) => {
  const [newNote, setNewNote] = React.useState({ comment: '', file: undefined } as any);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit?.(newNote)
      .then(() => {
        setOpen(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper
        title="Notes"
        isLoading={loading}
        style={{ marginBottom: 0 }}
        actions={[<CmsIcon icon={'close'} onClick={() => setOpen(false)} />]}
      >
        {!formOverride && onSubmit && (
          <div className="note-form">
            <InputUI.DebouncedInput multiline onChange={(x: any) => setNewNote({ ...newNote, comment: x })} />
            <Buttons.Valid onClick={handleSubmit}>Ajouter une note</Buttons.Valid>
          </div>
        )}
        {formOverride && formOverride(newNote, setNewNote, handleSubmit)}
        <div className="overflow-modal-container">
          <NoteContainer notes={notes} card={card} />
        </div>
      </CmsPaper>
    </UI.Dialog>
  );
};

// {/*{React.Children.map(children, (child, index) => (*/}
// {/*  <div key={index} className="note-item-container">*/}
// {/*    <div className="arrow">*/}
// {/*      <div className="arrow-up" style={{ borderBottomColor: alternateColor(index) }}></div>*/}
// {/*      <div className="arrow-body" style={{ backgroundColor: alternateColor(index) }}></div>*/}
// {/*    </div>*/}
// {/*    <div className="note-item">{child}</div>*/}
// {/*  </div>*/}
// {/*))}*/}
