import React, { CSSProperties, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsForm, CmsFormPaper, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { Buttons, InputUI, UI } from '../../../component/shared';
import CRUD from '../../../service/CRUD.service';
import {
  BlPriceSchedule,
  BlPriceScheduleGroup,
  BlQuotationLine,
  BlReferenceStudyGroup,
} from '../../../interface/BlType';
import notificationService from '../../../service/NotificationService';
import { ClCompany } from '../../../interface/ClType';
import { CmsMenuButton } from '../../../component/shared/Menu';
import { CellDndContext, CmsColumnDndDef, CmsDndTable, DndUtils } from '../../../component/table/CmsDndTable';
import CmsIcon from '../../../component/shared/CmsIcon';
import LoadingScreen from '../../../component/LoadingScreen';
import { CmsImportPanel, CmsImportSimpleList, CmsTreeView } from '../../../component/shared/ImportPanel';
import { IdLabel } from '../../../interface/CommonType';
import { useNavigate } from 'react-router-dom';
import {
  colorCellFromBlReferenceStudyGroupLine,
  getAllLineGroupCalculation,
  getIndentStyle,
  SubRowChapterPic,
  SubRowCompanyComponent,
  SubRowRefStudyComponent,
} from './BlPriceScheduleTools';
import { CmsPaper } from '../../../component/shared/Ui';
import Tooltip from '@mui/material/Tooltip';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { API_URL } from '../../../constant/API_URL';
import Utils from '../../../helper/Utils';
import { DndInput } from '../../../component/table/DnDTableCell';
import { QuotationImportCsv, RefStudyTooltip } from '../../billing/quotation/QuotationImportPanel';
import './priceSchedule.scss';
import { getChapterAmount } from '../../billing/quotation/QuotationCreateEdit';
import { useDrop } from 'react-dnd';
import { Row } from '@tanstack/react-table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import WarningIcon from '@mui/icons-material/Warning';
import { WkCampaignType, WkUnit } from '../../../interface/WkType';
import { CmsLazyLoadImage } from '../../../component/shared/CmsLazyLoadImage';

function useHandleBusinessRules(groupId: number): [BlPriceSchedule[], Dispatch<SetStateAction<BlPriceSchedule[]>>] {
  const [state, setState] = React.useState<any[]>([]);
  const handleStateUpdate: any = (table: any[]) => {
    if (!table || !table.length) return setState([{ id: Utils.getNewId(table), indent: 0, position: 1 }]);
    const tableWithWarning = table.map(getWarningData);
    setState(calculateQuotationOrPriceScheduleRefAndIndent(tableWithWarning, groupId));
  };
  return [state, handleStateUpdate];
}

export function calculateQuotationOrPriceScheduleRefAndIndent(
  table: BlPriceSchedule[] | BlQuotationLine[],
  groupId?: number,
  isQuotation = false,
) {
  let currentIndent = 0;
  let currentRef: number[] = [];
  let lineCount = 0;
  let lastChapterIndex = 0;
  return table.map((row: BlPriceSchedule | BlQuotationLine, i: number) => {
    if (row.ref === row.calculatedRef || !row.ref || row.ref === '') row.ref = undefined;
    if (row.chapter) {
      lastChapterIndex = i;
      if (!row.chapterLevel) row.chapterLevel = 1;
      const delta = row.chapterLevel - currentIndent;
      currentIndent = row.chapterLevel > currentIndent + 1 ? currentIndent + 1 : row.chapterLevel;
      row.chapterLevel = row.indent = currentIndent = currentIndent < 1 ? 1 : currentIndent;
      if (isQuotation) (row as BlQuotationLine).amount = getChapterAmount(table as BlQuotationLine[], row.id ?? 0);
      if (delta > 0) currentRef = [...currentRef, lineCount + 1];
      else if (delta === 0) currentRef[currentRef.length - 1]++;
      else if (delta < 0) {
        for (let j = 0; j < -delta; j++) currentRef.pop();
        currentRef[currentRef.length - 1]++;
      }
      row.calculatedRef = currentRef.map((x) => x.toString()).join('.');
      lineCount = 0;
    } else {
      if (currentRef.length === 0) row.calculatedRef = '0.' + (i - lastChapterIndex + 1).toString();
      else {
        lineCount++;
        row.calculatedRef = currentRef.map((x) => x.toString()).join('.') + '.' + lineCount;
      }
      row.indent = currentIndent + 1;
    }
    return { ...row, canRowExpand: true, position: i + 1, groupId };
  });
}

export const PriceScheduleAllPack: FC<{ id?: any }> = ({ id }) => {
  const [state, setState] = useHandleBusinessRules(id ?? 0);
  const [expanded, setExpanded] = useState<any>({});
  const [headFormState, setHeadFormState] = useState<any>();
  const [companyList, setCompanyList] = useState<ClCompany[]>([]);
  const [campaignTypeList, setCampaignTypeList] = useState<WkCampaignType[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [open, setOpen] = useState(false);
  const [isGroupContract, setIsGroupContract] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [refStudyList, setRefStudyList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [toggleState, setToggleState] = useState<any>({});
  const [unitList, setUnitList] = useState<WkUnit[]>([]);
  const [loading, setLoading] = useState(false);
  const [subRowContentType, setSubRowContentType] = useState<any>('refStudy');
  const navigate = useNavigate();

  useEffect(() => {
    CRUD.getList<ClCompany>(APIRoute.ClCompany + '/Simplified').then(setCompanyList);
    CRUD.getList<WkCampaignType>(APIRoute.WkCampaignType + '/Simplified').then(setCampaignTypeList);
    CRUD.getList<BlReferenceStudyGroup>(APIRoute.BlReferenceStudyGroup).then(setRefStudyList);
    CRUD.getList<WkUnit>(APIRoute.WkUnit).then(setUnitList);
  }, [id]);

  const onHeadFormChange = (data: any) => {
    if (JSON.stringify(headFormState) === JSON.stringify(data)) return;
    setHeadFormState(data);
  };

  const dndCols = useMemo(() => {
    if (isGroupContract) return quotationPriceScheduleConfigList;
    return quotationPriceScheduleConfigList.filter((x) => x.id !== 'typeId' && x.id !== 'campaignTypeIdList');
  }, [isGroupContract]);

  const onSubmitAll = async (data: any) => {
    let errorList = [];
    for (let line of state.filter((x) => !x.chapter)) {
      line.rowColor = undefined;
      if (!line.label || !line.price || !line.unitId) {
        line.rowColor = 'rgba(255, 0, 0, 0.2)';
        errorList.push(line.position);
      }
    }
    if (errorList.length) {
      notificationService.error('Les lignes ' + errorList.join(', ') + ' sont incomplètes');
      setState([...state]);
      return Promise.resolve({ ...data, blPriceSchedule: state });
    }
    const payload = { ...data, blPriceSchedule: state };
    setLoading(true);
    let group = await CRUD.post<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup, payload, !!id);
    notificationService.success('Le bordereau a bien été enregistré');
    if (!id) navigate(`/castres/billing/priceschedule/${group.id}/edit`);
    setState(group.blPriceSchedule ?? []);
    setLoading(false);
    return group;
  };

  const handleSaveButtons = () => onSubmitAll(headFormState);

  const handleRefStudyImport = (refStudy: any) => {
    if (!selectedRow) return notificationService.error("aucune ligne n'est sélectionnée");
    if (selectedRow.original.chapter) return notificationService.error("Impossible d'importer dans un chapitre");
    CRUD.getById<BlReferenceStudyGroup>(APIRoute.BlReferenceStudyGroup, refStudy.id).then((x) => {
      const line = state.find((x: any) => x.id === selectedRow.original.id);
      if (!line) return notificationService.error("erreur d'importation");
      const studyGroup = {
        id: Utils.getNewId(state),
        quantity: 1,
        group: x,
        lineId: line.id,
        groupId: x.id,
        price: x.costPrice,
        blPriceScheduleLineGroup: x.blReferenceStudyGroupLine,
        canRowExpand: true,
      };
      line.blPriceScheduleLineGroup = [...(line.blPriceScheduleLineGroup ?? []), studyGroup];
      setState([...state]);
    });
  };

  const handleClCompanyImport = (company: any) => {
    if (!selectedRow) return notificationService.error("aucune ligne n'est sélectionnée");
    const line = state.find((x: any) => x.id === selectedRow.original.id);
    if (!line) return notificationService.error("erreur d'importation");
    if (line.blPriceScheduleLineCompany.find((x: any) => x.companyId === company.id))
      return notificationService.error('Enseigne déjà importée');
    const payload: any = {
      id: Utils.getNewId(state),
      price: line.price,
      companyId: company.id,
      company: { name: company.label },
    };
    line.blPriceScheduleLineCompany = [...(line.blPriceScheduleLineCompany ?? []), payload];
    setState([...state]);
  };

  const handleSelectedRow = (row: any) => {
    if (selectedRow?.id === row.original?.id) return;
    setSelectedRow(row);
  };

  const handleAllWarningAcknowledgment = () => {
    for (const row of state) row.newWarningAcknowledgmentDate = new Date();
    setState([...state]);
    setWarningModalOpen(false);
  };

  const handleExpand = () => {
    if (Object.entries(expanded).length > 0) return setExpanded({});
    const newExpanded: any = {};
    for (const row of state.map((x, i) => ({ ...x, index: i })) as any[])
      if (row.canRowExpand && !row.chapter) newExpanded[row.index] = true;
    setExpanded(newExpanded);
  };

  const handleToggleAll = () => {
    if (Object.entries(toggleState).length > 0) return setToggleState({});
    const newToggleState: any = {};
    for (const index of state.map((x) => x.id)) newToggleState[index] = true;
    setToggleState(newToggleState);
  };

  if (!companyList || companyList.length === 0) return <LoadingScreen />;

  const dndTitle = selectedCompany ? `Lignes de bordereau ${selectedCompany.label}` : 'Lignes de bordereau';
  const dndTitleDiv = <div style={{ color: selectedCompany ? 'greenyellow' : 'inherit' }}>{dndTitle}</div>;
  return (
    <>
      {loading && <LoadingScreen fullScreen />}
      <PriceScheduleForm {...{ id, setState, setIsGroupContract, onSubmitAll, companyList, onHeadFormChange }} />
      {!!id && (
        <CmsDndTable
          title={dndTitleDiv}
          actions={[
            <CmsIcon icon="expand" onClick={handleExpand} tooltip="déplier/refermer les lignes" />,
            <CmsIcon icon="resize" onClick={handleToggleAll} tooltip="déplier/refermer les désignations" />,
            isAnyLineWarningAcknowledgment(state) && (
              <CmsIcon
                icon="warning"
                onClick={() => setWarningModalOpen(true)}
                tooltip="Acquitter tous les avertissements"
                style={{ color: 'yellow' }}
              />
            ),
            <QuotationImportCsv id={id} setState={setState} isPriceSchedule />,
            <MultiCompanyApplication {...{ companyList, selectedCompany, state, setState }} />,
            <CompanySelection
              companyList={companyList}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />,
            <InputUI.CmsButtonGroup
              value={subRowContentType}
              options={[
                { id: 'refStudy', label: "Réf d'études" },
                { id: 'company', label: 'Prix par enseigne' },
              ]}
              onClick={setSubRowContentType}
            />,
            <Buttons.Valid onClick={() => setOpen(!open)}>Import</Buttons.Valid>,
            <CmsIcon icon="save" onClick={handleSaveButtons} tooltip="Mettre à jour" />,
          ]}
          expandStateHandler={{ state: expanded, setState: setExpanded }}
          stateHandler={{ state, setState }}
          handleCustomReorder={(drag, drop) => DndUtils.handleReorderWithChapter(drag, drop, state, setState)}
          columns={dndCols}
          cellStyle={() => ({ padding: '0 0.2rem' })}
          lineSharedFunctionList={{
            state,
            setState,
            selectedCompany,
            toggleState,
            setToggleState,
            unitList,
            campaignTypeList,
          }}
          rowStyle={(row) => {
            let style = {} as CSSProperties;
            if (row.original.id === selectedRow?.original?.id) style = { outline: '2px solid #88FFFF' };
            if (row.original.rowColor) return { backgroundColor: row.original.rowColor, ...style };
            if (!row.original.chapter) return style;
            return { backgroundColor: handleChapterColor(row.original.indent), ...style };
          }}
          onRowClick={handleSelectedRow}
          SubRowComponent={(x: any) => <SubRow {...{ row: x.row, state, setState, subRowContentType }} />}
          ReadonlyRow={(x) => <ReadonlyRow {...x} isGroupContract={isGroupContract} />}
          // ReadonlyRow={state.length > 100 ? (x) => <ReadonlyRow {...x} isGroupContract={isGroupContract} /> : undefined}
        />
      )}
      <WarningAcknowledgment
        open={warningModalOpen}
        setOpen={setWarningModalOpen}
        onValidate={handleAllWarningAcknowledgment}
      />
      {(subRowContentType === 'refStudy' && (
        <BlRefStudyImportPanel {...{ open, setOpen, refStudyList, handleRefStudyImport, handleSaveButtons }} />
      )) || <ClCompanyImportPanel {...{ open, setOpen, companyList, handleClCompanyImport }} />}
    </>
  );
};

interface FormProps {
  id?: number;
  setState: any;
  setIsGroupContract: any;
  onSubmitAll: any;
  companyList: ClCompany[];
  onHeadFormChange?: any;
}

const PriceScheduleForm: FC<FormProps> = ({
  id,
  setState,
  setIsGroupContract,
  onSubmitAll,
  companyList,
  onHeadFormChange,
}) => {
  const [isFolded, setIsFolded] = useState(!!id);
  return (
    <CmsForm
      id={id}
      onChange={onHeadFormChange}
      onGetEditData={(priceScheduleGroup: BlPriceScheduleGroup) => {
        setState(priceScheduleGroup?.blPriceSchedule ?? []);
        if (priceScheduleGroup.typeCode === 'CONTRAT') setIsGroupContract(true);
        return priceScheduleGroup;
      }}
      defaultValues={{ ref: 'Auto-générer à la création' }}
      onSubmit={onSubmitAll}
      currentUrl="/castres/billing/priceschedule/"
      route={APIRoute.BlPriceScheduleGroup}
      renderForm={(form) => {
        const data = form.watch();
        if (isFolded)
          return (
            <CmsPaper>
              <div className="flex-h space-between">
                <h3>
                  <CmsIcon
                    icon="right"
                    style={{ fontSize: '1.8rem' }}
                    onClick={() => setIsFolded(false)}
                    tooltip="Déplier"
                  />
                  Bordereau: {data.ref} - {data.label}, {data.typeLabel}, {data.active ? 'A' : 'In'}ctif
                </h3>
                <div style={{ display: 'none' }}>
                  <CmsFormInput.Text id="ref" label="Référence" required max={10} />
                </div>
                <CmsFormSubmit withoutDivider type={id ? 'editForm' : 'createForm'} />
              </div>
            </CmsPaper>
          );
        return (
          <CmsFormPaper
            contentType={id ? 'editForm' : 'createForm'}
            title={
              <span>
                <CmsIcon
                  icon="down"
                  style={{ fontSize: '1.8rem' }}
                  onClick={() => setIsFolded(true)}
                  tooltip="Replier"
                />
                Bordereau
              </span>
            }
          >
            <div className="cms-grid fr4">
              <CmsFormInput.Text id="ref" label="Référence" readOnly />
              <CmsFormInput.Text id="label" label="Nom" required max={255} />
              <CmsFormInput.Select
                id="priceScheduleGroupTypeId"
                label="Type"
                options={APIRoute.BlPriceScheduleGroupType}
                onChange={(x) => {
                  form.setValue('typeLabel', x?.label);
                  setIsGroupContract(x?.code === 'CONTRAT');
                }}
                returnObject
                required
              />
              <CmsFormInput.Switch id="active" label="Actif" />
            </div>
            <CmsFormInput.Select id="companyIdList" label="Liste des enseignes" options={companyList} multiple />
          </CmsFormPaper>
        );
      }}
    />
  );
};

interface SubRowProps {
  row: any;
  state: any[];
  setState: any;
  subRowContentType: string;
}

const SubRow: FC<SubRowProps> = ({ row, state, setState, subRowContentType }) => {
  const data = row.original;
  const handleChange = (refStudyId: number, value: any, attr = 'quantity', table = 'blPriceScheduleLineGroup') => {
    const line = state.find((x: any) => x.id === row.original.id);
    if (!line) return notificationService.error("La ligne n'a pas été trouvée");
    if (attr === 'reorder') {
      line.blPriceScheduleLineGroup = value;
      return setState([...state]);
    }
    const studyGroupLine: any = (line as any)[table]?.find((x: any) => x.id === refStudyId);
    if (!studyGroupLine) return notificationService.error("La référence d'étude n'a pas été trouvée");
    studyGroupLine[attr] = value;
    setState([...state]);
  };

  const handleDelete = (refStudyId: number, table = 'blPriceScheduleLineGroup') => {
    const line: any = state.find((x: any) => x.id === row.original.id);
    if (!line) return notificationService.error("La ligne n'a pas été trouvée");
    line[table] = line[table]?.filter((x: any) => x.id !== refStudyId);
    setState([...state]);
  };
  const postRoute = APIRoute.BlPriceScheduleGroup + '/ChapterPic';
  if (data.chapter) return <SubRowChapterPic key={'sub-pic' + data.id} {...{ data, state, setState, postRoute }} />;
  if (subRowContentType === 'refStudy')
    return <SubRowRefStudyComponent key={'sub-' + data.id} {...{ data, handleChange, handleDelete }} />;
  return <SubRowCompanyComponent key={'sub-' + data.id} {...{ data, handleChange, handleDelete }} />;
};

interface ReadOnlyRowProps {
  row: Row<any>;
  handleRowClick: any;
  reorderRow: any;
  isGroupContract: boolean;
}

const ReadonlyRow: FC<ReadOnlyRowProps> = ({ row, handleRowClick, reorderRow, isGroupContract }) => {
  const data = row.original as BlPriceSchedule;
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<any>) => reorderRow(draggedRow.index, row.index),
  });
  if (data.chapter) {
    return (
      <TableRow
        className={'price-schedule-chapter level' + data.indent}
        ref={dropRef}
        key={'readonly' + data.id}
        onClick={() => handleRowClick(row)}
      >
        <TableCell className="drop">{+row.id + 1}</TableCell>
        <TableCell>{data.ref ?? data.calculatedRef}</TableCell>
        <TableCell colSpan={isGroupContract ? 6 : 4} className="chapter-label">
          {data.label}
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow
      className={'price-schedule-data level' + data.indent}
      ref={dropRef}
      key={'readonly' + data.id}
      onClick={() => handleRowClick(row)}
    >
      <TableCell className="drop">{+row.id + 1}</TableCell>
      <TableCell>{data.ref ?? data.calculatedRef}</TableCell>
      <TableCell className="chapter-label">{data.label}</TableCell>
      <TableCell className="price-schedule-unit">{data.unitLabel}</TableCell>
      {isGroupContract && [
        <TableCell className="price-schedule-unit">{data.typeLabel}</TableCell>,
        <TableCell className="price-schedule-unit">{data.campaignTypeNameList.toString()}</TableCell>,
      ]}
      <TableCell className="price-schedule-number" style={(data as any).priceCellStyle}>
        {data.price} €
      </TableCell>
      <TableCell className="price-schedule-number">
        {data.wasWarning && <WarningIcon className={(data.isWarning ? 'is' : 'was') + '-warning'} />}
      </TableCell>
    </TableRow>
  );
};

function isAnyLineWarningAcknowledgment(state: BlPriceSchedule[]): boolean {
  return state.some(
    (x) =>
      x.newWarningAcknowledgmentDate &&
      x.lastGroupWarningDate &&
      x.newWarningAcknowledgmentDate < x.lastGroupWarningDate,
  );
}

interface WarningAcknowledgmentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onValidate: () => void;
}

export const WarningAcknowledgment: FC<WarningAcknowledgmentProps> = ({ open, setOpen, onValidate }) => {
  return (
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper style={{ marginBottom: 0 }}>
        Attention : en validant, vous allez acquitter tous les avertissements en cours. Souhaitez-vous continuer ?
        <div className="flex-h end">
          <Buttons.Cancel onClick={() => setOpen(false)}>Annuler</Buttons.Cancel>
          <Buttons.Valid onClick={onValidate}>Acquitter</Buttons.Valid>
        </div>
      </CmsPaper>
    </UI.Dialog>
  );
};

const handleChapterColor = (indent: number) => {
  return ['#10253f', '#17375d', '#375f91', '#548dd3', '#94b2d5', '#b8cbe3'][indent - 1];
};

interface BlRefStudyImportPanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refStudyList: any[];
  handleRefStudyImport: (refStudy: any) => void;
  handleSaveButtons: () => void;
}

const BlRefStudyImportPanel: FC<BlRefStudyImportPanelProps> = ({
  open,
  setOpen,
  refStudyList,
  handleRefStudyImport,
  handleSaveButtons,
}) => {
  const [selectedButton, setSelectedButton] = useState(1);
  const buttons: IdLabel[] = [
    { id: 1, label: 'Tous' },
    { id: 2, label: 'Par unité' },
  ];
  return (
    <CmsImportPanel {...{ open, setOpen }} title={<h3>Panneau d'import</h3>}>
      <div className="price-schedule-save-button">
        <CmsIcon icon="save" tooltip="Mettre à jour" onClick={handleSaveButtons} />
      </div>
      <div className="flex-center" style={{ marginBottom: '.5rem' }}>
        <InputUI.CmsButtonGroup value={selectedButton} options={buttons} onClick={setSelectedButton} />
      </div>
      {selectedButton === 1 && (
        <CmsImportSimpleList optionsList={refStudyList} onClick={handleRefStudyImport} card={CustomCard} />
      )}
      {selectedButton === 2 && <BlRefStudyGroupImport {...{ refStudyList, handleRefStudyImport }} />}
    </CmsImportPanel>
  );
};

interface ClCompanyImportPanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  companyList: any[];
  handleClCompanyImport: (refStudy: any) => void;
}

const ClCompanyImportPanel: FC<ClCompanyImportPanelProps> = ({ open, setOpen, companyList, handleClCompanyImport }) => {
  return (
    <CmsImportPanel {...{ open, setOpen }} title={<h3>Panneau d'import</h3>}>
      <CmsImportSimpleList optionsList={companyList} onClick={handleClCompanyImport} />
    </CmsImportPanel>
  );
};

interface BlPriceScheduleGroupImportProps {
  refStudyList: any[];
  handleRefStudyImport: (refStudy: any) => void;
}

const BlRefStudyGroupImport: FC<BlPriceScheduleGroupImportProps> = ({ refStudyList, handleRefStudyImport }) => {
  const [filterValue, setFilterValue] = useState<string>();
  const groupedByUnit = useMemo(() => {
    const grouped: { id: any; label: string; children: any[] }[] = [];
    for (const x of refStudyList) {
      let unit = grouped.find((y) => y.id === 'unit' + x.unitId);
      if (!unit) {
        unit = { id: 'unit' + x.unitId, label: x.unitLabel, children: [] };
        grouped.push(unit);
      }
      unit.children.push(x);
    }
    return grouped;
  }, [refStudyList]);
  const filteredAndGroupedByUnit = useMemo(() => {
    if (!filterValue) return groupedByUnit;
    const filtered: { id: any; label: string; children: any[] }[] = [];
    for (const x of groupedByUnit) {
      const unitFilteredChildren = x.children.filter((y: any) =>
        (y.label as string)?.toLowerCase().includes(filterValue ?? ''),
      );
      if (unitFilteredChildren.length === 0) continue;
      filtered.push({ ...x, children: unitFilteredChildren });
    }
    return filtered;
  }, [groupedByUnit, filterValue]);
  return (
    <div>
      <InputUI.DebouncedInput onChange={(x: string) => setFilterValue(x?.toLowerCase())} />
      <CmsTreeView tree={filteredAndGroupedByUnit} card={CustomCard} onCardClick={handleRefStudyImport} />
    </div>
  );
};

const CustomCard: FC = (line: any, onClick) => {
  if (line.children) return line.label;
  return (
    <div className="import-card" onClick={() => onClick?.(line)} style={{ color: '#61daff' }}>
      <Tooltip title={<RefStudyTooltip line={line} />} placement="left">
        <span className="card-label">{line.label}</span>
      </Tooltip>
    </div>
  );
};

const handleUnitPrice: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  if (x.row.original.chapter) return [];
  let value = x.getValue();
  const original = x.row.original;
  const selectedCompany = x.cell.lineSharedFunctionList.selectedCompany;
  if (selectedCompany) {
    const priceCompany = x.row.original.blPriceScheduleLineCompany?.find(
      (x: any) => x.companyId === selectedCompany.id,
    );
    if (priceCompany !== undefined) value = priceCompany.price;
  }
  const handleOnChange = (value: any) => {
    if (!selectedCompany) return x.cell.setCellValue(value);
    const line = x.row.original;
    const priceCompany = line.blPriceScheduleLineCompany?.find((x: any) => x.companyId === selectedCompany.id);
    if (!priceCompany) {
      line.blPriceScheduleLineCompany = [
        ...(line.blPriceScheduleLineCompany ?? []),
        {
          id: Utils.getNewId(x.cell.stateHandler.state),
          price: value,
          companyId: selectedCompany.id,
          company: { name: selectedCompany.label },
          lineId: x.row.original.id,
        },
      ];
      return x.cell.lineSharedFunctionList.setState([...x.cell.stateHandler.state]);
    }
    priceCompany.price = value;
    x.cell.lineSharedFunctionList.setState([...x.cell.stateHandler.state]);
  };

  const input = (
    <InputUI.DebouncedInput
      variant="standard"
      key={x.cell.key}
      onChange={handleOnChange}
      valueCanUpdate
      value={value}
      priceInput
    />
  );
  if (!original.payload?.costPrice) return input;
  const spaceStyle = { margin: '-0.2rem', padding: '0.2rem' };
  return (
    <div style={{ ...(original.priceCellStyle ?? {}), ...spaceStyle }}>
      <Tooltip
        title={
          <TabStyleDataView
            style={{ fontSize: '1rem' }}
            conf={toolTipPriceScheduleWarningConfigList}
            mapFrom={original.payload}
          />
        }
        style={{ maxWidth: '20rem' }}
        placement="left"
      >
        <span>{input}</span>
      </Tooltip>
    </div>
  );
};

export const toolTipPriceScheduleWarningConfigList: LabelValueIf[] = [
  { label: 'Invariable', value: (x: any) => Utils.ThousandSpacing(x.flatRateCost, 2) + '€' },
  { label: 'Variable', value: (x: any) => Utils.ThousandSpacing(x.variableRateCost, 2) + '€' },
  {
    label: <p className="text-no-wrap">Coût de revient</p>,
    value: (x: any) => Utils.ThousandSpacing(x.costPrice, 2) + '€',
  },
  { label: 'P.V.S', value: (x: any) => Utils.ThousandSpacing(x.markupPrice, 2) + '€' },
  {
    label: <span style={{ color: 'yellow' }}>Rentabilité à partir de</span>,
    value: (x: any) => <span style={{ color: 'yellow' }}>{x.rentability + ' éléments'}</span>,
    ifIs: (x: any) => x.rentability > 1,
  },
  {
    label: <span style={{ color: '#FF5555' }}>Attention</span>,
    value: () => <span style={{ color: '#FF5555' }}>Pas rentable</span>,
    ifIs: (x: any) => x.diffVariable < 0,
  },
];

const designationCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const handleIndent = (right: boolean) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    const indent = (row?.chapterLevel ?? 0) + (right ? 1 : -1);
    row.chapterLevel = indent < 1 ? 1 : indent > 5 ? 5 : indent;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  const handleLabel = (label: string) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    row.label = label;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };
  const indentStyle = getIndentStyle(x.row.original.indent ?? 0);
  const iconStyle: CSSProperties = { margin: '-0.2rem 0' };
  if (x.row.original.chapter) {
    return (
      <div key={x.cell.key} className="cms-grid" style={{ gridTemplateColumns: 'auto 6rem' }}>
        <div style={indentStyle} className="designation-input-cell">
          <InputUI.DebouncedInput variant="standard" value={x.getValue()} valueCanUpdate onChange={handleLabel} />
        </div>
        <div className="flex-h align-center">
          <CmsIcon icon="unIndent" onClick={() => handleIndent(false)} style={iconStyle} />
          <CmsIcon icon="indent" onClick={() => handleIndent(true)} style={iconStyle} />
          <ChapterPicPopperIcon data={x.row.original} route={APIRoute.BlPriceScheduleGroup} />
        </div>
      </div>
    );
  }
  const toggleState = x.cell.lineSharedFunctionList.toggleState;
  const setIsToggled = (toggle: boolean) => {
    const setToggleState = x.cell.lineSharedFunctionList.setToggleState;
    setToggleState({ ...toggleState, [x.row.original.id]: toggle });
  };

  return (
    <div style={indentStyle} className="designation-input-cell">
      <InputUI.DebouncedInput
        value={x.getValue()}
        variant="standard"
        valueCanUpdate
        onChange={handleLabel}
        multilineToggle
        controlledToggle={{ isToggled: !!toggleState[x.row.original.id], setIsToggled }}
      />
    </div>
  );
};

export const ChapterPicPopperIcon: FC<{ data: BlQuotationLine | BlPriceSchedule; route: string }> = ({
  data,
  route,
}) => {
  if (!data.imagePath || (data.id ?? 0) <= 0) return [];
  return (
    <CmsIcon
      icon="visible"
      textPos="left"
      style={{ margin: '-0.2rem 0' }}
      tooltip={
        <div style={{ position: 'relative', width: 0, height: 0 }}>
          <CmsLazyLoadImage
            key={'lazy-image' + data.id}
            alt="Image d'illustration du chapitre"
            src={`${API_URL}${route}/ChapterPic/${data.id}`}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderRadius: '0.2rem',
              boxShadow: '0 0 0.5rem rgba(0,0,0,0.3)',
              maxWidth: '50vw',
            }}
          />
        </div>
      }
    />
  );
};

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    const list = [...(x.cell.stateHandler.state as BlPriceSchedule[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleChapterDelete = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, DndUtils.getEndOfWholeChapterSize(list, startIndex, x.row.original.chapterLevel ?? 1));
    x.cell.stateHandler.setState(list);
  };

  const handleChapterAddEndChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    const endIndex = DndUtils.getChapterSize(list, startIndex);
    list.splice(startIndex + endIndex, 0, { id: Utils.getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleAdd = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, { id: Utils.getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleReplace = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, {
      id: Utils.getNewId(x.cell.stateHandler.state),
      chapterLevel: 1,
      chapter: true,
    } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleWarningAcknowledgment = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    if (list[startIndex].newWarningAcknowledgmentDate === list[startIndex].warningAcknowledgmentDate)
      list[startIndex].newWarningAcknowledgmentDate = new Date();
    else list[startIndex].newWarningAcknowledgmentDate = list[startIndex].warningAcknowledgmentDate;
    x.cell.stateHandler.setState(list);
  };

  const buttonList = x.row.original.chapter
    ? [
        <span onClick={handleDelete}>Supprimer ce chapitre</span>,
        <span onClick={handleChapterDelete}>Supprimer ce chapitre et tout son contenu</span>,
        <span onClick={handleChapterAddEndChapter}>Ajouter une ligne à la fin du chapitre</span>,
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>,
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>,
      ]
    : [
        <span onClick={handleDelete}>Supprimer cette ligne</span>,
        <span onClick={() => handleReplace()}>Transformer cette ligne en chapitre</span>,
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>,
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>,
      ];

  const style = { margin: '-0.2rem' };
  const { isWarning, wasWarning } = x.row.original;
  return [
    <div className="flex-center">
      {wasWarning && (
        <CmsIcon
          icon="warning"
          onClick={handleWarningAcknowledgment}
          tooltip={isWarning ? 'Avertissement non acquitté' : 'Avertissement acquitté'}
          style={{ color: isWarning ? 'yellow' : 'grey', ...style }}
        />
      )}
      <CmsMenuButton
        key={'action-group' + x.row.original.id}
        preventClose={false}
        title="Menu action"
        className="button-group"
        overLoadButton={(props) => (
          <CmsIcon key={'action'} icon="moreVertical" getMouseEvent onClick={props.handleClick} style={style} />
        )}
      >
        {buttonList}
      </CmsMenuButton>
    </div>,
  ];
};

function getWarningData(x: BlPriceSchedule): BlPriceSchedule {
  if (!x.lastGroupWarningDate) return x;
  const lastGroupWarningDate = new Date(x.lastGroupWarningDate ?? 0);
  const newWarningAcknowledgmentDate = new Date(x.newWarningAcknowledgmentDate ?? 0);
  const warningAcknowledgmentDate = new Date(x.warningAcknowledgmentDate ?? 0);
  if (lastGroupWarningDate > newWarningAcknowledgmentDate) x.isWarning = true;
  if (lastGroupWarningDate > warningAcknowledgmentDate) x.wasWarning = true;
  const payload = getAllLineGroupCalculation(x.price, x.blPriceScheduleLineGroup);
  (x as any).payload = payload;
  (x as any).priceCellStyle = colorCellFromBlReferenceStudyGroupLine(x.price, payload);
  return x;
}

const CampaignTypeCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const campaignList = x.cell.lineSharedFunctionList.campaignTypeList;
  const handleOnChange = (value: any) => {
    const items = campaignList.filter((x: any) => value.includes(x.id));
    const row = x.cell.stateHandler.state.find((y: any) => y.id === x.row.original.id);
    row.campaignTypeIdList = value;
    row.campaignTypeNameList = items.map((y: IdLabel) => y.label).join(', ');
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  return (
    <InputUI.AutoCompletor
      variant="standard"
      options={campaignList}
      value={x.getValue()}
      onChange={handleOnChange}
      multiple
    />
  );
};

const quotationPriceScheduleConfigList: CmsColumnDndDef<any>[] = [
  {
    header: 'Ref',
    id: 'ref',
    size: 40,
    cell: (x) => {
      return (
        <InputUI.DebouncedInput
          variant="standard"
          key={x.cell.key}
          valueCanUpdate
          onChange={x.cell.setCellValue}
          value={x.getValue() ?? x.row.original.calculatedRef}
        />
      );
    },
  },
  { header: 'Bordereau/Désignation', id: 'label', size: 500, cell: designationCell },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    inputOptions: { data: APIRoute.WkUnit + '/Simplified' },
    hideIfChapter: true,
    cell: (x) => (
      <Tooltip title={x.row.original.unitLabel}>
        <span>
          <InputUI.AutoCompletor
            style={{ width: '6rem', marginRight: '-2rem' }}
            variant="standard"
            returnObject
            options={x.cell.lineSharedFunctionList.unitList}
            value={x.getValue()}
            onChange={(y: WkUnit) => {
              let row = x.cell.stateHandler.state.find((z: any) => z.id === x.row.original.id);
              row.unitId = y?.id;
              row.unitLabel = y?.label;
              x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
            }}
          />
        </span>
      </Tooltip>
    ),
  },
  {
    header: 'Type',
    id: 'typeId',
    size: 0,
    cell: DndInput.Select,
    inputOptions: { data: APIRoute.BlPriceScheduleType },
    hideIfChapter: true,
  },
  {
    header: 'Type de campagne',
    id: 'campaignTypeIdList',
    size: 0,
    cell: CampaignTypeCell,
    // cell: DndInput.Select,
    // inputOptions: { data: APIRoute.WkCampaignType + '/Simplified', multiple: true },
    hideIfChapter: true,
  },
  { header: 'Prix unitaire bordereau', id: 'price', size: 0, cell: handleUnitPrice },
  { header: 'Action', id: 'action', size: 0, cell: handleActions },
];

interface CompanySelectionProps {
  companyList: ClCompany[];
  selectedCompany: any;
  setSelectedCompany: Dispatch<SetStateAction<any>>;
}

const CompanySelection: FC<CompanySelectionProps> = ({ companyList, selectedCompany, setSelectedCompany }) => {
  const [open, setOpen] = useState(false);
  const title = selectedCompany ? selectedCompany.label : 'Personnaliser les prix';
  return [
    <Buttons.Default key="add" onClick={() => setOpen(true)}>
      {title}
    </Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper title="Sélectionner l'enseigne à personnaliser" style={{ marginBottom: 0 }}>
        <InputUI.AutoCompletor
          options={companyList}
          value={selectedCompany?.id ?? null}
          onChange={setSelectedCompany}
          returnObject
        />
        <Buttons.Valid onClick={() => setOpen(false)}>Valider</Buttons.Valid>
      </CmsPaper>
    </UI.Dialog>,
  ];
};

interface MultiCompanyApplicationProps {
  companyList: ClCompany[];
  selectedCompany: any;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
}

const MultiCompanyApplication: FC<MultiCompanyApplicationProps> = ({
  companyList,
  selectedCompany,
  state,
  setState,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCompanyList, setSelectedCompanyList] = useState<ClCompany[]>([]);
  if (!selectedCompany) return [];
  const handleApply = () => {
    for (const priceScheduleLine of state) {
      for (const company of selectedCompanyList) {
        const originLineCompany = priceScheduleLine.blPriceScheduleLineCompany.find(
          (x: any) => x.companyId === selectedCompany.id,
        );
        if (!originLineCompany) continue;
        const existingCompany = priceScheduleLine.blPriceScheduleLineCompany.find(
          (x: any) => x.companyId === company.id,
        );
        if (existingCompany) {
          existingCompany.price = originLineCompany.price;
        } else {
          priceScheduleLine.blPriceScheduleLineCompany.push({
            id: Utils.getNewId(state),
            price: originLineCompany.price,
            companyId: company.id,
            company: { name: company.label },
            lineId: priceScheduleLine.id,
          });
        }
      }
    }
    setState([...state]);
    setOpen(false);
  };
  return [
    <Buttons.Default key="add" onClick={() => setOpen(true)}>
      Appliquer ces prix à d'autres enseignes
    </Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper title="Sélectionner les enseignes à mettre à jour" style={{ marginBottom: 0 }}>
        <InputUI.AutoCompletor
          options={companyList}
          value={selectedCompanyList}
          onChange={setSelectedCompanyList}
          returnObject
          multiple
        />
        <Buttons.Valid onClick={handleApply}>Valider</Buttons.Valid>
      </CmsPaper>
    </UI.Dialog>,
  ];
};

interface ImportCsvProps {
  priceScheduleGroupId: number;
  setState: Dispatch<SetStateAction<any>>;
}

export const PriceScheduleImportCsv: FC<ImportCsvProps> = ({ priceScheduleGroupId, setState }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleImport = () => {
    CRUD.postFormData<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup + '/Import', {
      id: priceScheduleGroupId,
      file,
    }).then((x) => {
      setState(x.blPriceSchedule);
      setOpen(false);
    });
  };

  return [
    <Buttons.Default onClick={() => setOpen(true)}>Importer un fichier CSV</Buttons.Default>,
    <UI.Dialog open={open} onClose={() => setOpen(false)}>
      <CmsPaper style={{ marginBottom: 0 }} title="Import">
        <InputUI.InputFile onFileSelected={setFile} />
        <Buttons.Default onClick={handleImport}> Importer </Buttons.Default>
      </CmsPaper>
    </UI.Dialog>,
  ];
};
