import React, { FC, useEffect } from 'react';
import { BubbleMenu, Editor, EditorContent, Extensions, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { TextStyle } from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import { CmsPaper } from './Ui';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import { IconButton, Popover } from '@mui/material';
import { SketchPicker } from 'react-color';
import PaletteIcon from '@mui/icons-material/Palette';
import Underline from '@tiptap/extension-underline';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
interface CmsRichTextEditorProps {
  content: string;
  setContent: (content: string) => void;
}

const extensions: Extensions = [
  StarterKit,
  TextStyle,
  TextStyle.configure({ mergeNestedSpanStyles: true }),
  Color,
  BulletList,
  Underline,
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
];

export const CmsRichTextEditor: FC<CmsRichTextEditorProps> = ({ content, setContent }) => {
  const [localContent, setLocalContent] = React.useState<string>('');
  const [color, setColor] = React.useState<string>('#0a9ad3');

  const editor = useEditor({
    extensions,
    content: content ?? '',
    onUpdate: ({ editor }) => {
      setLocalContent(editor.getHTML());
    },
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setContent(localContent);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [localContent]);

  if (!editor) return <></>;
  const handleSetColor = (color: string) => {
    editor?.commands.setColor(color);
    setColor(color);
  };

  return (
    <div className="cms-rich-text-editor">
      <div className="head-text-editor">
        <OptionMenu {...{ handleSetColor, editor, color }} />
      </div>
      <EditorContent className="editor-content" editor={editor} />
      <BubbleMenu editor={editor}>
        <CmsPaper className="head-text-editor popover-color">
          <OptionMenu {...{ handleSetColor, editor, color, isPopover: true }} />
        </CmsPaper>
      </BubbleMenu>
    </div>
  );
};

const OptionMenu: FC<{
  editor: Editor;
  color: string;
  handleSetColor: any;
  isPopover?: boolean;
}> = ({ editor, color, handleSetColor, isPopover }) => {
  // const setFontSize = (level: any) => editor.chain().focus().toggleHeading({ level }).run();
  // const setFontSize = (size: any) => editor.chain().setMark('textStyle', { fontSize: '25px' }).run();
  const setItalic = () => editor.chain().focus().toggleItalic().run();
  const setBold = () => editor.chain().focus().toggleBold().run();
  const setUnderline = () => editor.chain().focus().toggleUnderline().run();
  const setList = () => editor.chain().focus().toggleBulletList().run();
  return [
    // <div className="text-size-editor">
    //   <IconButton onClick={() => setFontSize('22px')}>
    //     <FormatSizeIcon style={{ fontSize: '0.7rem' }} />
    //   </IconButton>
    //   <IconButton onClick={() => setFontSize('1.5rem')}>
    //     <FormatSizeIcon style={{ fontSize: '1rem' }} />
    //   </IconButton>
    //   <IconButton onClick={() => setFontSize('1rem')}>
    //     <FormatSizeIcon style={{ fontSize: '1.4rem', margin: '-0.2rem' }} />
    //   </IconButton>
    // </div>,
    <div className="text-style-editor">
      <IconButton onClick={setList}>
        <FormatListBulletedIcon />
      </IconButton>
      <IconButton onClick={setItalic}>
        <FormatItalicIcon />
      </IconButton>
      <IconButton onClick={setBold}>
        <FormatBoldIcon />
      </IconButton>
      <IconButton onClick={setUnderline}>
        <FormatUnderlinedIcon />
      </IconButton>
    </div>,
    <div className="color-editor">{!isPopover && <ColorPicker {...{ color, setColor: handleSetColor }} />}</div>,
  ];
};

const anchorOrigin: any = { vertical: 'center', horizontal: 'right' };
const transformOrigin: any = { vertical: 'center', horizontal: 'left' };
const ColorPicker: FC<{ color: string; setColor: any }> = ({ color, setColor }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const idHtml = open ? 'simple-popover' : undefined;
  const buttonProps: any = { variant: 'contained', style: { backgroundColor: color } };
  const popProps: any = { open, anchorEl, onClose: handleClose, transformOrigin, anchorOrigin };
  return (
    <>
      <IconButton aria-describedby={idHtml} onClick={handleClick} {...buttonProps}>
        <PaletteIcon />
      </IconButton>
      <Popover id={idHtml} {...popProps} disablePortal style={{ zIndex: 100 }}>
        <SketchPicker color={color} onChange={(val) => setColor(val.hex)} />
      </Popover>
    </>
  );
};
