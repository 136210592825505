import React, { FC, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import Grid from '@mui/material/Grid';
import CRUD from 'service/CRUD.service';
import { WkActionEquipment, WkReport, WkTask } from 'interface/WkType';
import NotificationService from 'service/NotificationService';
import { authenticationService } from 'service/Authentication.service';
import CmsIcon from 'component/shared/CmsIcon';
import { CellDndContext, CmsColumnDndDef, CmsDndTable, validateRequiredFields } from 'component/table/CmsDndTable';
import { Buttons } from 'component/shared';
import { DndInput } from 'component/table/DnDTableCell';
import Utils from 'helper/Utils';

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    const list = [...(x.cell.stateHandler.state as WkActionEquipment[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) NotificationService.error('Erreur Dnd Table: ligne non trouvé');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      <CmsIcon icon="delete" tooltip="Supprimer" onClick={handleDelete} />
      {x.row.original.followUp && !x.row.original.isFollowUpNecessary && (
        <CmsIcon
          key="warning"
          icon="warning"
          style={{ color: 'yellow', margin: '-0.2rem 0' }}
          textPos="left"
          tooltip="La précision sur la suite à donner sera effacée lors de l'enregistrement si il n'y a pas de suite à donner"
        />
      )}
    </div>
  );
};

export const WkReportCreateEdit: FC = ({ id }: any) => {
  const [userOptionList, setUserOptionList] = useState<any[]>([]);
  const [stationId, setStationId] = useState<number>();
  const [equipmentOptionList, setEquipmentOptionList] = useState<any[]>();
  const [state, setState] = useState<any[]>([]);

  useEffect(() => {
    CRUD.getList(APIRoute.UsUsers + '/Simplified?withDisabled=true&roleFilter=WkReport').then(setUserOptionList);
  }, []);

  useEffect(() => {
    if (stationId)
      CRUD.getList(APIRoute.EqEquipment + '/ByStation/' + stationId + '/Simplified').then(setEquipmentOptionList);
  }, [stationId]);

  const actionEquipmentConfigList: CmsColumnDndDef<WkActionEquipment>[] = useMemo(() => {
    const config: CmsColumnDndDef<WkActionEquipment>[] = [
      {
        id: 'diagnostic',
        header: 'Constat avant intervention',
        cell: DndInput.Text,
        inputOptions: { multiline: true },
      },
      {
        id: 'description',
        header: "Description de l'action réalisée",
        cell: DndInput.Text,
        inputOptions: { multiline: true },
        required: true,
      },
      {
        id: 'equipmentId',
        header: 'Équipement',
        cell: DndInput.Select,
        inputOptions: { data: equipmentOptionList },
      },
      {
        id: 'domainId',
        header: 'Domaine',
        cell: DndInput.Select,
        inputOptions: { data: APIRoute.EqDomain + '/Simplified' },
      },
      {
        id: 'natureId',
        header: 'Panne constatée',
        cell: DndInput.Select,
        inputOptions: {
          fetchOptionlist: { route: APIRoute.EqNature + '/Simplified/', input: 'domainId' },
          data: [{ id: 0, label: 'Veuillez sélectionner un domaine' }],
        },
      },
      {
        id: 'actionId',
        header: 'Action réalisée',
        cell: DndInput.Select,
        inputOptions: {
          fetchOptionlist: { route: APIRoute.EqAction + '/ByDomain/', input: 'domainId' },
          data: [{ id: 0, label: 'Veuillez sélectionner un domaine' }],
        },
      },
      {
        id: 'isFollowUpNecessary',
        header: 'Suite à donner',
        cell: DndInput.Switch,
        size: 140,
      },
      {
        id: 'followUp',
        header: 'Précisions suite à donner',
        cell: DndInput.Text,
        inputOptions: { multiline: true },
        readOnlyIf: (row: WkActionEquipment) => !row.isFollowUpNecessary,
      },
      {
        id: 'privateComment',
        header: 'Commentaire interne',
        cell: DndInput.Text,
        inputOptions: { multiline: true },
      },
      { header: 'Actions', id: 'action', size: 0, cell: handleActions },
    ];
    return config;
  }, [equipmentOptionList]);

  const onSubmitAll = async (data: any, isRedirect?: boolean) => {
    const result = await CRUD.put<WkTask>(APIRoute.WkTask + '/All', data);
    NotificationService.success('Les modifications ont bien été enregistrées');
    if (isRedirect) window.open(`/work/report/upload/${result.id}`, '_self');
    return result;
  };
  const user = useMemo(() => authenticationService.getCurrentUser(), []);
  return (
    <>
      <CmsDndTable
        title="Liste des actions entreprises"
        stateHandler={{ state, setState }}
        columns={actionEquipmentConfigList}
        actions={[
          <Buttons.Nav.Add
            key="AddLine"
            onClick={() => setState([...state, { id: Utils.getNewId(state) }])}
            title="Ajouter une action"
          />,
        ]}
        withoutDnd
      />
      <CmsForm
        id={id}
        title="un rapport"
        currentUrl={`/castres/work/task/${id}/report`}
        goBackTo={`/work/report/upload/${id}`}
        goBackUseHref={true}
        route={APIRoute.WkTask}
        actions={[
          <CmsIcon
            key="help"
            href="/help/Rapport_d'intervention/Creation_rapport_web"
            icon="help"
            tooltip="Aide"
            target="_blank"
          />,
        ]}
        paperStyle={{ maxWidth: '80rem' }}
        onGetEditData={(data: any) => {
          if (!data.workstart) data.workstart = data.startDate;
          setState(data.wkActionEquipment.length !== 0 ? data.wkActionEquipment : [{ id: Utils.getNewId([]) }]);
          return data;
        }}
        onSubmit={async (data: any, _, isRedirect) => {
          data = {
            ...data,
            senderId: data.outsourced ? null : data.senderId,
            reportUserId: data.outsourced ? [] : data.reportUserId,
            wkActionEquipment: state,
          };
          if (data.wkActionEquipment.length < 1) {
            NotificationService.error('Veuillez remplir au moins une action entreprise');
            return data;
          }
          if (validateRequiredFields(state, actionEquipmentConfigList)) {
            await CRUD.post<WkReport>(APIRoute.WkTask + '/report', data, true);
            var result = await onSubmitAll(data, isRedirect);
            return result;
          }
          return data;
        }}
        renderForm={(form) => {
          if (form.getValues('stationId') !== stationId) setStationId(form.getValues('stationId'));
          return (
            <Grid container spacing={2}>
              <Grid item sm={12} lg={6}>
                <CmsFormInput.DateAndTime
                  id="startDate"
                  label="Date de départ"
                  required
                  onDateChange={() => {
                    form.trigger(['endDate', 'workstart']);
                  }}
                  rules={{
                    validate: (value, formvalue) =>
                      ((!formvalue.workstart || value <= formvalue.workstart) &&
                        (!formvalue.endDate || value <= formvalue.endDate)) ||
                      "Doit être inférieure à la date d'arrivée",
                  }}
                />
                <CmsFormInput.DateAndTime
                  id="workstart"
                  label="Date d'arrivée sur site"
                  onDateChange={() => {
                    form.trigger(['endDate', 'startDate']);
                  }}
                  required
                  rules={{
                    validate: (value, formvalue) =>
                      ((!formvalue.startDate || formvalue.startDate <= value) &&
                        (!formvalue.endDate || value <= formvalue.endDate)) ||
                      'Doit être entre la date de départ et date de fin',
                  }}
                />
                <CmsFormInput.DateAndTime
                  id="endDate"
                  label="Date de fin"
                  onDateChange={() => {
                    form.trigger(['workstart', 'startDate']);
                  }}
                  required
                  rules={{
                    validate: (value) => value <= Date.now() || 'Cette date est dans le futur',
                  }}
                />
                <CmsFormInput.Select
                  id="senderId"
                  label="Intervenant Principal"
                  options={userOptionList}
                  readOnlyIf={(dataForm: any) => !!dataForm.reportId}
                  requiredIf={(dataForm: any) => !dataForm.outsourced}
                  hideIf={(dataForm: any) => dataForm.outsourced}
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.senderId === 0 || dataForm.senderId === null)
                      return { update: true, value: user.userId };
                    else return { update: false, value: null };
                  }}
                  filterByActive={true}
                />
                <CmsFormInput.Switch
                  id="outsourced"
                  label="Sous-traité"
                  hideIf={(dataForm: any) => !!dataForm.apkReport}
                />
                <CmsFormInput.Select
                  id="reportUserId"
                  label="Autres intervenants"
                  options={userOptionList}
                  multiple={true}
                  rules={{
                    validate: (value: any[], formvalue) =>
                      !value.some((v) => v === formvalue.senderId) ||
                      "L'intervenant principal ne peut pas être présent ici",
                  }}
                  filterByActive={true}
                  hideIf={(dataForm: any) => dataForm.outsourced}
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <CmsFormInput.Switch
                  id="doneRemotely"
                  label="Fait à distance"
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.doneRemotely == null) return { update: true, value: true };
                    else return { update: false, value: null };
                  }}
                />
                <CmsFormInput.Number
                  id="distance"
                  label="Distance"
                  requiredIf={(data: any) => !data.doneRemotely}
                  hideIf={(data: any) => data.doneRemotely}
                />
                <CmsFormInput.Switch
                  id="resolved"
                  label="Intervention résolue"
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.doneRemotely == null) return { update: true, value: false };
                    else return { update: false, value: null };
                  }}
                />
                <CmsFormInput.Switch
                  id="isFollowUpNecessary"
                  label="Suite à donner"
                  updateFieldIf={(dataForm: any) => {
                    return {
                      update: !dataForm.isFollowUpNecessary && state.some((x: any) => x.isFollowUpNecessary),
                      value: state.some((x: any) => x.isFollowUpNecessary),
                    };
                  }}
                />
                <CmsFormInput.Text
                  id="followUp"
                  label="Précisions suite à donner"
                  hideIf={(data) => !data.isFollowUpNecessary}
                  multiline={true}
                />
                <CmsFormInput.Number id="reportId" label="Id du rapport" hideIf={() => true} />
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};
